import type { Seq } from "immutable";
import type { RequestCodeListOptions } from "../../../redux/apiSlice";
import type { CodelistStateResult } from "../../../redux/codelistSlice";
import { mapAsync } from "../../../utils/generator";
import type {
	DatatypeMap,
	RestrictionId,
	RestrictionsMap,
} from "../../EditorState/types";
import type {
	RestrictionProfileValidationResult,
	ProfileValidationResult,
	RestrictionProfileValidationContext,
	Validator,
	ValidationTargetFieldProfiling,
} from "../types";
import { ValidationResultGroup } from "../types";
import { generateRestrictionEntries } from "./helpers";
import validateProfiles from "./validateProfiles";
import type { LiteId } from "../../../lib/validation/lite/IDSchemas";
import type { LiteModellContainer } from "../../AppActor/actors/modellierungModel/types";
import {
	selectDatatypesFromModell,
	selectQNameFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import { NotADatatypeError } from "../../../utils/profilingHelpers";

function createRestrictionProfileValidationResult(
	profileResult: ProfileValidationResult<ValidationTargetFieldProfiling>,
	datatypeId: LiteId,
	restrictionId: RestrictionId,
	restrictionName: string,
): RestrictionProfileValidationResult<ValidationTargetFieldProfiling> {
	return {
		...profileResult,
		type: ValidationResultGroup.RestrictionProfiles,
		datatypeId,
		restrictionId,
		restrictionName,
		elementName: restrictionName,
		elementNamePath: [restrictionName, profileResult.nodeName],
	};
}

function createProfileSeq(
	restrictions: RestrictionsMap,
): Seq.Indexed<RestrictionProfileValidationContext> {
	return restrictions
		.entrySeq()
		.filter(([, restriction]) => {
			const profile = restriction.get("profile");
			return !!profile && !profile?.isEmpty();
		})
		.map(([restrictionId, restriction]) => ({
			restrictionId,
			restrictionName: restriction.get("name"),
			// We've filtered the undefined profiles one line above, so we can
			// safely assume they are defined here
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			profiles: restriction.get("profile")!,
		}));
}

/**
 * Validate restriction profiles of datatypes entered by the user.
 *
 * @param datatypes The datatypes, containing the restriction profiles, that
 * should be validated
 * @param getStandardNode A getter for the nodes of the standard. We need these
 * nodes to compare the profiles to the data in the standard
 */
export default async function* validateRestrictionProfiles({
	modell,
	datatypes,
	getCodelist,
}: {
	modell: LiteModellContainer;
	datatypes: DatatypeMap;
	getCodelist: (
		requestOptions: Partial<RequestCodeListOptions>,
	) => Promise<CodelistStateResult>;
}): Validator<
	RestrictionProfileValidationResult<ValidationTargetFieldProfiling>
> {
	const datatypeList = selectDatatypesFromModell(modell);
	// Iterate through each datatype, so we can check all user entered data
	const restrictionGenerator = generateRestrictionEntries(datatypes);
	for (const [datatypeQName, restrictions] of restrictionGenerator) {
		const datatype = datatypeList.find((dt) => {
			NotADatatypeError.assert(dt);
			return selectQNameFromModell(modell, dt) === datatypeQName;
		});
		if (!datatype) continue;
		NotADatatypeError.assert(datatype);
		const restrictionProfiles = createProfileSeq(restrictions);
		// Validate all restriction profiles
		for (const {
			profiles,
			restrictionId,
			restrictionName,
		} of restrictionProfiles) {
			yield* mapAsync<
				ProfileValidationResult<ValidationTargetFieldProfiling> | null,
				RestrictionProfileValidationResult<ValidationTargetFieldProfiling> | null
			>(
				(profileResult) =>
					profileResult &&
					createRestrictionProfileValidationResult(
						profileResult,
						datatype.id,
						restrictionId,
						restrictionName,
					),
			)(
				validateProfiles({
					modell,
					profiles,
					datatypes,
					getCodelist,
				}),
			);
		}
	}
}
