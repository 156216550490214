import { useCallback, useEffect } from "react";
import {
	placeholder,
	tooltips,
	labels,
} from "../../../../../../resources/textConstants";
import { useSendStoreEvent } from "../../../../../AppActor/EventStore/hooks";
import { EditableFieldNameSchema } from "../../../../../AppActor/actors/modellierungModel/types";
import { ValidationTargetField } from "../../../../../AppActor/actors/modellierungModel/validation/validators/types";
import EditFormFieldLite from "../../../../../EditFormFieldLite";
import ValidationDisplayLite from "../../../../../ValidationDisplayLite";
import type { EditRendererProps } from "../../types";
import type {
	LiteEigenschaft,
	LiteNode,
} from "../../../../../../lib/validation/lite/LiteSchemas";
import {
	isLiteBaustein,
	isLiteEigenschaft,
	isLitePaket,
} from "../../../../../../lib/validation/lite/TypeGuards";
import {
	LiteBausteinType,
	LiteGruppeType,
} from "../../../../../../lib/validation/lite/LiteEnums";
import { DEFAULT_GROUPTYPE } from "../../../../../AppActor/actors/modellierungModel/helpers";
import { selectNodeFromModell } from "../../../../../AppActor/actors/modellierungModel/selectors";
import { useReferencingEntries } from "../../referers/shared/helpers";

const getToolTipText = (type: "choice" | "sequence" | "all"): string => {
	return tooltips?.groupTypeSelect[type].tooltip ?? "Grouppenart Tooltip";
};
const groupTypeOptions = [
	{
		value: LiteGruppeType.Sequence,
		label: labels.groupType.sequence,
		tooltip: getToolTipText("sequence"),
	},
	{
		value: LiteGruppeType.Choice,
		label: labels.groupType.choice,
		tooltip: getToolTipText("choice"),
	},
	{
		value: LiteGruppeType.All,
		label: labels.groupType.all,
		tooltip: getToolTipText("all"),
	},
];
export default function CommonMetadataEdit({
	activeNode,
	isReadOnly,
	projekt,
	activePath,
}: EditRendererProps): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const { currentReferer, currentKey } = useReferencingEntries(activeNode);

	const handleWrite = useCallback(
		(name: string, value: string) => {
			const nodeId = activeNode.id;
			sendStoreEvent({
				type: "MODELLIERUNG.MODELL.APPLY",
				payload: {
					projektId: projekt.id,
					patch: {
						type: "changeDetailsValue",
						payload: {
							name: EditableFieldNameSchema.parse(name),
							value,
							nodeId,
						},
					},
				},
			});
		},
		[activeNode.id, sendStoreEvent, projekt],
	);

	const handleSelectChange = (e: {
		target: { name?: string; value: string };
	}) => {
		handleWrite("gruppeArt", e.target.value);
	};

	/* We dont show GroupEdit for  LiteBausteinType.CodeDatentyp and Paket and Schemapaket  */
	const isCodeType = () => {
		if (activeNode && isLiteBaustein(activeNode)) {
			return activeNode.typ === LiteBausteinType.CodeDatentyp;
		}
		return false;
	};

	const hasDatentyp = useCallback(
		(node: LiteNode): node is LiteEigenschaft => {
			return isLiteEigenschaft(activeNode) && !!activeNode.datentyp;
		},
		[activeNode],
	);
	const hasGlobaleEigenschaft = () => {
		return currentKey === "referenz";
	};
	/* We display the GroupType Field readonly when it has a datentyp */
	const isGroupTypeReadonly = () => {
		return hasDatentyp(activeNode) || isReadOnly;
	};

	const getGroupTypeValue = (): LiteGruppeType => {
		if (hasDatentyp(activeNode) && !!activeNode.datentyp) {
			const node = selectNodeFromModell(projekt.modell, activeNode.datentyp);
			return node?.gruppeArt || DEFAULT_GROUPTYPE;
		}
		return activeNode.gruppeArt || DEFAULT_GROUPTYPE;
	};

	const getName = () => {
		return hasGlobaleEigenschaft()
			? currentReferer?.name
			: activeNode.name || "";
	};

	useEffect(() => {
		if (
			isLiteEigenschaft(activeNode) &&
			!!activeNode.datentyp &&
			activeNode.gruppeArt !== LiteGruppeType.Sequence
		) {
			/* For nodes with datentyp we write LiteGruppeType.Sequence as default value to the node */
			handleWrite("gruppeArt", LiteGruppeType.Sequence);
		}
	}, [hasDatentyp, activeNode, handleWrite]);
	return (
		<>
			<EditFormFieldLite
				definition={{
					readOnly: hasGlobaleEigenschaft() || isReadOnly,
					name: "name",
					label: "Name",
				}}
				value={getName()}
				placeholder={placeholder.anonymousStructure}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "name",
				}}
				error={
					<ValidationDisplayLite
						locator={{
							path: activePath,
							targetField: ValidationTargetField.NodeName,
						}}
					/>
				}
			/>
			{/* Dont show for LiteBausteinType.CodeDatentyp or Package or SchemaPackage */}
			{!isCodeType() && !isLitePaket(activeNode) && (
				<EditFormFieldLite
					definition={{
						readOnly: isGroupTypeReadonly(),
						name: "gruppeArt",
						label: "Gruppierungsart",
						type: "select",
						options: groupTypeOptions,
					}}
					value={getGroupTypeValue()}
					onChange={handleSelectChange}
					inputProps={{
						"data-testid": "details-field",
						"data-field-name": "gruppe-art",
					}}
				/>
			)}

			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "beschreibung",
					label: "Beschreibung",
					type: "textarea",
				}}
				value={activeNode.beschreibung || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "beschreibung",
				}}
			/>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "umsetzungshinweis",
					label: "Umsetzungshinweis",
					type: "textarea",
				}}
				value={activeNode.umsetzungshinweis || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "umsetzungshinweis",
				}}
			/>
		</>
	);
}
