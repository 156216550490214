import type { ReactNode } from "react";
import type { LiteId } from "../../../../../lib/validation/lite/IDSchemas";
import { LiteBausteinType } from "../../../../../lib/validation/lite/LiteEnums";
import { useAppSelector } from "../../../../../redux/hooks";
import { useStateSelector } from "../../../../EditorState";
import type { Nullish } from "../../../../../utils/types";
import type { RootState } from "../../../../../redux/store";
import { selectModellContainer } from "../../../../../redux/treeSlice";
import {
	createSelectChildrenFromModell,
	selectNodeFromModell,
	selectReferencedDatatypeFromModell,
} from "../../../../AppActor/actors/modellierungModel/selectors";
import { selectStandard } from "../../../../EditorState/selectors";

const selectIsLeaf =
	(standard: Nullish<string>, path: LiteId[]) => (state: RootState) => {
		const modell = selectModellContainer(standard)(state);
		const nodeId = path.at(-1);
		if (!modell || !nodeId) return false;
		const node = selectNodeFromModell(modell, nodeId);
		if (!node) return false;
		const children = createSelectChildrenFromModell(modell)(node, path);
		if (children.length > 0) return false;
		const ref = selectReferencedDatatypeFromModell(modell, node);
		return !(ref && ref.typ === LiteBausteinType.CodeDatentyp);
	};

export default function IsLeaf({
	children,
	path,
}: {
	children: ReactNode;
	path: LiteId[];
}): JSX.Element {
	const standard = useStateSelector(selectStandard());
	const isLeaf = useAppSelector(selectIsLeaf(standard, path));
	return <>{isLeaf ? children : null}</>;
}
