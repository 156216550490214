import fileDownload from "js-file-download";
import type { ReactNode } from "react";
import { useEffect, useMemo } from "react";
import { createStateContainer } from "@xoev/state-container";
import createImmutableMap from "@xoev/immutable-map";
import { useEventHandler } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import type SavedProfileData from "../../types/SavedProfileData";
import { RequestMethods, useResource } from "../Api";
import type { PostRequestOptions } from "../Api/types";
import createEditorState from "../EditorState/createEditorState";
import editorStateReducer from "../EditorState/editorStateReducer";
import {
	useEditorStateContext,
	useStateContainerContext,
	useStateSelector,
} from "../EditorState/hooks";
import EditorDataContext from "./EditorDataContext";
import { createSaveData } from "./helpers";
import type { EditorDataContextType } from "./types";
import { SaveContentType } from "./types";
import RequestErrorNotification from "../RequestErrorNotification";
import { selectStandard } from "../EditorState/selectors";
import { extraProps } from "../../utils/props";
import { getFileNameFromHeaders } from "../../utils/requests";
import { useTreeStructure } from "../TreeStructureProvider";
import createEmptyStateContainer from "../EditorState/emptyStateContainer";
import {
	selectConfiguration,
	selectGenerationConfiguration,
	setConfiguration,
} from "../../redux/configuration/configurationSlice";
import { useSendSavePointEvent } from "../EditorSavePoints/EditorSavePointContext";

const FALLBACK_FILENAME = "profil.xml";

const EditorDataProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element => {
	const { setStateContainer } = useEditorStateContext();
	const { getState } = useStateContainerContext();
	const dispatch = useAppDispatch();
	const configuration = useAppSelector(selectConfiguration());
	const sendSavePointEvent = useSendSavePointEvent();

	const {
		data: dataZip,
		headers: headersZip,
		request: requestZip,
		status: statusZip,
		error: errorZip,
	} = useResource<Blob, RequestMethods.Post, SavedProfileData>();

	const {
		request: requestJson,
		status: statusSaveToSession,
		error: errorSaveToSession,
	} = useResource<SavedProfileData, RequestMethods.Post>();

	const {
		data: initData,
		request: initRequest,
		status: initStatus,
		error: initError,
	} = useResource<SavedProfileData>();

	const runInitialRequest = useEventHandler(() => {
		initRequest("/v1/projekt");
	});
	useEffect(() => {
		runInitialRequest();
	}, [runInitialRequest]);

	const { requestTree, treeStatus, treeError } = useTreeStructure();
	const initContainer = useEventHandler((data: SavedProfileData) => {
		const standardKennung = data.metadaten.standard;
		// Request all package nodes on startup, so highlighting the tree works and
		// we have access to all message nodes
		requestTree({ standard: standardKennung });
		const state = createEditorState(data);
		setStateContainer(
			createStateContainer(editorStateReducer, createImmutableMap(state)),
		);
		dispatch(
			setConfiguration({
				configuration: data.konfiguration,
			}),
		);
	});

	useEffect(() => {
		if (initData) {
			initContainer(initData);
		}
	}, [initContainer, initData]);

	const resetContainer = useEventHandler(() => {
		setStateContainer(createEmptyStateContainer());
		setConfiguration({ configuration });
	});

	const generationConfiguration = useAppSelector(
		selectGenerationConfiguration(),
	);

	const handleSave = useEventHandler((acceptedContentType: SaveContentType) => {
		const data = createSaveData({
			state: getState().value,
			configuration,
		});

		const options: PostRequestOptions<SavedProfileData> = {
			method: RequestMethods.Post,
			data: {
				...data,
				konfiguration: {
					...data.konfiguration,
					generierung: generationConfiguration,
				},
			},
			headers: {
				Accept: acceptedContentType,
				"Content-Type": "application/json",
			},
		};

		if (acceptedContentType === SaveContentType.Zip) {
			requestZip(`/v1/projekt/${data.metadaten.kennung}`, options);
		} else if (acceptedContentType === SaveContentType.Json) {
			requestJson(`/v1/projekt/${data.metadaten.kennung}`, options);
		}
	});

	const saveToSession = useEventHandler(() => {
		handleSave(SaveContentType.Json);
		sendSavePointEvent({ type: "REPORT_AUTO_SAVE" });
	});
	const saveZip = useEventHandler(() => {
		handleSave(SaveContentType.Zip);
		sendSavePointEvent({ type: "REPORT_MANUAL_SAVE" });
	});

	useEffect(() => {
		if (dataZip) {
			const filename = getFileNameFromHeaders(headersZip, FALLBACK_FILENAME);
			fileDownload(dataZip, filename, "application/zip");
		}
	}, [dataZip, headersZip]);

	// We cannot access the `saveToSession` function in the
	// `EditorSavePointProvider` because we need its context here in the data
	// provider. To avoid the circular dependency, we send the handler to the
	// save point machine from here
	useEffect(() => {
		sendSavePointEvent({
			type: "SET_SAVE_HANDLER",
			requestAutoSave: saveToSession,
		});
	}, [saveToSession, sendSavePointEvent]);

	const ctx = useMemo(
		(): EditorDataContextType => ({
			saveZip,
			saveToSession,
			statusZip,
			statusSaveToSession,
			initContainer,
			errorZip,
			errorSaveToSession,
			initStatus,
			initError,
			resetContainer,
		}),
		[
			errorSaveToSession,
			errorZip,
			initContainer,
			saveToSession,
			saveZip,
			statusSaveToSession,
			statusZip,
			initStatus,
			initError,
			resetContainer,
		],
	);

	const standard = useStateSelector(selectStandard());
	const retryTreeFetch = () => {
		if (standard) {
			requestTree({ standard });
		}
	};

	return (
		<EditorDataContext.Provider value={ctx}>
			{/*
				When we get a 404 response we know that we don't have a project already.
				This is not actually an error, but communicates what we've asked for,
				so we hide the error message in that case. All other errors are unknown
				and should be displayed to the user
			*/}
			{initError?.status !== 404 && (
				<RequestErrorNotification
					id="init-load-project-request"
					error={initError}
					status={initStatus}
					onRetry={runInitialRequest}
				>
					Fehler beim Laden des Projekts. Bitte versuchen Sie es erneut. Sollte
					der Fehler weiterhin auftreten, wenden Sie sich bitte an die
					Administrator:innen
				</RequestErrorNotification>
			)}
			<RequestErrorNotification
				id="save-project-zip-error"
				error={errorZip}
				status={statusZip}
			>
				Fehler beim Speichern des Projekts
			</RequestErrorNotification>
			<RequestErrorNotification
				id="save-protject-session-error"
				error={errorSaveToSession}
				status={statusSaveToSession}
			>
				Fehler beim Speichern des Projekts
			</RequestErrorNotification>
			<RequestErrorNotification
				id="tree-request-error"
				error={treeError}
				status={treeStatus}
				{...extraProps(standard && { onRetry: retryTreeFetch })}
			>
				Fehler beim Abrufen der Datentypen und Nachrichtenstrukturen
			</RequestErrorNotification>
			{children}
		</EditorDataContext.Provider>
	);
};

export default EditorDataProvider;
