import { useEffect } from "react";
import { useEffectApiRef } from "./hooks";
import { useEventHandler, useStableNavigate } from "../../../../hooks";
import type { ProjektDbId } from "../../../../lib/validation/lite/IDSchemas";
import { RawLiteModelSchema } from "../../../../lib/validation/lite/LiteSchemas";
import { useLazyGetProjekteQuery } from "../../../../redux/apiSlice";

async function requestProjectById(dbId: ProjektDbId) {
	try {
		const response = await fetch(`/api/v2/projekte/${dbId}`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		if (response.status !== 200) {
			throw new Error(`Fetching project data for "${dbId}" failed`);
		}
		const data = await response.json();
		return await RawLiteModelSchema.parseAsync(data.modell);
	} catch (error) {
		// Show the error in the console for debugging purposes
		// eslint-disable-next-line no-console
		console.error(error);
		throw error;
	}
}

export default function EffectApi(): JSX.Element {
	const effectApi = useEffectApiRef();
	const navigate = useStableNavigate();
	const [triggerGetProjekte] = useLazyGetProjekteQuery();

	const requestProjektList = useEventHandler(() =>
		triggerGetProjekte().unwrap(),
	);

	useEffect(() => {
		effectApi.send({ type: "SET_FN", key: "navigate", fn: navigate });
	}, [effectApi, navigate]);

	useEffect(() => {
		effectApi.send({
			type: "SET_FN",
			key: "requestProjectById",
			fn: requestProjectById,
		});
	}, [effectApi]);

	useEffect(() => {
		effectApi.send({
			type: "SET_FN",
			key: "requestProjektList",
			fn: requestProjektList,
		});
	}, [effectApi, requestProjektList]);

	return <></>;
}
