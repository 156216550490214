import type { ImmutableToJS, StructureToJS } from "@xoev/immutable-map";
import type ProfilingMetadataValues from "../../types/ProflierungMetadataValues";
import type SavedProfileData from "../../types/SavedProfileData";
import type {
	SavedPropertyProfile,
	SavedConfiguration,
	SavedDatatypeProfile,
	SavedMessageProfileConfiguration,
	SavedProfile,
	SavedProfileDocumentation,
	SavedProfileMetadata,
} from "../../types/SavedProfileData";
import { stringifyId } from "../../utils/xoev";
import type {
	DatatypeMap,
	EditorState,
	StateConfigMap,
	MessageProfileValues,
	MessageProfileConfiguration,
	PropertyProfileMap,
} from "../EditorState/types";
import { normalizeString } from "../../utils/normalizeString";
import StatusFassung from "../../types/StatusFassung";
import type { ConfigurationState } from "../../redux/configuration/types";
import { ProjektType } from "../../lib/validation/lite/LiteEnums";

function createProfileConfig(
	profileConfig: StructureToJS<MessageProfileConfiguration> | undefined,
): SavedMessageProfileConfiguration {
	const { codeliste, ...restConfig } = profileConfig || {};
	const nonPartialCodelist = codeliste && {
		...codeliste,
		kennung: codeliste.kennung || "",
		version: codeliste.version || "",
	};
	return {
		...restConfig,
		...(nonPartialCodelist ? { codeliste: nonPartialCodelist } : {}),
	};
}

function createPropertyList(
	properties: ImmutableToJS<PropertyProfileMap> | undefined,
	stateConfig: StateConfigMap,
): SavedPropertyProfile[] {
	return Object.entries(properties || {}).map(([, prop]) => {
		const propertyValues = stateConfig
			?.get("profilierung")
			?.get("eigenschaften");
		const propertyConfig = propertyValues?.find(
			(configProp) => configProp.nameTechnisch === prop.name,
		);
		if (!propertyConfig) {
			const expected = (propertyValues || [])
				.map((a) => `"${a.nameTechnisch}"`)
				.join(", ");
			throw new Error(
				`Unknown property "${prop.name}" cannot be mapped to any ` +
					`property definition. Expect one of:\n[${expected}]`,
			);
		}
		const config: SavedPropertyProfile = {
			definition: propertyConfig,
			value: prop.value,
		};
		return config;
	});
}

function createProfile(
	profileMap: { [nodeId: string]: StructureToJS<MessageProfileValues> },
	stateConfig: StateConfigMap,
): SavedProfile[] {
	return Object.entries(profileMap).map(([nodeId, values]) => {
		const { konfiguration, eigenschaften, ...withoutConfigAndProps } = values;
		const properties = createPropertyList(eigenschaften, stateConfig);
		const config = createProfileConfig(konfiguration);

		const profile: SavedProfile = {
			...withoutConfigAndProps,
			syntaxPfad: nodeId,
		};
		if (properties.length) {
			profile.eigenschaften = properties;
		}
		if (Object.keys(config).length) {
			profile.konfiguration = config;
		}

		return profile;
	});
}

function createDatatypes(
	datentypen: ImmutableToJS<DatatypeMap>,
	stateConfig: StateConfigMap,
): SavedDatatypeProfile[] {
	const datentypenMap = datentypen;
	return Object.entries(datentypenMap).flatMap(([nodeId, values]) => {
		const { restrictions } = values;
		return Object.entries(restrictions || {}).map(
			([restrictionId, restrictionValues]) => {
				const profilesRestructured = restrictionValues.profile
					? createProfile(restrictionValues.profile, stateConfig)
					: [];
				return {
					id: restrictionId,
					datentyp: nodeId,
					name: restrictionValues.name,
					beschreibung: restrictionValues.beschreibung,
					umsetzungshinweis: restrictionValues.umsetzungshinweis,
					profile: profilesRestructured,
				};
			},
		);
	});
}

function createDocumentation(state: EditorState): SavedProfileDocumentation[] {
	const docEntries = state.get("dokumentation").entrySeq();
	// Make sure the configuration is always first in the list
	const hiddenDocs = docEntries.filter(([, doc]) => doc.get("versteckt"));
	const visibleDocs = docEntries
		.filter(([, doc]) => !doc.get("versteckt"))
		.sortBy(([, doc]) => doc.get("index"));
	return hiddenDocs
		.concat(visibleDocs)
		.map(([name, doc]) => {
			// The backend will generate these chapters itself, so there is no need for
			// us to sync the generated content to the state and send it back. We just
			// ignore it...
			const immutableDoc = doc.get("generiert") ? doc.set("inhalt", "") : doc;
			return { ...immutableDoc.toJS(), name: name.toString() };
		})
		.toArray();
}

function createMetadata(
	metadaten: Partial<ProfilingMetadataValues>,
): SavedProfileMetadata {
	const userMetadata = metadaten;
	const generatedNameTechnisch = normalizeString(userMetadata.nameKurz || "");
	const now = new Date().toISOString();
	return {
		...userMetadata,
		nameTechnisch: userMetadata.nameTechnisch || generatedNameTechnisch,
		datumErstellung: userMetadata.datumErstellung || now,
		datumLetzteBearbeitung: now,
		// These properties should all be defined, as will be enforced by the
		// validation, but ts can't know that
		beschreibung: userMetadata.beschreibung || "",
		herausgeber: userMetadata.herausgeber || "",
		kennung: userMetadata.kennung || "",
		nameKurz: userMetadata.nameKurz || "",
		nameLang: userMetadata.nameLang || "",
		standard: userMetadata.standard || "",
		version: userMetadata.version || "",
		statusFassung: userMetadata.statusFassung ?? StatusFassung.Entwurf,
	};
}

function createConfig(
	configuration: ConfigurationState | null,
	stateConfig: StateConfigMap,
): SavedConfiguration {
	const properties = stateConfig.get("profilierung")?.get("eigenschaften");
	return {
		generierung: configuration?.generierung || undefined,
		dokumentation: configuration?.dokumentation || undefined,
		profilierung:
			properties && properties.length
				? { eigenschaften: properties }
				: undefined,
	};
}

// eslint-disable-next-line import/prefer-default-export
export function createSaveData({
	state,
	configuration,
}: {
	state: EditorState;
	configuration: ConfigurationState | null;
}): SavedProfileData {
	const { metadaten, profile, datentypen } = state.toJS();
	const stateConfig = state.get("konfiguration");

	const dokumentation = createDocumentation(state);
	const config = createConfig(configuration, stateConfig);
	const metadata = createMetadata(metadaten);
	const profiles = createProfile(profile, stateConfig);
	const datatypes = createDatatypes(datentypen, stateConfig);

	return {
		typ: ProjektType.Profilierung,
		metadaten: metadata,
		profile: profiles,
		profilierteVersionStandard: {
			kennung: stringifyId(metadata.standard, metadata.version),
			version: metadata.version,
		},
		dokumentation,
		datentypen: datatypes,
		konfiguration: config,
	};
}
