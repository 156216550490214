import "./GeschaeftsregelSingleDisplay.scss";
import type { ReactNode } from "react";
import type { Geschaeftsregel } from "../../../lib/validation/lite/LiteSchemas";
import { labels } from "../../../resources/textConstants";

const GeschaeftsregelSingleDisplay = ({
	geschaeftsregel,
	children,
	error,
}: {
	geschaeftsregel: Geschaeftsregel;
	children?: React.ReactNode;
	error?: string | ReactNode | null;
}): JSX.Element => {
	return (
		<div className="geschaeftsregel-display">
			<h4 className="geschaeftsregel-display__kennung">
				{geschaeftsregel.kennung}
			</h4>
			{error}

			<div className="geschaeftsregel-display__container">
				<span className="geschaeftsregel-display__label">
					{labels.geschaeftsregeln.spezifikation}:
				</span>
				<span className="geschaeftsregel-display__value">
					{geschaeftsregel.spezifikation}
				</span>
			</div>
			<div className="geschaeftsregel-display__container">
				<span className="geschaeftsregel-display__label">
					{labels.geschaeftsregeln.beschreibung}:
				</span>
				<span className="geschaeftsregel-display__value">
					{geschaeftsregel.beschreibung}
				</span>
			</div>

			{!!geschaeftsregel.verbindlichkeit && (
				<div className="geschaeftsregel-display__container">
					<span className="geschaeftsregel-display__label">
						{labels.geschaeftsregeln.verbindlichkeit}:
					</span>
					<span className="geschaeftsregel-display__value">
						{geschaeftsregel.verbindlichkeit}
					</span>
				</div>
			)}
			{children}
		</div>
	);
};

export default GeschaeftsregelSingleDisplay;
