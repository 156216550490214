import { isImmutable } from "immutable";
import { configureStore, combineReducers, isPlain } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import appInfoReducer from "./appInfoSlice";
import { apiSlice } from "./apiSlice";
import uiReducer from "./uiSlice";
import treeReducer from "./treeSlice";
import codelistReducer from "./codelistSlice";
import standardsReducer from "./standardsSlice";
import configurationReducer from "./configuration/configurationSlice";
import {
	messageApiErrorMiddleware,
	messagesApiSlice,
} from "./messagesApiSlice";
import documentationReducer from "./documentationSlice";
import messagesReducer from "./messagesSlice";
import debugReducer from "./debugSlice";
import { toMutable } from "../utils/types";

// Augment middleware to consider Immutable.JS iterables serializable
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSerializable = (value: any) => isImmutable(value) || isPlain(value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEntries = (value: any) =>
	isImmutable(value)
		? value
				.entrySeq()
				.map(([k, v]) => toMutable([String(k), v] as const))
				.toArray()
		: Object.entries(value);

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	whitelist: ["standards", "documentation", "messages", "debug"],
};

const reducers = {
	ui: uiReducer,
	appInfo: appInfoReducer,
	[apiSlice.reducerPath]: apiSlice.reducer,
	tree: treeReducer,
	codelist: codelistReducer,
	standards: standardsReducer,
	configuration: configurationReducer,
	[messagesApiSlice.reducerPath]: messagesApiSlice.reducer,
	messages: messagesReducer,
	documentation: documentationReducer,
	debug: debugReducer,
};
const rootReducer = combineReducers(reducers);

export const store = configureStore({
	reducer: persistReducer(
		persistConfig,
		rootReducer,
	) as unknown as typeof reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				isSerializable,
				getEntries,
			},
		}).concat([
			messageApiErrorMiddleware,
			apiSlice.middleware,
			messagesApiSlice.middleware,
		]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const { dispatch } = store;
export const persistor = persistStore(store);
