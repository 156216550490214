import { v4 as uuid } from "uuid";
import type { RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import { extractChildNodeEntries } from "../../../helpers";
import styles from "./ItemRenderer.module.scss";
import UnknownMarkup from "../UnknownMarkup";

const ItemRenderer: RendererImplementationComponent<
	RendererImplementation.Item
> = ({ markup, renderer: Renderer }) => {
	const children = extractChildNodeEntries(markup);

	if (children.length === 0) return <UnknownMarkup markup={markup} />;

	return (
		<li
			className={styles["xoev-renderer-listitem"]}
			data-testid="item-renderer"
		>
			{children.map(([, childMarkup]) => {
				return (
					<Renderer
						markup={childMarkup}
						key={`xoev-renderer--listitem-key-${uuid()}`}
					/>
				);
			})}
		</li>
	);
};

export default ItemRenderer;
