import { useReducer, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, TableBody } from "@mui/material";
import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../../EditorState";
import {
	selectDatatypeEntry,
	selectRestrictionEntries,
	selectStandard,
} from "../../../EditorState/selectors";
import DatatypeDetailsTable from "./DatatypeDetailsTable";
import DatatypeEditCells from "./DatatypeEditCells";
import DatatypeTableHeader from "./DatatypeTableHeader";
import DatatypeTableRow from "./DatatypeTableRow";
import useCheckedActiveNode from "./useCheckedActiveNode";
import DatatypeRestrictionDisplay from "./DatatypeRestrictionDisplay";
import {
	RestrictionIdSchema,
	type DatatypeProfileValues,
	type RestrictionProfileValues,
} from "../../../EditorState/types";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import { NotADatatypeError } from "../../../../utils/profilingHelpers";
import { useAppSelector } from "../../../../redux/hooks";
import { selectQName } from "../../../../redux/treeSlice";
import { AssertionError } from "../../../../utils/error";
import "./DatatypeRestrictionsTable.scss";
import type { LiteId } from "../../../../lib/validation/lite/IDSchemas";

type EventType =
	| { type: "ACTIVATE_ROW"; id: string; nodeId: LiteId }
	| { type: "DEACTIVATE_ROW" }
	| { type: "DELETE_ROW"; id: string }
	| { type: "ACTIVATE_ADD"; nodeId: LiteId }
	| { type: "DEACTIVATE_ADD" };

interface RestrictionTableState {
	activeRowId: null | string;
	isAddRowActive: boolean;
	addRestrictionTemplate: ImmutableMap<RestrictionProfileValues>;
	nodeId: LiteId | null;
}

const FOCUS_TIMEOUT = 100;

const createRestriction = (name: string = "", beschreibung: string = "") =>
	createImmutableMap<RestrictionProfileValues>({
		id: RestrictionIdSchema.parse(uuidv4()),
		name,
		beschreibung,
	});

const initialState: RestrictionTableState = {
	activeRowId: null,
	isAddRowActive: false,
	addRestrictionTemplate: createRestriction(),
	nodeId: null,
};

const emptyDatatypeEntry = createImmutableMap<Partial<DatatypeProfileValues>>();

function reducer(
	state: RestrictionTableState,
	event: EventType,
): RestrictionTableState {
	switch (event.type) {
		case "ACTIVATE_ROW":
			return {
				...state,
				activeRowId: event.id,
				isAddRowActive: false,
				nodeId: event.nodeId,
			};
		case "DEACTIVATE_ROW":
			return { ...state, activeRowId: null, nodeId: null };
		case "DELETE_ROW":
			return {
				...state,
				activeRowId: state.activeRowId === event.id ? null : state.activeRowId,
			};
		case "ACTIVATE_ADD":
			return {
				...state,
				activeRowId: null,
				isAddRowActive: true,
				addRestrictionTemplate: createRestriction(),
				nodeId: event.nodeId,
			};
		case "DEACTIVATE_ADD":
			return { ...state, isAddRowActive: false, nodeId: null };
		default:
			return state;
	}
}

export default function DatatypeRestrictionsTable(): JSX.Element {
	const activeNode = useCheckedActiveNode(DatatypeRestrictionsTable.name);
	NotADatatypeError.assert(activeNode);
	const applyPatch = useApplyPatch();
	const { setDatatype } = useEditorPatchCreators();
	const [state, dispatch] = useReducer(reducer, initialState);
	const addButtonRef = useRef<HTMLButtonElement>(null);
	const isMountedRef = useIsMountedRef();
	const standard = useStateSelector(selectStandard());
	const activeQName = useAppSelector(selectQName(standard, activeNode));
	AssertionError.notNullish(
		activeQName,
		"`activeQName` in <DatatypeRestrictionsTable />",
	);
	const dataTypeEntry = useStateSelector(selectDatatypeEntry(activeQName));
	const values = dataTypeEntry || emptyDatatypeEntry;
	const restrictionEntries = useStateSelector(
		selectRestrictionEntries(activeQName),
	);

	const handleEditSubmit = (
		restriction: ImmutableMap<RestrictionProfileValues>,
	) => {
		const nextDatatype = values.setIn(
			["restrictions", restriction.get("id")],
			restriction,
		);
		applyPatch(setDatatype({ datatype: nextDatatype, id: activeQName }));
		dispatch({ type: "DEACTIVATE_ROW" });
	};

	const handleActivateEdit = (id: string) => {
		dispatch({ type: "ACTIVATE_ROW", id, nodeId: activeNode.id });
	};

	const handleCopy = (restrictionId: string) => {
		const entry = restrictionEntries.find(([id]) => id === restrictionId);

		if (entry === undefined) {
			throw new Error("entry is undefined");
		}

		const [, restriction] = entry;
		const newRestriction = createRestriction(
			`${restriction.get("name")} (Kopie)`,
			restriction.get("beschreibung"),
		);
		applyPatch(
			setDatatype({
				datatype: values.setIn(
					["restrictions", newRestriction.get("id")],
					newRestriction,
				),
				id: activeQName,
			}),
		);
	};

	const deactivateAdd = () => {
		dispatch({ type: "DEACTIVATE_ADD" });
		setTimeout(() => {
			if (!isMountedRef.current || !addButtonRef.current) return;
			addButtonRef.current.focus();
		}, FOCUS_TIMEOUT);
	};

	const handleAdd = (restriction: ImmutableMap<RestrictionProfileValues>) => {
		applyPatch(
			setDatatype({
				datatype: values.setIn(
					["restrictions", restriction.get("id")],
					restriction,
				),
				id: activeQName,
			}),
		);
		deactivateAdd();
	};

	const handleDiscard = () => {
		dispatch({ type: "DEACTIVATE_ROW" });
	};

	const handleDelete = (id: string) => {
		applyPatch(
			setDatatype({
				datatype: values.deleteIn(["restrictions", id]),
				id: activeQName,
			}),
		);
		dispatch({ type: "DELETE_ROW", id });
	};

	const handleActivateAdd = () => {
		dispatch({ type: "ACTIVATE_ADD", nodeId: activeNode.id });
	};

	const handleAddDiscard = () => {
		deactivateAdd();
	};

	if (state.nodeId !== null && state.nodeId !== activeNode.id) {
		if (state.activeRowId) {
			handleDiscard();
		}

		if (state.isAddRowActive) {
			deactivateAdd();
		}
	}

	return (
		<>
			<DatatypeDetailsTable
				title="Einschränkungen"
				aria-label="Informationen zum den Einschränkungen des Datentyps"
				className="datatype-restriction-table"
			>
				{restrictionEntries.size !== 0 && <DatatypeTableHeader withActions />}
				<TableBody>
					{restrictionEntries.map(([id, restrictionValues]) => (
						<DatatypeTableRow
							key={id}
							data-restriction-name={restrictionValues.get("name")}
						>
							{state.activeRowId === id ? (
								<DatatypeEditCells
									values={restrictionValues}
									onSubmit={handleEditSubmit}
									onDiscard={handleDiscard}
								/>
							) : (
								<DatatypeRestrictionDisplay
									restriction={restrictionValues}
									onActivateEdit={handleActivateEdit}
									onDelete={handleDelete}
									onCopy={handleCopy}
								/>
							)}
						</DatatypeTableRow>
					))}
					{!state.activeRowId && state.isAddRowActive && (
						<DatatypeTableRow>
							<DatatypeEditCells
								values={state.addRestrictionTemplate}
								onSubmit={handleAdd}
								onDiscard={handleAddDiscard}
							/>
						</DatatypeTableRow>
					)}
				</TableBody>
			</DatatypeDetailsTable>
			{!state.activeRowId && !state.isAddRowActive && (
				<Button
					className="datatype-restriction-table__add-button"
					ref={addButtonRef}
					onClick={handleActivateAdd}
					variant="contained"
					data-testid="open-restriction-editing"
				>
					Neue Einschränkung hinzufügen
				</Button>
			)}
		</>
	);
}
