import { useActor } from "@xstate/react";
import type { KeyboardEventHandler } from "react";
import { useEffect } from "react";
import { useSendStoreEvent } from "../../../AppActor/EventStore/hooks";
import EditorSideBar from "../../../EditorSideBar";
import searchInputMachine from "./searchInput.machine";
import {
	useIsSearchLoading,
	useLatestSearchTerm,
} from "../../../AppActor/actors/modellierungModel/search/hooks";
import type { ProjektId } from "../../../../lib/validation/lite/IDSchemas";

export default function BausteinSearch({
	projektId,
	describedBy,
}: {
	projektId: ProjektId;
	describedBy: string;
}): JSX.Element {
	const isSearching = useIsSearchLoading();
	const latestSearchTerm = useLatestSearchTerm();
	const sendStoreEvent = useSendStoreEvent();
	const [state, send] = useActor(searchInputMachine, {
		input: {
			onSearch: (term) =>
				sendStoreEvent({
					type: "SEARCH.REQUEST",
					payload: { projektId, term },
				}),
			onClear: () =>
				sendStoreEvent({
					type: "SEARCH.CLEAR",
					payload: { projektId },
				}),
		},
	});
	const isTyping = state.matches("Typing");
	const isLoading = isSearching || isTyping;

	useEffect(() => {
		// Set the latest search term, so reloading the page will re-initialize
		// the search term in the ui correctly
		send({ type: "SET_TERM", value: latestSearchTerm });
	}, [latestSearchTerm, send]);

	const handleChange = (value: string) => {
		send({ type: "CHANGE", value });
	};

	const handleClear = () => {
		handleChange("");
	};

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "Enter") {
			const delta = e.shiftKey ? -1 : 1;
			sendStoreEvent({
				type: "SEARCH.SHIFT_RESULT_FOCUS",
				payload: { projektId, delta },
			});
		}
	};

	return (
		<EditorSideBar.Search
			label="Modellstruktur durchsuchen"
			placeholder="Modellstruktur durchsuchen"
			onChange={handleChange}
			onClear={handleClear}
			onKeyDown={handleKeyDown}
			value={state.context.value}
			loading={isLoading}
			aria-describedby={describedBy}
		/>
	);
}
