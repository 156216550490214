import type ProfilingMetadataValues from "../types/ProflierungMetadataValues";
import StatusFassung from "../types/StatusFassung";
import type { FieldDefinition, FormHelpers } from "../components/EditFormField";
import { createFormHelpers } from "../components/EditFormField";

export function createMetadataFields(
	standardDefinition: Omit<
		FieldDefinition<ProfilingMetadataValues>,
		"name" | "label"
	>,
): FieldDefinition<ProfilingMetadataValues>[] {
	return [
		{
			name: "standard",
			label: "Standard",
			isRequired: true,
			...(standardDefinition as Record<string, never>),
		},
		{
			type: "select",
			name: "statusFassung",
			label: "Status Fassung",
			defaultValue: "ENTWURF",
			options: [
				{
					label: "Entwurf",
					value: StatusFassung.Entwurf,
					"data-testid": "status-select-option",
					"data-status-value": StatusFassung.Entwurf,
				},
				{
					label: "Endfassung",
					value: StatusFassung.Endfassung,
					"data-testid": "status-select-option",
					"data-status-value": StatusFassung.Endfassung,
				},
			],
			isRequired: true,
		},
		{ name: "nameKurz", label: "Name (kurz)", isRequired: true },
		{ name: "nameLang", label: "Name (lang)", isRequired: true },
		{
			name: "beschreibung",
			label: "Beschreibung",
			type: "textarea",
			isRequired: true,
		},
		{ name: "herausgeber", label: "Herausgeber", isRequired: true },
		{ name: "version", label: "Version", isRequired: true },
		{
			name: "beschreibungVersion",
			label: "Versionsbeschreibung",
			type: "textarea",
		},
		{ name: "kennung", label: "Kennung", readOnly: true },
	];
}

export function createMetadataFormHelpers(
	standardDefinition: Omit<
		FieldDefinition<ProfilingMetadataValues>,
		"name" | "label"
	>,
): FormHelpers<ProfilingMetadataValues> {
	return createFormHelpers<ProfilingMetadataValues>(
		createMetadataFields(standardDefinition),
	);
}

export const metadataFormHelpers = createMetadataFormHelpers({
	readOnly: true,
});
