import { v4 as uuid } from "uuid";
import { extractChildNodeEntries } from "../../../helpers";
import type { RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import styles from "./ParagraphRenderer.module.scss";

const ParagraphRenderer: RendererImplementationComponent<
	RendererImplementation.Paragraph
> = ({ markup, renderer: Renderer }) => {
	const children = extractChildNodeEntries(markup);

	return (
		<div
			className={styles["xoev-renderer-paragraph"]}
			data-testid="paragraph-renderer"
		>
			{children.map(([, childMarkup]) => {
				return (
					<Renderer
						markup={childMarkup}
						key={`xoev-renderer-paragraph-child-key-${uuid()}`}
					/>
				);
			})}
		</div>
	);
};

export default ParagraphRenderer;
