import { z } from "zod";
import { ProjektTypeSchema } from "./LiteEnums";
import { ProjektDbIdSchema } from "./IDSchemas";
import { RawLiteModelBaseSchema } from "./LiteSchemas";

export const ApiProjektSchema = z.object({
	id: ProjektDbIdSchema,
	nameKurz: z.string(),
	kennung: z.string(),
	version: z.string().optional(),
	typ: ProjektTypeSchema,
});
export type ApiProjekt = z.infer<typeof ApiProjektSchema>;

export const ApiProjektListSchema = z.array(ApiProjektSchema);
export type ApiProjektList = z.infer<typeof ApiProjektListSchema>;

const RawLiteModelTypSchema = RawLiteModelBaseSchema.extend({
	typ: ProjektTypeSchema,
});
export const ApiUploadProjektSchema = z.object({
	id: ProjektDbIdSchema,
	modell: RawLiteModelTypSchema,
	name: z.string(),
});
export type ApiUploadProjekt = z.infer<typeof ApiUploadProjektSchema>;
