import { Link } from "react-router-dom";
import type { ReactNode } from "react";
import {
	useDefinitionPath,
	useIsDefinedInMainModell,
	useModellierungDatatypesAndGlobals,
	useNodeTargetHref,
} from "../../../../AppActor/actors/modellierungModel/hooks";
import type {
	LiteBaustein,
	LiteEigenschaft,
} from "../../../../../lib/validation/lite/LiteSchemas";
import InfoRendererDisplay from "../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import DynamicInfo from "../../../../ui/DynamicInfo";
import type { DisplayRendererProps } from "../types";
import {
	getReferersConfiguration,
	useReferencingEntries,
} from "./shared/helpers";
import ReferersName from "./shared/ReferersName";

export default function RefererDisplay({
	activeNode,
}: DisplayRendererProps<LiteEigenschaft | LiteBaustein>): JSX.Element {
	const { currentReferer, currentKey } = useReferencingEntries(activeNode);
	const datatypes = useModellierungDatatypesAndGlobals();

	let label: string | undefined;
	let fallback: string | undefined;
	if (currentKey) {
		const referersConfig = getReferersConfiguration(currentKey ?? "datentyp");
		label = referersConfig.label;
		fallback = referersConfig.fallback;
	}

	const definitionPath = useDefinitionPath(currentReferer?.id);
	const isDefinedInMainModell = useIsDefinedInMainModell(currentReferer?.id);
	const href = useNodeTargetHref(definitionPath);
	const name = currentReferer && (
		<ReferersName datatypes={datatypes} refEntry={currentReferer} />
	);

	let value: ReactNode = (
		<>
			{name}
			<DynamicInfo
				infoKey="datatypeNotInStandard"
				inline="end"
				data-testid="override-info"
			/>
		</>
	);
	if (!currentReferer) {
		value = null;
	}

	if (isDefinedInMainModell && href) {
		value = <Link to={href}>{name}</Link>;
	}

	return (
		<>
			{currentReferer && (
				<InfoRendererDisplay label={label} value={value} fallback={fallback} />
			)}
		</>
	);
}
