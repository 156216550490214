import { StrictMode } from "react";
import ErrorBoundary from "@xoev/error-boundary";
import { MuiThemeProvider } from "@xoev/mui-theme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createActorContext } from "@xstate/react";
import { KeycloakProvider } from "./components/Keycloak/KeycloakProvider";
import { persistor, store } from "./redux/store";
import { ApiProvider } from "./components/Api";
import { createSessionContext } from "./components/Session";
import useApiOrigin from "./hooks/useApiOrigin";
import createAppMachine from "./components/AppActor/app.machine";
import appActors from "./components/AppActor/appActors";
import AppActorRedirectContext from "./components/AppActor/AppActorRedirectContext";
import AppView from "./AppView";
import "@xoev/error-boundary/ErrorBoundary.css";

const SessionContext = createSessionContext();
const AppActorContext = createActorContext(createAppMachine(appActors));

const App = ({ origin = "" }: { origin?: string } = {}): JSX.Element => {
	// Use the origin defined by either the rendering package or by the developer
	// if a custom origin is defined in `localStorage`
	let storedOrigin = origin;
	if (process.env.BUILD_MODE !== "production") {
		// This call to our custom hook does not actually violate the rules of
		// hooks, since `process.env.BUILD_MODE` is replaced by a constant string
		// during the build process. Wrapping it in this conditional will however
		// help the bundlers identify it as dead code and treeshake the
		// `useApiOrigin`, so it won't be included in the production bundle
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const apiOrigin = useApiOrigin();
		if (apiOrigin) {
			storedOrigin = apiOrigin;
		}
	}

	return (
		<StrictMode>
			<ErrorBoundary>
				<MuiThemeProvider>
					<Provider store={store}>
						<KeycloakProvider>
							<PersistGate loading={null} persistor={persistor}>
								<ApiProvider
									// We need to pass this context via a prop, to avoid a dependency
									// cycle between SessionProvider and ApiProvider
									sessionContext={SessionContext}
									origin={storedOrigin}
									base="/api"
								>
									<AppActorRedirectContext.Provider value={AppActorContext}>
										<AppActorContext.Provider>
											<AppView />
										</AppActorContext.Provider>
									</AppActorRedirectContext.Provider>
								</ApiProvider>
							</PersistGate>
						</KeycloakProvider>
					</Provider>
				</MuiThemeProvider>
			</ErrorBoundary>
		</StrictMode>
	);
};

export default App;
