import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, raise, setup } from "xstate";
import SegmentTrie from "@xoev/segment-trie";
import { translateStoreEvents } from "../../../EventStore/helpers";
import type { ExtractStoreEventPayload } from "../../../EventStore/StoreEvent";
import type { SearchResult } from "./types";
import type { LiteSearchTrie } from "./helpers";
import { EMPTY_TRIE, getFocusIndex, identity } from "./helpers";
import type {
	LiteId,
	ProjektId,
} from "../../../../../lib/validation/lite/IDSchemas";
import type { ProjektMeta } from "../../project/types";

export type ModellierungSearchQueryActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof modellierungSearchQueryMachine
>;
export type ModellierungSearchQueryContext = ProjektMeta & {
	result: SearchResult;
	trie: LiteSearchTrie;
	term: string;
	index: number;
};
export type ModellierungSearchQueryEvent =
	| ({ type: "SEARCH_REQUEST" } & ExtractStoreEventPayload<"SEARCH.REQUEST">)
	| ({ type: "SEARCH_RESPONSE" } & ExtractStoreEventPayload<"SEARCH.RESPONSE">)
	| ({ type: "SEARCH_CLEAR" } & ExtractStoreEventPayload<"SEARCH.CLEAR">)
	| ({
			type: "UPDATE_RESULT_INDEX";
	  } & ExtractStoreEventPayload<"SEARCH.UPDATE_RESULT_INDEX">)
	| {
			type: "CHECK_EXTERNAL_PATH_UPDATE";
			projektId: ProjektId;
			fullPath: LiteId[];
	  }
	| {
			type: "UPDATE_INDEX_EXTERNAL";
			projektId: ProjektId;
			index: number | undefined;
	  };

const EMPTY_RESULT: SearchResult = { ids: [], paths: [] };

const modellierungSearchQueryMachine = setup({
	types: {
		events: {} as ModellierungSearchQueryEvent,
		context: {} as ModellierungSearchQueryContext,
		input: {} as ProjektMeta,
	},
	actors: {
		translateEvents: translateStoreEvents<ModellierungSearchQueryEvent>(
			{
				"SEARCH.REQUEST": ({ payload }) => ({
					type: "SEARCH_REQUEST",
					...payload,
				}),
				"SEARCH.RESPONSE": ({ payload }) => ({
					type: "SEARCH_RESPONSE",
					...payload,
				}),
				"SEARCH.CLEAR": ({ payload }) => ({
					type: "SEARCH_CLEAR",
					...payload,
				}),
				"SEARCH.UPDATE_RESULT_INDEX": ({ payload }) => ({
					type: "UPDATE_RESULT_INDEX",
					...payload,
				}),
				"MODELLIERUNG_TREE.STATE.ACTIVATE": ({ payload }) => ({
					type: "CHECK_EXTERNAL_PATH_UPDATE",
					...payload,
				}),
			},
			{ replayEventLog: true },
		),
	},
	actions: {
		writeSearchResult: assign({
			result: ({ event }) => {
				assertEvent(event, "SEARCH_RESPONSE");
				return event.result;
			},
			trie: ({ event }) => {
				assertEvent(event, "SEARCH_RESPONSE");
				const entries = event.result.paths.map(
					(path, index) => [path, index] as const,
				);
				return new SegmentTrie(identity, entries);
			},
			index: 0,
		}),
		clearSearch: assign({
			result: EMPTY_RESULT,
			trie: EMPTY_TRIE,
			term: "",
			index: 0,
		}),
		writeTerm: assign({
			term: ({ event }) => {
				assertEvent(event, "SEARCH_REQUEST");
				return event.term;
			},
		}),
		updateIndex: assign({
			index: ({ event }) => {
				assertEvent(event, ["UPDATE_RESULT_INDEX", "UPDATE_INDEX_EXTERNAL"]);
				if (event.index === undefined) {
					throw new Error("`event.index` cannot be undefined in `updateIndex`");
				}
				return event.index;
			},
		}),
		raisePathUpdate: raise(({ event, context }) => {
			assertEvent(event, "CHECK_EXTERNAL_PATH_UPDATE");
			const index = getFocusIndex(event.fullPath, context.trie);
			return {
				type: "UPDATE_INDEX_EXTERNAL" as const,
				index,
				projektId: context.projektId,
			};
		}),
	},
	guards: {
		isSelf: ({ context, event }) => {
			return context.projektId === event.projektId;
		},
		hasFocusIndex: ({ event }) => {
			assertEvent(event, "UPDATE_INDEX_EXTERNAL");
			return event.index !== undefined;
		},
	},
}).createMachine({
	id: "modellierungSearch:query",
	context: ({ input }) => ({
		...input,
		result: EMPTY_RESULT,
		trie: EMPTY_TRIE,
		term: "",
		index: 0,
	}),
	invoke: { src: "translateEvents" },
	initial: "Idle",
	states: {
		Idle: {},
		Searching: {
			initial: "Done",
			states: {
				Done: {},
				Loading: {},
			},
		},
	},
	on: {
		SEARCH_REQUEST: {
			guard: "isSelf",
			target: ".Searching.Loading",
			actions: "writeTerm",
		},
		SEARCH_RESPONSE: {
			guard: "isSelf",
			target: ".Searching.Done",
			actions: "writeSearchResult",
		},
		SEARCH_CLEAR: {
			guard: "isSelf",
			target: ".Idle",
			actions: "clearSearch",
		},
		UPDATE_RESULT_INDEX: {
			guard: "isSelf",
			actions: "updateIndex",
		},
		CHECK_EXTERNAL_PATH_UPDATE: {
			guard: "isSelf",
			actions: "raisePathUpdate",
		},
		UPDATE_INDEX_EXTERNAL: {
			guard: "hasFocusIndex",
			actions: "updateIndex",
		},
	},
});

export default modellierungSearchQueryMachine;
