import type { ReactNode } from "react";
import { CircularProgress } from "@mui/material";
import {
	isMetadataValidationResult,
	isProfileValidationResult,
	isRestrictionProfileValidationResult,
	isRestrictionValidationResult,
	sortBySeverity,
	targetFieldLabels,
	validationIcons,
	validationSaveProjectNotes,
	validationSeverityNames,
	validationSuccessIcon,
} from "../../../ProfilierungValidation/helpers";
import type {
	ValidationResultBase,
	Severity,
	ValidationTargetFieldProfiling,
} from "../../../ProfilierungValidation/types";
import {
	ValidationResultGroup,
	ValidationStatus,
} from "../../../ProfilierungValidation/types";
import { encodeXPath } from "../../../../utils/url";
import type { LiteModellContainer } from "../../../AppActor/actors/modellierungModel/types";
import {
	selectNodeFromModell,
	selectQNameFromModell,
	selectQNamePathFromModell,
} from "../../../AppActor/actors/modellierungModel/selectors";
import { AssertionError } from "../../../../utils/error";
import type { LiteId } from "../../../../lib/validation/lite/IDSchemas";

type TriggerDetails = {
	icon: ReactNode;
	label: string;
};

export function getTrigger(
	severity: Severity | null,
	status: ValidationStatus,
): TriggerDetails {
	if (status === ValidationStatus.Running) {
		return {
			icon: <CircularProgress size="1em" />,
			label: "Die Validierung der Daten wird ausgeführt.",
		};
	}
	if (severity === null) {
		return {
			icon: validationSuccessIcon,
			label: "Es liegen keine Validierungsfehler vor.",
		};
	}
	return {
		icon: validationIcons[severity],
		label: validationSaveProjectNotes[severity],
	};
}

class ValidationResultGroupNotFoundError extends Error {
	constructor(result: ValidationResultBase<ValidationTargetFieldProfiling>) {
		const groupList = Object.values(ValidationResultGroup);
		const knownValidationGroups = groupList.join(", ");
		super(
			`Could not extract a target name for validation result group ` +
				`"${result.type}". Did you add a new validation result group and ` +
				`forgot to add a reference here?\n` +
				`Known validation result groups are "${knownValidationGroups}".`,
		);
	}
}

function getDatatypeQName(modell: LiteModellContainer, datatypeId: LiteId) {
	const datatypeNode = selectNodeFromModell(modell, datatypeId);
	AssertionError.notNullish(
		datatypeNode,
		`Datatype with id "${datatypeId}" was expected to be defined in \`getJumpTarget\`.`,
	);
	return selectQNameFromModell(modell, datatypeNode);
}

function getJumpTarget(
	modell: LiteModellContainer,
	result: ValidationResultBase<ValidationTargetFieldProfiling>,
): string {
	if (isProfileValidationResult(result)) {
		const qnames = selectQNamePathFromModell(modell, result.path);
		return `/profilierung/nachrichtenstrukturen/${encodeXPath(qnames)}`;
	}
	if (isRestrictionProfileValidationResult(result)) {
		const qnames = selectQNamePathFromModell(modell, result.path);
		const datatypeQName = getDatatypeQName(modell, result.datatypeId);
		return `/profilierung/datentypen/${encodeXPath(datatypeQName)}/profil/${
			result.restrictionId
		}/${encodeXPath(qnames)}`;
	}
	if (isRestrictionValidationResult(result)) {
		const datatypeQName = getDatatypeQName(modell, result.datatypeId);
		return `/profilierung/datentypen/${encodeXPath(datatypeQName)}/profil/${
			result.restrictionId
		}`;
	}
	if (isMetadataValidationResult(result)) {
		return `/profilierung/projekt`;
	}
	throw new ValidationResultGroupNotFoundError(result);
}

export interface ValidationTableEntry {
	id: string;
	severity: Severity;
	severityLabel: string;
	severityIcon: ReactNode;
	fieldLabel: string;
	elementName: string;
	message: string;
	elementLink: string;
	pathLabel: string;
}
export function createValidationTableData(
	modell: LiteModellContainer,
	results: ValidationResultBase<ValidationTargetFieldProfiling>[],
): ValidationTableEntry[] {
	return sortBySeverity(results).map((result) => ({
		id: result.id,
		severity: result.severity,
		severityLabel: validationSeverityNames[result.severity],
		severityIcon: validationIcons[result.severity],
		fieldLabel: targetFieldLabels[result.targetField],
		elementName: result.elementName,
		message: result.message,
		elementLink: getJumpTarget(modell, result),
		pathLabel: [
			...result.elementNamePath,
			targetFieldLabels[result.targetField],
		].join(" → "),
	}));
}
