import type { MouseEvent } from "react";
import type { SvgIconComponent } from "@mui/icons-material";

import { useModellierungProjekt } from "../../AppActor/actors/modellierungModel/hooks";
import {
	createSelectIsRecursive,
	selectNodeFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import type { LiteNode } from "../../../lib/validation/lite/LiteSchemas";
import { joinLitePath } from "../../../lib/validation/lite/helpers";
import {
	isLiteEigenschaft,
	isLiteNachricht,
} from "../../../lib/validation/lite/TypeGuards";
import type { LiteId } from "../../../lib/validation/lite/IDSchemas";
import {
	useActiveBausteinNodeId,
	useDefinedIn,
	useIsDefinedInExtension,
	useIsNodeReadOnly,
} from "../../AppActor/actors/treeState/hooks";
import RecursionIcon from "../../Icons/RecursionIcon";
import InteractiveNode from "../../InteractiveNode";
import type { NodeElementProps, NodeEventArg } from "../../Tree";
import Tree from "../../Tree";
import LiteNodeIcon from "../LiteNodeIcon";
import LiteNodeKindIcon from "../LiteNodeKindIcon";
import ContextMenu from "../ContextMenu";
import { pathEquals } from "../../Tree/treeHelpers";
import ChoiceIcon from "../../Icons/ChoiceIcon";
import {
	isChoice,
	isGroupTypeAll,
	kindTooltipMap,
} from "../../AppActor/actors/modellierungModel/helpers";
import { useIsProjektDisplayOnly } from "../../AppActor/actors/project/hooks";
import ValidationLabel from "../../ProfilierungValidation/ValidationLabel";
import { useValidationSeverity } from "../../ValidationDisplayLite/useValidationResults";
import "./ModelTreeNode.scss";
import AllIcon from "../../Icons/AllIcon";
import {
	// getTextConstantsByRuntime,
	placeholder,
	tooltips,
} from "../../../resources/textConstants";

import { kindIconMap } from "../../../resources/iconMaps/icons";
import KomplexerDatentypIcon from "../../../resources/icons/KomplexerDatentypIcon";
import AußerhalbDefiniertIcon from "../../../resources/icons/MuiMergeTypeIcon";
import { getExtendedLiteNodeKind } from "../../AppActor/actors/modellierungModel/LiteKind";
import UnknownBausteinIcon from "../../../resources/icons/MuiQuestionMarkIcon";

export default function ModelTreeNode({
	activePath,
	node,
	path,
	isExpanded,
	rootPath,
	getProps,
	NodeMenu,
	onActivate,
	isMarked,
}: {
	activePath: LiteId[];
	node: LiteNode;
	path: LiteId[];
	isExpanded: boolean;
	rootPath: LiteId[];
	getProps: () => NodeElementProps;
	NodeMenu: (props: { isOpen: boolean; closeMenu: () => void }) => JSX.Element;
	onActivate?: (event: NodeEventArg<LiteNode, LiteId>) => void;
	isMarked?: boolean;
}): JSX.Element {
	const fullPath = [...rootPath, ...path];
	const projekt = useModellierungProjekt();
	const isDisplayOnly = useIsProjektDisplayOnly();
	const selectIsRecursive = createSelectIsRecursive(projekt);
	const isActive = pathEquals(activePath, fullPath);
	const isReadOnlyByPath = useIsNodeReadOnly(fullPath);
	const isDefinedInExtension = useIsDefinedInExtension(fullPath);
	const definedIn = useDefinedIn(node.id);
	const isReadOnly = !!rootPath && isReadOnlyByPath;
	const severity = useValidationSeverity(fullPath);

	const createHandleContextMenu =
		(handleContextMenu: (e: MouseEvent) => void) => (e: MouseEvent) => {
			onActivate?.({ node, path });
			handleContextMenu(e);
		};

	const parentNodeId = useActiveBausteinNodeId();
	const parentNode =
		(projekt &&
			parentNodeId &&
			selectNodeFromModell(projekt.modell, parentNodeId)) ||
		node;

	const getDisplayName = () => {
		if (isLiteEigenschaft(node) && node.referenz) {
			return projekt
				? selectNodeFromModell(projekt.modell, node.referenz)?.name
				: "";
		}
		return node.name;
	};

	/*  */
	let refererNode: LiteNode | null = null;
	if (isLiteEigenschaft(node) && projekt?.modell) {
		if (node.basisDatentyp) {
			refererNode = selectNodeFromModell(projekt?.modell, node.basisDatentyp);
		}
		if (node.datentyp) {
			refererNode = selectNodeFromModell(projekt?.modell, node.datentyp);
		}
		if (node.referenz) {
			refererNode = selectNodeFromModell(projekt?.modell, node.referenz);
		}
	}
	const showKindIcon = refererNode || !node.name;

	function getLiteNodeIcon(): SvgIconComponent {
		if (!node.name && isLiteEigenschaft(node) && !node.referenz) {
			return KomplexerDatentypIcon;
		}
		if (refererNode) {
			return kindIconMap[getExtendedLiteNodeKind(refererNode)];
		}
		return UnknownBausteinIcon;
	}

	function getLiteNodeTooltip(): string {
		let refName: string = "Unbekannter Datentyp";
		if (refererNode && refererNode.name) {
			refName = refererNode.name;
		}
		if (isLiteEigenschaft(node) && !node.referenz && !node.name) {
			return tooltips.anonymousStructure.tooltip;
		}
		if (refererNode) {
			const kindTooltip = kindTooltipMap[
				getExtendedLiteNodeKind(refererNode)
			]?.replace("{{refName}}", refName);
			if (!kindTooltip) {
				/* Return just the refname as as a fallback */
				// eslint-disable-next-line no-console
				console.warn("missing icon tooltip", refererNode.name);
			}
			return kindTooltip || refName;
		}
		return "";
	}
	return (
		<ContextMenu disabled={isDisplayOnly}>
			{({ closeMenu, handleContextMenu, isOpen }) => (
				<>
					<InteractiveNode
						{...getProps()}
						data-testid="model-tree-node"
						data-node-id={node.id}
						data-node-name={node.name || placeholder.anonymousStructure}
						data-node-path={joinLitePath(fullPath)}
						data-is-expanded={isExpanded}
						data-is-marked={isMarked}
						isActive={isActive}
						isDisabled={isReadOnly}
						onContextMenu={createHandleContextMenu(handleContextMenu)}
					>
						<Tree.NodeToggleClick fullPath={fullPath} project={projekt} />
						<LiteNodeKindIcon node={node} />
						<ValidationLabel severity={severity}>
							<span className="model-tree-node__name">
								{isMarked ? (
									<mark>{getDisplayName()}</mark>
								) : (
									getDisplayName() || `<${placeholder.anonymousStructure}>`
								)}
							</span>
						</ValidationLabel>
						<RecursionIcon isRecursive={selectIsRecursive(node)} />
						{/* Group type icons */}
						{isChoice(node) && <ChoiceIcon />}
						{isGroupTypeAll(node) && <AllIcon />}

						{showKindIcon && (
							<LiteNodeIcon
								icon={getLiteNodeIcon()}
								title={getLiteNodeTooltip()}
							/>
						)}

						{isDefinedInExtension && (
							<LiteNodeIcon
								icon={AußerhalbDefiniertIcon}
								title={`Außerhalb ${
									isLiteNachricht(parentNode)
										? "der Nachricht"
										: "des Datentyps"
								} definiert in "${definedIn?.name}"`}
							/>
						)}
						{/* <CodeListIcon isCodeList={isCodeliste} /> */}
					</InteractiveNode>
					<ContextMenu.Menu>
						<NodeMenu closeMenu={closeMenu} isOpen={isOpen} />
					</ContextMenu.Menu>
				</>
			)}
		</ContextMenu>
	);
}
