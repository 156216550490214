import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from "@mui/material";
import { VisuallyHidden } from "@xoev/ui";
import { Link } from "react-router-dom";
import { useState } from "react";
import { placeholder } from "../../../resources/textConstants";
import { useValidationStatus } from "../../AppActor/actors/modellierungModel/validation/hooks";
import { useActiveProjectId } from "../../AppActor/actors/navigation/hooks";
import { getTrigger } from "../../EditorNavBar/EditorNavBarActions/ValidationDialog/helpers";
import { ValidationStatus } from "../../ProfilierungValidation/types";
import useValidationResults, {
	getMaxSeverity,
} from "../../ValidationDisplayLite/useValidationResults";
import {
	sortBySeverity,
	validationIcons,
	validationSeverityNames,
} from "../../ProfilierungValidation/helpers";
import {
	konfigurationValidationTarget,
	metadatenVersionValidationTarget,
	type ResultType,
	type ValidationTargetField,
} from "../../AppActor/actors/modellierungModel/validation/validators/types";
import { targetFieldLabelsLite } from "../../AppActor/actors/modellierungModel/validation/helpers";
import {
	createSelectNodesByPathFromModell,
	selectNodeFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import { useModellierungProjekt } from "../../AppActor/actors/modellierungModel/hooks";
import type { StandardProjekt } from "../../AppActor/actors/project/types";
import { joinLitePath } from "../../../lib/validation/lite/helpers";
import styles from "./validationDialog.module.scss";

function getRootLink(targetField: ValidationTargetField) {
	if (metadatenVersionValidationTarget.includes(targetField)) {
		return "/metadaten/version";
	}
	if (konfigurationValidationTarget.includes(targetField)) {
		return "/konfiguration";
	}
	return "/metadaten/standard";
}

function getJumpTarget(projekt: StandardProjekt, result: ResultType) {
	if (result.id === projekt.modell.rootModelId) {
		const rootLink = getRootLink(result.targetField);
		return `/modellierung/${projekt.id}${rootLink}`;
	}
	const path = createSelectNodesByPathFromModell(projekt.modell)(
		result.idPath.slice(1),
	).map((node) => node.id);
	return `/modellierung/${projekt.id}/modell/${path.join("/")}`;
}

export function createValidationTableData(
	projekt: StandardProjekt,
	results: ResultType[],
) {
	return sortBySeverity(results)
		.filter(
			(result) =>
				!!selectNodeFromModell(projekt.modell, result.idPath.slice(-1)[0]),
		)
		.map((result) => ({
			id: result.id,
			key: `${joinLitePath(result.idPath)}#${result.ruleId}#${
				result.targetField
			}`,
			severity: result.severity,
			severityLabel: validationSeverityNames[result.severity],
			severityIcon: validationIcons[result.severity],
			fieldLabel: targetFieldLabelsLite[result.targetField],
			message: result.message,
			elementLink: getJumpTarget(projekt, result),
			pathLabel: [
				...createSelectNodesByPathFromModell(projekt.modell)(
					result.idPath.slice(1),
				).map((node) => node.name || placeholder.anonymousStructure),
				targetFieldLabelsLite[result.targetField],
			].join(" → "),
		}));
}

export default function ValidationDialog(): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);
	const projekt = useModellierungProjekt();
	const results = useValidationResults();
	const maxSeverity = getMaxSeverity(results);
	const projektId = useActiveProjectId();
	const validationStatus = useValidationStatus(projektId);
	const shouldShowDialog =
		results.length > 0 && validationStatus !== "Validating";

	const handleOpen = () => {
		if (shouldShowDialog) {
			setIsOpen(true);
		}
	};
	const handleClose = () => setIsOpen(false);

	const { icon, label } = getTrigger(
		maxSeverity || null,
		validationStatus === "Validating"
			? ValidationStatus.Running
			: ValidationStatus.Completed,
	);
	const validationIcon = (
		<Tooltip title={label}>
			<IconButton
				sx={{ cursor: shouldShowDialog ? "pointer" : "auto" }}
				aria-label={label}
				onClick={handleOpen}
			>
				{icon}
			</IconButton>
		</Tooltip>
	);

	return (
		<div>
			{validationIcon}
			<Dialog
				open={isOpen}
				onClose={handleClose}
				className={styles["xoev-validation-dialog"]}
				maxWidth="lg"
			>
				<div data-testid="validation-dialog">
					<DialogTitle>Validierungsergebnisse</DialogTitle>
					<DialogContent>
						<Stack spacing={2}>
							<DialogContentText>{label}</DialogContentText>
							{results.length > 0 && projekt && (
								<TableContainer sx={{ maxHeight: "55vh" }}>
									<Table stickyHeader aria-label="Validierungsergebnisse">
										<TableHead>
											<TableRow>
												<TableCell>Typ</TableCell>
												<TableCell>Feld</TableCell>
												<TableCell>Validierungsergebnis</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{createValidationTableData(projekt, results).map(
												(row) => (
													<TableRow key={row.key}>
														<TableCell>
															<VisuallyHidden>
																{row.severityLabel}
															</VisuallyHidden>
															<Tooltip title={row.severityLabel} aria-hidden>
																<span>{row.severityIcon}</span>
															</Tooltip>
														</TableCell>
														<TableCell>
															<Tooltip title={`Springe zu: ${row.pathLabel}`}>
																<Link
																	to={row.elementLink}
																	onClick={handleClose}
																>
																	{row.fieldLabel}
																</Link>
															</Tooltip>
														</TableCell>
														<TableCell>{row.message}</TableCell>
													</TableRow>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={handleClose}
							data-testid="close-validation-dialog"
						>
							Schließen
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		</div>
	);
}
