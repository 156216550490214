import { placeholder } from "../../../resources/textConstants";
import { AssertionError } from "../../../utils/error";
import type { LiteId } from "./IDSchemas";
import { LiteIdSchema } from "./IDSchemas";
import { LiteNodeType } from "./LiteEnums";
import { LitePathSchema, QNamePathSchema, QNameSchema } from "./LiteSchemas";
import type {
	LitePath,
	QName,
	QNamePath,
	LiteNode,
	LiteModel,
	LitePaket,
	LiteBaustein,
	LiteEigenschaft,
} from "./LiteSchemas";
import {
	isLiteModel,
	isLitePaket,
	isLiteBaustein,
	isLiteEigenschaft,
} from "./TypeGuards";

export class NodeTypeAssertionError extends AssertionError {
	constructor(node: LiteNode, type: LiteNodeType) {
		super(
			`Expected node "${node.name || placeholder.anonymousStructure}" id: "${
				node.id
			}" to be of type ` +
				`"${type}", but found "${node.liteType}":\n` +
				`${JSON.stringify(node, null, 2)}`,
		);
	}
}
export function assertLiteModel(node: LiteNode): asserts node is LiteModel {
	if (!isLiteModel(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Model);
	}
}
export function assertLitePaket(node: LiteNode): asserts node is LitePaket {
	if (!isLitePaket(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Paket);
	}
}
export function assertLiteBaustein(
	node: LiteNode,
): asserts node is LiteBaustein {
	if (!isLiteBaustein(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Baustein);
	}
}
export function assertLiteEigenschaft(
	node: LiteNode,
): asserts node is LiteEigenschaft {
	if (!isLiteEigenschaft(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Eigenschaft);
	}
}
export function parseLitePath(path: LitePath): LiteId[] {
	return path.split("/").map((id) => LiteIdSchema.parse(id));
}

export function joinLitePath(path: LiteId[]): LitePath {
	return LitePathSchema.parse(path.join("/"));
}

export function parseQNamePath(path: QNamePath): QName[] {
	return path.split("/").map((id) => QNameSchema.parse(id));
}

export function joinQNamePath(path: QName[]): QNamePath {
	return QNamePathSchema.parse(path.join("/"));
}
