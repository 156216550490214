import { v4 as uuid } from "uuid";
import type { ActorRefFrom } from "xstate";
import { assertEvent, setup } from "xstate";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../EventStore/helpers";
import {
	getNavigationQueryFromSystem,
	selectActiveProjektId,
} from "../navigation/hooks";
import type { ProjektId } from "../../../../lib/validation/lite/IDSchemas";
import { ProjektIdSchema } from "../../../../lib/validation/lite/IDSchemas";
import type { ProjektMeta } from "../project/types";
import { getProjectsQueryFromSystem, selectProjektActorRefs } from "./hooks";

export type ProjectsCommandActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof projectsCommandMachine
>;
export type ProjectsCommandEvent =
	| { type: "CLOSE_PROJECT"; projektId: ProjektId }
	| { type: "CLOSE_CURRENT_PROJECT" }
	| {
			type: "OPEN_PROJECT";
			projektMeta: ProjektMeta;
			initialLocation?: string;
	  };

const projectsCommandMachine = setup({
	types: {
		events: {} as ProjectsCommandEvent,
	},
	actors: {
		translateEvents: translateStoreEvents<ProjectsCommandEvent>({
			"SHORTCUT.CLOSE_PROJECT": () => ({ type: "CLOSE_CURRENT_PROJECT" }),
			"PROJECT.CLOSE": ({ payload }) => ({
				type: "CLOSE_PROJECT",
				projektId: payload.projektId,
			}),
			"PROJECT.OPEN_EXISTING": ({ payload }) => ({
				type: "OPEN_PROJECT",
				projektMeta: {
					...payload.projektMeta,
					projektId: ProjektIdSchema.parse(uuid()),
				},
				initialLocation: payload.initialLocation,
			}),
		}),
	},
	actions: {
		goToHomePage: ({ system, event }) => {
			assertEvent(event, "CLOSE_PROJECT");
			const navigationQuery = getNavigationQueryFromSystem(system);
			const isTabActive =
				selectActiveProjektId(navigationQuery.getSnapshot()) ===
				event.projektId;
			if (isTabActive) {
				sendToEventStore(system, { type: "GOTO.HOME" });
			}
		},
		openProject: ({ system, event }) => {
			assertEvent(event, "OPEN_PROJECT");
			const { projektMeta, initialLocation } = event;
			const projektList = selectProjektActorRefs(
				getProjectsQueryFromSystem(system).getSnapshot().context.projects,
			).map((ref) => ref.getSnapshot().context);
			const openTab = projektList.find(
				(projekt) => projekt.dbId === projektMeta.dbId,
			);
			if (openTab) {
				sendToEventStore(system, {
					type: "GOTO.TAB",
					payload: {
						kennung: openTab.kennung,
						projektId: openTab.projektId,
						projektType: openTab.projektType,
					},
				});
			} else {
				sendToEventStore(system, {
					type: "PROJECT.SPAWN",
					payload: { projektMeta },
				});
				sendToEventStore(system, {
					type: "PROJECT.ACTIVATE",
					payload: { projektMeta, initialLocation },
				});
			}
		},
		closeCurrentProject: ({ event, system }) => {
			assertEvent(event, "CLOSE_CURRENT_PROJECT");
			const currentProjektId = selectActiveProjektId(
				getNavigationQueryFromSystem(system).getSnapshot(),
			);
			if (!currentProjektId) return;
			sendToEventStore(system, {
				type: "PROJECT.CLOSE",
				payload: { projektId: currentProjektId },
			});
		},
	},
}).createMachine({
	id: "projects:command",
	invoke: { src: "translateEvents" },
	on: {
		CLOSE_PROJECT: { actions: "goToHomePage" },
		CLOSE_CURRENT_PROJECT: { actions: "closeCurrentProject" },
		OPEN_PROJECT: { actions: "openProject" },
	},
});

export default projectsCommandMachine;
