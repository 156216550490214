import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import { toMutable } from "../../utils/types";
import { InvalidRefTypeError } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/refIds";
import type { SpecificRefSelectionItem } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import { RefType } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import { isDeepEmpty } from "./helpers";
import {
	selectPropertyReferences,
	selectDatatypeIdByRestriction,
	selectDatatypeEntryWithRestriction,
	selectDatatypeRestrictionReferences,
	selectProfile,
	selectRestrictionEntries,
	selectRestrictionProfile,
	selectRestrictions,
} from "./selectors";
import {
	subSelectCodelist,
	subSelectDatatype,
	subSelectRestrictionProfileSeq,
	subSelectUsedProperties,
} from "./subSelectors";
import type {
	PropertyValues,
	DatatypeMap,
	DatatypeProfileValues,
	EditorMeta,
	EditorReducerMap,
	EditorState,
	MessageProfileValues,
	ProfileRef,
	StateProfileMap,
	RestrictionId,
} from "./types";
import type { QName, QNamePath } from "../../lib/validation/lite/LiteSchemas";
import { AssertionError } from "../../utils/error";

export function getProfile(
	state: EditorState,
	selectionItem: SpecificRefSelectionItem,
) {
	const { qnamePath: syntaxPath, type } = selectionItem;
	if (type === RefType.MessageElement) {
		return state.getIn(["profile", syntaxPath]) || createImmutableMap();
	}
	if (type === RefType.Datatype) {
		const { restrictionId } = selectionItem;
		const restrictionDatatypeId =
			selectDatatypeIdByRestriction(restrictionId)(state);
		return (
			(restrictionDatatypeId &&
				state
					.get("datentypen")
					.get(restrictionDatatypeId)
					?.get("restrictions")
					?.get(restrictionId)
					?.get("profile")
					?.get(syntaxPath)) ||
			createImmutableMap()
		);
	}
	throw new InvalidRefTypeError(type);
}

export function setDatatypeInProfile(
	profile: ImmutableMap<Partial<MessageProfileValues>>,
	datatypeId: QName,
	restrictionName: string,
): ImmutableMap<Partial<MessageProfileValues>> {
	let nextProfile = profile
		.setIn(["datentyp", "datentyp"], datatypeId)
		.setIn(["datentyp", "name"], restrictionName);
	const datatypeRef = subSelectDatatype(nextProfile);
	const codelist = subSelectCodelist(nextProfile);
	if (datatypeRef && codelist) {
		nextProfile = nextProfile.deleteIn(["konfiguration", "codeliste"]);
		if (nextProfile.get("konfiguration")?.isEmpty()) {
			nextProfile = nextProfile.delete("konfiguration");
		}
	}
	return nextProfile;
}

export function setDatatype(
	state: EditorState,
	profile: ImmutableMap<Partial<MessageProfileValues>>,
	selectionItem: SpecificRefSelectionItem,
	parentRestrictionId: RestrictionId,
): EditorState {
	const refEntry =
		selectDatatypeEntryWithRestriction(parentRestrictionId)(state);
	if (!refEntry) {
		throw new Error(
			`Restriction Id "${parentRestrictionId}" could not be found`,
		);
	}
	const { datatypeId, restrictionName } = refEntry;
	const { qnamePath: syntaxPath, type } = selectionItem;
	const nextProfile = setDatatypeInProfile(
		profile,
		datatypeId,
		restrictionName,
	);
	if (type === RefType.MessageElement) {
		return state.setIn(["profile", syntaxPath], nextProfile);
	}
	if (type === RefType.Datatype) {
		const { restrictionId } = selectionItem;
		const restrictionDatatypeId =
			selectDatatypeIdByRestriction(restrictionId)(state);
		return state.setIn(
			[
				"datentypen",
				AssertionError.asNotNullish(restrictionDatatypeId),
				"restrictions",
				restrictionId,
				"profile",
				syntaxPath,
			],
			nextProfile,
		);
	}
	throw new InvalidRefTypeError(type);
}

export function deleteProfile(
	state: EditorState,
	selectionItem: SpecificRefSelectionItem,
) {
	const { qnamePath: syntaxPath, type } = selectionItem;
	if (type === RefType.MessageElement) {
		return state.deleteIn(["profile", syntaxPath]);
	}
	if (type === RefType.Datatype) {
		const { restrictionId } = selectionItem;
		const restrictionDatatypeId =
			selectDatatypeIdByRestriction(restrictionId)(state);
		return state.deleteIn([
			"datentypen",
			AssertionError.asNotNullish(restrictionDatatypeId),
			"restrictions",
			restrictionId,
			"profile",
			syntaxPath,
		]);
	}
	throw new InvalidRefTypeError(type);
}

export function cleanPropertyReferencesInProfile(
	nameTechnisch: string,
	profile: ImmutableMap<Partial<MessageProfileValues>> | null | undefined,
) {
	const profileProps = subSelectUsedProperties(profile);
	const nextProfileProps = profileProps?.delete(nameTechnisch);
	let nextProfile = profile || createImmutableMap();
	if (nextProfileProps && nextProfileProps.size > 0) {
		nextProfile = nextProfile.set("eigenschaften", nextProfileProps);
	} else {
		nextProfile = nextProfile.delete("eigenschaften");
	}
	return nextProfile;
}

export function editPropertyInProfile(
	nameTechnisch: string,
	values: PropertyValues,
	profile: ImmutableMap<Partial<MessageProfileValues>> | null | undefined,
) {
	const nextProfile = profile || createImmutableMap();
	const usedProps = subSelectUsedProperties(profile);
	const nextPropProfile = usedProps
		?.get(nameTechnisch)
		?.set("name", values.nameTechnisch);
	if (!nextPropProfile) return nextProfile;
	const nextUsedProps = usedProps
		?.delete(nameTechnisch)
		?.set(values.nameTechnisch, nextPropProfile);
	if (!nextUsedProps) return nextProfile;
	return nextProfile.set("eigenschaften", nextUsedProps);
}

export function adjustPropertyRefs({
	state,
	reducerMap,
	patchMeta,
	nameTechnisch,
	adjustProfile,
}: {
	state: EditorState;
	// We need to pass in the reducer map as a parameter instead of importing it
	// to avoid circular dependencies
	reducerMap: EditorReducerMap;
	patchMeta: EditorMeta;
	nameTechnisch: string;
	adjustProfile: (
		profile: ImmutableMap<Partial<MessageProfileValues>> | null | undefined,
	) => ImmutableMap<Partial<MessageProfileValues>>;
}) {
	const refs = selectPropertyReferences(nameTechnisch)(state);
	let nextState = state;

	for (const ref of refs) {
		const { type } = ref;
		if (type === RefType.MessageElement) {
			const profile = selectProfile(ref.profileId)(state);
			const nextProfile = adjustProfile(profile);
			nextState = reducerMap.setProfile(nextState, {
				type: "setProfile",
				meta: patchMeta,
				payload: { id: ref.profileId, profile: nextProfile },
			});
		}
		if (type === RefType.Datatype) {
			const { datatypeId, restrictionId, profileId } = ref;
			const profile = selectRestrictionProfile(
				datatypeId,
				restrictionId,
				profileId,
			)(state);
			const nextProfile = adjustProfile(profile);
			nextState = reducerMap.setRestrictionProfile(nextState, {
				type: "setRestrictionProfile",
				meta: patchMeta,
				payload: {
					datatypeId,
					restrictionId,
					restrictionProfileId: profileId,
					profile: nextProfile,
				},
			});
		}
	}

	return nextState;
}

export function fixupProfileMap(profileMap: StateProfileMap): StateProfileMap {
	let nextProfileMap = profileMap;
	for (const [id, profile] of profileMap.entrySeq()) {
		if (isDeepEmpty(profile)) {
			nextProfileMap = nextProfileMap.delete(id);
		}
	}
	return nextProfileMap;
}

export function fixupDatatype(
	datatype: ImmutableMap<Partial<DatatypeProfileValues>>,
): ImmutableMap<Partial<DatatypeProfileValues>> {
	// Create an iterator over all restriction profiles, so we can check for
	// empty profiles and delete them later
	const restrictionProfileIter = subSelectRestrictionProfileSeq(datatype);
	// Loop through all restriction profiles, and delete the empty ones
	let nextDatatype = datatype;
	for (const { restrictionId } of restrictionProfileIter) {
		const path = toMutable(["restrictions", restrictionId, "profile"] as const);
		const profileMap = nextDatatype.getIn(path);
		if (profileMap) {
			const fixedProfileMap = fixupProfileMap(profileMap);
			// If the profileMap is empty after the fixup of its contents, we can
			// remove the entire profile map
			if (fixedProfileMap.isEmpty()) {
				nextDatatype = nextDatatype.deleteIn(path);
			} else {
				nextDatatype = nextDatatype.setIn(path, fixedProfileMap);
			}
		}
	}
	return nextDatatype;
}

export function fixupDatatypeMap(datatypeMap: DatatypeMap): DatatypeMap {
	let nextDtMap = datatypeMap;
	for (const [id, datatype] of datatypeMap.entrySeq()) {
		const nextDatatype = fixupDatatype(datatype);
		const restrictions = nextDatatype.get("restrictions");
		// If the datatype has no restrictions, it cannot carry any other data, so
		// we delete the complete datatype
		if (restrictions && restrictions.isEmpty()) {
			nextDtMap = nextDtMap.delete(id);
		} else {
			nextDtMap = nextDtMap.set(id, nextDatatype);
		}
	}
	return nextDtMap;
}

export function getDatatypeRefPath(ref: ProfileRef) {
	if (ref.type === RefType.MessageElement) {
		const { profileId } = ref;
		return toMutable(["profile", profileId, "datentyp"] as const);
	}
	if (ref.type === RefType.Datatype) {
		const { datatypeId, profileId, restrictionId: refRestrictionId } = ref;
		return toMutable([
			"datentypen",
			datatypeId,
			"restrictions",
			refRestrictionId,
			"profile",
			profileId,
			"datentyp",
		] as const);
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	throw new InvalidRefTypeError((ref as any).type);
}

export function fixupDatatypeRestrictionReferences(
	prevState: EditorState,
	currentState: EditorState,
	datatypeId: QName,
) {
	let nextState = currentState;
	const prevRestrictionSeq = selectRestrictionEntries(datatypeId)(prevState);
	const currentRestrictions = selectRestrictions(datatypeId)(nextState);
	if (prevRestrictionSeq.count() === 0) return nextState;
	// Iterate through all previous restrictions and diff them against the current
	// restrictions in the state
	for (const [prevRestrictionId, prevRestriction] of prevRestrictionSeq) {
		const prevRestrictionName = prevRestriction.get("name");
		const currentRestriction = currentRestrictions?.get(prevRestrictionId);
		const currentRestrictionName = currentRestriction?.get("name");
		const refs =
			selectDatatypeRestrictionReferences(prevRestrictionId)(prevState);
		for (const ref of refs) {
			const path = getDatatypeRefPath(ref);
			if (!currentRestriction) {
				// The restriction was removed, delete all references to it
				nextState = nextState.deleteIn(path);
				// And delete the restriction itself
				nextState = nextState.deleteIn([
					"datentypen",
					datatypeId,
					"restrictions",
					prevRestrictionId,
				]);
			} else if (currentRestrictionName !== prevRestrictionName) {
				// The restriciton was renamed. Adjust name in all referencing profiles
				const namePath = (path as string[]).concat(["name"]) as [
					// Pretend as though we only care about message profile paths heres
					...Extract<typeof path, [string, QNamePath, string]>,
					"name",
				];
				nextState = nextState.setIn(namePath, currentRestrictionName as string);
			}
		}
	}
	return nextState;
}

export function diffSelection(prev: string[], next: string[]) {
	const prevSet = new Set(prev);
	const nextSet = new Set(next);
	const added: string[] = [];
	const deleted: string[] = [];
	for (const item of prevSet) {
		if (!nextSet.has(item)) {
			deleted.push(item);
		}
	}
	for (const item of nextSet) {
		if (!prevSet.has(item)) {
			added.push(item);
		}
	}
	return { added, deleted };
}

export function deleteChildProfiles(
	profileMap: StateProfileMap,
	parentId: QNamePath,
) {
	const prefix = `${parentId}/`;
	const profileIds = profileMap.keySeq();
	let currentMap = profileMap;
	profileIds.forEach((profileId) => {
		if (profileId.startsWith(prefix)) {
			currentMap = currentMap.delete(profileId);
		}
	});
	return currentMap;
}
