import { extractNodeProps, MarkupPropertyNames } from "../../../helpers";
import type { RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import UnknownMarkup from "../UnknownMarkup";
import notifications from "../../../../../../resources/textConstants/notifications.json";
import styles from "./UnknownRenderer.module.scss";

const UnknownRenderer: RendererImplementationComponent<
	RendererImplementation.Unknown
> = ({ markup }) => {
	const props = extractNodeProps(markup);
	const rendererName = props[MarkupPropertyNames.Renderer];

	if (props === undefined) return <UnknownMarkup markup={markup} />;

	return (
		<div
			className={styles["xoev-renderer-unknown"]}
			data-testid="unknown-renderer"
		>
			{`${notifications.messages.unknown.renderer} "${rendererName}"`}.<br />
			Markup: {JSON.stringify(markup)}
		</div>
	);
};

export default UnknownRenderer;
