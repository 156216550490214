import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import { FixedSizeCache } from "@xoev/memo";
import type { ProjektMeta, StandardProjekt } from "../project/types";
import type { ModellierungPatch } from "./types";
import type { EventTranslationMap } from "../../EventStore/helpers";
import { translateStoreEvents } from "../../EventStore/helpers";
import modellierungPatchMap from "./patchMap";
import type { ProjektId } from "../../../../lib/validation/lite/IDSchemas";
import { ProjektType } from "../../../../lib/validation/lite/LiteEnums";

export type ModellierungStackEntry = {
	sequenceNumber: number;
	patch: ModellierungPatch;
	eventLogIndex: number;
};

export type ModellierungModellQueryActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof modellierungModellQueryMachine
>;
export type ModellierungModellQueryInput = ProjektMeta & {
	projekt: StandardProjekt;
};
export type ModellierungModellQueryContext = ModellierungModellQueryInput & {
	initialProjekt: StandardProjekt;
	projekt: StandardProjekt;
	stack: ModellierungStackEntry[];
	cursor: number;
	sequenceNumber: number;
	cache: FixedSizeCache<[string], StandardProjekt>;
};
export type ModellierungModellQueryEvent =
	| {
			type: "APPLY";
			projektId: ProjektId;
			patch: ModellierungPatch;
			eventLogIndex: number;
	  }
	| { type: "UNDO"; projektId: ProjektId }
	| { type: "REDO"; projektId: ProjektId };

// Turn the sequence numbers into a string, so the comparison in the cache
// does not fail because some references have changed
function getCacheKey(stack: ModellierungStackEntry[]): [string] {
	const key = stack.map((entry) => entry.sequenceNumber).join("/");
	return [key];
}

function applyPatch(projekt: StandardProjekt, patch: ModellierungPatch) {
	const reducer = modellierungPatchMap[patch.type];
	if (!reducer) {
		throw new Error(
			`No handler for patch of type "${patch.type}" can be found in patch map.`,
		);
	}
	return reducer(projekt, patch as never);
}

export const translateMap: EventTranslationMap<ModellierungModellQueryEvent> = {
	"MODELLIERUNG.MODELL.APPLY": ({ payload }, meta) => ({
		type: "APPLY",
		eventLogIndex: meta.index,
		...payload,
	}),
	"MODELLIERUNG.MODELL.UNDO": ({ payload }) => ({
		type: "UNDO",
		...payload,
	}),
	"MODELLIERUNG.MODELL.REDO": ({ payload }) => ({
		type: "REDO",
		...payload,
	}),
};

const modellierungModellQueryMachine = setup({
	types: {
		events: {} as ModellierungModellQueryEvent,
		context: {} as ModellierungModellQueryContext,
		input: {} as ModellierungModellQueryInput,
	},
	actors: {
		translateEvents: translateStoreEvents<ModellierungModellQueryEvent>(
			translateMap,
			{ replayEventLog: true },
		),
	},
	actions: {
		apply: assign(({ context, event }) => {
			assertEvent(event, "APPLY");
			const { cache, cursor, stack, sequenceNumber, projekt } = context;
			const { patch, eventLogIndex } = event;
			const nextStack = [
				...stack.slice(0, cursor),
				{ sequenceNumber, patch, eventLogIndex },
			];
			const nextProjekt = applyPatch(projekt, event.patch);
			// Store the projekt in the cache, so we can skip the calculation in
			// undo/redo, should it still be cached
			const cacheKey = getCacheKey(nextStack);
			cache.set(cacheKey, nextProjekt);

			return {
				stack: nextStack,
				projekt: nextProjekt,
				cursor: cursor + 1,
				sequenceNumber: sequenceNumber + 1,
			};
		}),
		undo: assign({
			cursor: ({ context, event }) => {
				assertEvent(event, "UNDO");
				return Math.max(context.cursor - 1, 0);
			},
		}),
		redo: assign({
			cursor: ({ context, event }) => {
				assertEvent(event, "REDO");
				return Math.min(context.cursor + 1, context.stack.length);
			},
		}),
		replayStack: assign({
			projekt: ({ context }) => {
				const { cache, initialProjekt, cursor, stack } = context;
				const currentStack = stack.slice(0, cursor);
				// Check the cache for the value and only re-calculate it if the change
				// was made too long ago
				const cacheKey = getCacheKey(currentStack);
				return cache.getOrInsert(cacheKey, () => {
					let projekt = initialProjekt;
					for (const entry of currentStack) {
						projekt = applyPatch(projekt, entry.patch);
					}
					return projekt;
				});
			},
		}),
	},
	guards: {
		isSelf: ({ context, event }) => {
			return context.projektId === event.projektId;
		},
		isReadonly: ({ context }) => {
			return context.projektType !== ProjektType.Modellierung;
		},
	},
}).createMachine({
	id: "modellierungModell:query",
	context: ({ input }) => ({
		...input,
		initialProjekt: input.projekt,
		stack: [],
		cursor: 0,
		sequenceNumber: 0,
		cache: new FixedSizeCache({
			cacheSize: 16,
			// We only use strings as cache keys, so we can ignore garbage collection
			// since strings are primitive values and won't be gc'd
			preventGarbageCollection: true,
		}),
	}),
	initial: "CheckReadonlyState",
	states: {
		CheckReadonlyState: {
			always: [
				{ guard: "isReadonly", target: "Readonly" },
				{ target: "Editable" },
			],
		},
		Readonly: {},
		Editable: {
			invoke: { src: "translateEvents" },
			on: {
				APPLY: { guard: "isSelf", actions: "apply" },
				UNDO: { guard: "isSelf", actions: ["undo", "replayStack"] },
				REDO: { guard: "isSelf", actions: ["redo", "replayStack"] },
			},
		},
	},
});

export default modellierungModellQueryMachine;
