import classNames from "classnames";
import {
	extractChildNodeEntries,
	extractNodeProps,
	MarkupPropertyNames,
} from "../../../helpers";
import type { Markup, RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import styles from "./TextRenderer.module.scss";
import UnknownMarkup from "../UnknownMarkup";

const createClasses = (props: Record<string, string>) => {
	return classNames(styles["xoev-renderer-text"], {
		[styles["xoev-renderer-text--bold"]]: Boolean(
			props[MarkupPropertyNames.Bold],
		),
		[styles["xoev-renderer-text--italic"]]: Boolean(
			props[MarkupPropertyNames.Italic],
		),
	});
};

const getEntries = (markup: Markup<RendererImplementation.Text>) => {
	const [entries] = extractChildNodeEntries(markup);

	return entries;
};

const TextRenderer: RendererImplementationComponent<
	RendererImplementation.Text
> = ({ markup }) => {
	const props = extractNodeProps(markup);
	const cssClasses = createClasses(props);

	const entries = getEntries(markup);
	if (entries === undefined)
		return <UnknownMarkup markup={markup} className={cssClasses} />;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [unusedVar, content] = entries;

	return (
		<span
			className={cssClasses}
			data-testid="text-renderer"
			data-text-renderer-bold={props[MarkupPropertyNames.Bold]}
			data-text-renderer-italic={props[MarkupPropertyNames.Italic]}
		>
			{content}
		</span>
	);
};

export default TextRenderer;
