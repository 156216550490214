import { ValidationTargetField } from "./validators/types";

// eslint-disable-next-line import/prefer-default-export
export const targetFieldLabelsLite: { [K in ValidationTargetField]: string } = {
	[ValidationTargetField.AenderungZurVorversion]: "Änderung zur Vorversion",
	[ValidationTargetField.BenannterCodelistenDatentyp]:
		"Benannter Codelisten Datentyp",
	[ValidationTargetField.Beschreibung]: "Beschreibung",
	[ValidationTargetField.Bezugsort]: "Bezugsort",
	[ValidationTargetField.CodesSchemavalidiert]: "Codes Schema validiert",
	[ValidationTargetField.ElementFormDefault]: "Element Form Default",
	[ValidationTargetField.ExterneWebsite]: "Externe Website",
	[ValidationTargetField.FassungVom]: "Fassung vom",
	[ValidationTargetField.GeschaeftsregelKennung]: "Geschäftsregel Kennung",
	[ValidationTargetField.HerausgebernameKurz]: "Herausgebername (kurz)",
	[ValidationTargetField.HerausgebernameLang]: "Herausgebername (lang)",
	[ValidationTargetField.Kennung]: "Kennung",
	[ValidationTargetField.Lizenz]: "Lizenz",
	[ValidationTargetField.NameKurz]: "Name (kurz)",
	[ValidationTargetField.NameLang]: "Name (lang)",
	[ValidationTargetField.Namespace]: "Namensraum",
	[ValidationTargetField.NameTechnisch]: "Name (technisch)",
	[ValidationTargetField.NodeBeschreibung]: "Beschreibung",
	[ValidationTargetField.NodeName]: "Name",
	[ValidationTargetField.NodeUmsetzungshinweis]: "Umsetzungshinweis",
	[ValidationTargetField.NutzungNameElement]: "Nutzung Name Element",
	[ValidationTargetField.Prefix]: "Präfix",
	[ValidationTargetField.SchemaDateiname]: "Dateiname",
	[ValidationTargetField.SchemaLocationBase]: "Schema Location Base",
	[ValidationTargetField.SchemaPrefix]: "Präfix",
	[ValidationTargetField.SchemaUri]: "Namensraum",
	[ValidationTargetField.StandardeinstellungEigenschaften]:
		"Standardeinstellung Eigenschaften",
	[ValidationTargetField.TypDesCodeElements]: "Typ Des Code Elements",
	[ValidationTargetField.Version]: "Version",
	[ValidationTargetField.VersionBeschreibung]: "Beschreibung",
	[ValidationTargetField.VersionModellierungswerkzeug]:
		"Version Modellierungswerkzeug",
	[ValidationTargetField.VersionXGenerator]: "Version XGenerator",
	[ValidationTargetField.VersionXoevHandbuch]: "Version XÖV Handbuch",
	[ValidationTargetField.WsdlDateipraefix]: "Wsdl Dateipräfix",
	[ValidationTargetField.WsdlStandardaufbauNamensraum]:
		"Wsdl Standardaufbau Namensraum",
	[ValidationTargetField.WsdlStandardaufbauSubjectOperation]:
		"Wsdl Standardaufbau Subject Operation",
	[ValidationTargetField.XsdGlobalElementNamePrefix]:
		"Xsd Global Element Name Präfix",
	[ValidationTargetField.XsdGlobalElementNameSuffix]:
		"Xsd Global Element Name Suffix",
	[ValidationTargetField.XsdNamedTypeNamePrefix]: "XsdNamedType Name-Prefix",
	[ValidationTargetField.XSDNamedTypeNameSuffix]: "XSDNamedType Name-Suffix",
};
