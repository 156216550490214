/* eslint-disable react/no-array-index-key */
import { isMarkupNodeType, walkMarkupTreeDepthFirst } from "../../../helpers";
import {
	MarkupNodeType,
	type Markup,
	type RendererImplementation,
} from "../../../types";
import type {
	MatrixObjectType,
	RendererImplementationComponent,
	TableCellType,
} from "../../types";
import "./ValueGroupRenderer.scss";

/**
 * Generates a matrix from the received markup in the following format.
 * The span cells are generated to inidicate indentations in the table
 * that will be based on the matrix. The function als returns the maximum
 * depth of the markup tree, which is needed for calculating colspans
 * (see text4 in the example below).
 *
 * | child  | sub1  |  text1 |
 * | span   | sub2  |  text2 |
 * | span   | sub3  |  text3 |
 * --------------------------
 * | child2 | text4          |
 *
 * @param markup The ValueGroup markup tree
 * @returns An object containing the matrix and the maximum depth of
 * the markup tree
 */
// Reduce cognitive complexity, when the function needs to be modified next
// eslint-disable-next-line sonarjs/cognitive-complexity
const getMatrix = (markup: Markup): MatrixObjectType => {
	const matrix: string[][] = [];
	let prevDepth = 1;
	let maxDepth = 0;
	let currentRow: string[] = [];
	for (const [key, node, depth] of walkMarkupTreeDepthFirst(markup)) {
		if (depth > 0) {
			if (depth > prevDepth) {
				if (isMarkupNodeType(node, MarkupNodeType.String)) {
					currentRow.push(key, node);
					matrix.push(currentRow);
					currentRow = [];
				} else {
					currentRow.push(key);
				}
			} else {
				currentRow = Array(depth - 1).fill("span");
				if (isMarkupNodeType(node, MarkupNodeType.String)) {
					currentRow.push(key, node);
					matrix.push(currentRow);
					currentRow = [];
				} else {
					currentRow.push(key);
				}
			}
			prevDepth = depth;
			if (depth > maxDepth) maxDepth = depth;
		}
	}
	return { matrix, maxDepth };
};

/**
 * A function to postprocess the matrix generated by the get matrix function.
 * It iterates through all rows of the matrix and the cells in each row. For
 * each cell, an object is created with the cell value (string), the calculated
 * colspan and a CSS-classname, which indicates if the cell should have a
 * background color. A cell object is saved in an array which represents a row
 * in the table. After all cells in a row have been processed, the row array is
 * saved in array in the corresponding row position.
 *
 * @param matrixObj The object generated by the getMatrix-function
 * @returns A 2d-array of table cells, corresponding to rows and columns
 * in the table
 */
const postprocessMatrix = (matrixObj: MatrixObjectType): TableCellType[][] => {
	let colorOn = true;
	const { matrix, maxDepth } = matrixObj;
	const processedMatrix: TableCellType[][] = [];
	for (let rowIndex = 0; rowIndex < matrix.length; rowIndex += 1) {
		const processedRow: TableCellType[] = [];
		for (
			let cellIndex = 0;
			cellIndex < matrix[rowIndex].length;
			cellIndex += 1
		) {
			const value = matrix[rowIndex][cellIndex];
			if (cellIndex === 0 && value !== "span") colorOn = !colorOn;
			const className = `value-group__cell-color-${colorOn}`;
			const colSpan =
				cellIndex === matrix[rowIndex].length - 1
					? maxDepth - matrix[rowIndex].length + 2
					: 0;
			processedRow.push({ value, colSpan, className });
		}
		processedMatrix.push(processedRow);
	}
	return processedMatrix;
};

const ValueGroupRenderer: RendererImplementationComponent<
	RendererImplementation.ValueGroup
> = ({ markup, renderer: Renderer }) => {
	const matrixObj = getMatrix(markup);
	const processedMatrix = postprocessMatrix(matrixObj);
	return (
		<>
			{markup.Header && <Renderer markup={markup.Header} />}
			<table cellSpacing="0" data-testid="value-group-renderer">
				<tbody>
					{processedMatrix.map((row, rowIndex) => {
						return (
							<tr
								key={`row${rowIndex}`}
								data-testid="row"
								data-row-index={rowIndex}
							>
								{row.map((cell, colIndex) => (
									<td
										className={cell.className}
										key={`cell${rowIndex}${colIndex}`}
										colSpan={cell.colSpan}
										data-testid="cell"
										data-row-index={rowIndex}
										data-col-index={colIndex}
									>
										{cell.value === "span" ? "" : cell.value}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default ValueGroupRenderer;
