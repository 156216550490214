import { useMatch } from "react-router-dom";
import type { ActiveNodeInfo } from "../MessageProfilingView/useActiveNode";
import { useActiveSubtreeNode } from "../MessageProfilingView/useActiveNode";
import type { LiteId } from "../../lib/validation/lite/IDSchemas";
import type { Nullish } from "../../utils/types";

export default function useActiveRestrictionProfile(
	nodeId: Nullish<LiteId>,
): ActiveNodeInfo {
	const { params } =
		useMatch(
			`/profilierung/datentypen/:nodeId/profil/:restrictionId/:pathParam`,
		) || {};
	const { pathParam = "" } = params || {};
	return useActiveSubtreeNode(nodeId, pathParam);
}
