import type { ActorRefFrom, ContextFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import type {
	ValidationResult,
	ValidationTargetField,
} from "./validators/types";
import { translateStoreEvents } from "../../../EventStore/helpers";
import type { ProjektMeta } from "../../project/types";
import type { ProjektId } from "../../../../../lib/validation/lite/IDSchemas";

export type ValidationQueryActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof validationQueryMachine
>;
// eslint-disable-next-line no-use-before-define
export type ValidationQueryContext = ContextFrom<typeof validationQueryMachine>;

export type ValidationQueryEvent = {
	type: "PERSIST_DATA_VALIDATION";
	results: ValidationResult<ValidationTargetField>[];
	projektId: ProjektId;
};

export type ValidationStates = "Idle" | "Validating" | "Errored";

const validationQueryMachine = setup({
	types: {
		events: {} as ValidationQueryEvent,
		context: {} as ProjektMeta & {
			validationErrors: {
				results: ValidationResult<ValidationTargetField>[];
			};
		},
		input: {} as ProjektMeta,
	},
	actors: {
		translateEvents: translateStoreEvents<ValidationQueryEvent>(
			{
				"VALIDATION.SUCCESS": (event) => ({
					type: "PERSIST_DATA_VALIDATION",
					...event.payload,
				}),
			},
			{ replayEventLog: true },
		),
	},
	actions: {
		persistErrors: assign(({ event, context }) => {
			assertEvent(event, "PERSIST_DATA_VALIDATION");
			const { results } = event;
			return { ...context, validationErrors: { results } };
		}),
	},
	guards: {
		isSelf: ({ event, context }) => event.projektId === context.projektId,
	},
}).createMachine({
	id: "validation:query",
	context: ({ input }) => ({
		...input,
		validationErrors: { results: [] },
	}),
	invoke: { src: "translateEvents" },
	on: {
		PERSIST_DATA_VALIDATION: {
			guard: "isSelf",
			actions: "persistErrors",
		},
	},
});

export default validationQueryMachine;
