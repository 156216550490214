import type { FieldRendererComponentProps, MultiValueField } from "../../types";
import { useHtmlId } from "../../../../../../hooks";
import useMultiValueRenderer from "./useMultiValueRenderer";
import InputFieldWithInlineChips from "../../../../../ui/Input/InputFieldWithInlineChips";
import ValidationFormatter from "../../../../../ProfilierungValidation/ValidationFormatter";
import "./MultiValueRenderer.scss";

const MultiValueRenderer = ({
	fields,
	activeNode,
	activePath,
	label,
	onFix,
}: FieldRendererComponentProps<MultiValueField>): JSX.Element => {
	const [definition] = fields;
	const {
		values,
		inputValue,
		isAddDisabled,
		validationResults,
		isReadOnly,
		handleInputChange,
		handleAdd,
		createHandleDelete,
	} = useMultiValueRenderer(activeNode, activePath, definition);
	const inputId = useHtmlId();
	const validationResultId = useHtmlId();

	return (
		<div className="multi-value-renderer">
			<InputFieldWithInlineChips
				values={values}
				inputId={inputId}
				dataFieldId={definition.name}
				addButtonDataTestId="add-value"
				inputValue={inputValue}
				isAddDisabled={isAddDisabled}
				isReadOnly={isReadOnly}
				chipTestField="multi-value-tag"
				handleInputChange={handleInputChange}
				handleChange={handleAdd}
				createHandleDelete={createHandleDelete}
				label={label}
			/>
			{validationResults.length > 0 && (
				<div id={validationResultId}>
					<ValidationFormatter
						value={values}
						results={validationResults}
						onFix={onFix}
					/>
				</div>
			)}
		</div>
	);
};

export default MultiValueRenderer;
