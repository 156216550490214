import {
	extractChildNodeEntries,
	extractNodeProps,
	MarkupPropertyNames,
} from "../../../helpers";
import type { Markup, RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import UnknownMarkup from "../UnknownMarkup";

const getEntries = (markup: Markup<RendererImplementation.Span>) => {
	const [entries] = extractChildNodeEntries(markup);

	return entries;
};

const SpanRenderer: RendererImplementationComponent<
	RendererImplementation.Span
> = ({ markup, renderer: Renderer }) => {
	const entries = getEntries(markup);
	if (entries === undefined) return <UnknownMarkup markup={markup} />;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [unusedVar, content] = entries;
	const isString = typeof content === "string";

	const props = extractNodeProps(markup);
	const cssClasses = props[MarkupPropertyNames.CssClass] as string;

	if (isString)
		return (
			<span
				className={cssClasses}
				data-testid="span-renderer"
				data-span-renderer-content="string"
			>
				{content}
			</span>
		);

	return (
		<span
			className={cssClasses}
			data-testid="span-renderer"
			data-span-renderer-content="object"
		>
			<Renderer markup={content} />
		</span>
	);
};

export default SpanRenderer;
