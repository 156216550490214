import {
	extractChildNodeEntries,
	extractNodeProps,
	MarkupPropertyNames,
} from "../../../helpers";
import type { Markup, RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import UnknownMarkup from "../UnknownMarkup";
import styles from "./LinkRenderer.module.scss";

const getEntries = (markup: Markup<RendererImplementation.Link>) => {
	const [entries] = extractChildNodeEntries(markup);

	return entries;
};

const createUrl = (path: string, messageId: string) => {
	const basePath = "/api/v1/viewer/nachrichten/";
	const containsDomain = path.match(/^http:\/\/|^https:\/\/|www\./);
	const isAbsolutePath = path.match(/^\/.*/);

	if (!containsDomain && !isAbsolutePath)
		return `${basePath}${messageId}/${path}`;

	return path;
};

const LinkRenderer: RendererImplementationComponent<
	RendererImplementation.Link
> = ({ markup, renderHelpers }) => {
	const props = extractNodeProps(markup);
	const entries = getEntries(markup);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [unusedVar, content] = entries;

	if (content === undefined) return <UnknownMarkup markup={markup} />;

	const messageId = renderHelpers.getMessageId();
	const url = createUrl(props[MarkupPropertyNames.Href], messageId);

	return (
		<a
			href={url}
			className={styles["xoev-renderer-link"]}
			data-testid="link-renderer"
		>
			{content}
		</a>
	);
};

export default LinkRenderer;
