import { useMemo } from "react";
import { useActiveProjectId } from "../../../../components/AppActor/actors/navigation/hooks";
import { createStandardMetadataFields } from "./metadataFormHelpersLite";
import { useSendStoreEvent } from "../../../../components/AppActor/EventStore/hooks";
import { useMetadataStandard } from "../../../../components/AppActor/actors/modellierungModel/hooks";
import type { MetadatenStandard } from "../../../../lib/validation/lite/LiteSchemas";
import { MetadatenStandardSchema } from "../../../../lib/validation/lite/LiteSchemas";
import EditForm from "../../../../components/EditForm";
import { useIsProjektDisplayOnly } from "../../../../components/AppActor/actors/project/hooks";
import "./MetadataEditFormLite.scss";

const MetadataStandardEditFormLite = (): JSX.Element => {
	const projektId = useActiveProjectId();
	const metadata = useMetadataStandard();
	const isReadOnly = useIsProjektDisplayOnly();

	const fields = useMemo(() => {
		const { visibleFields } = createStandardMetadataFields({
			readOnly: isReadOnly,
		});
		return visibleFields;
	}, [isReadOnly]);

	const sendStoreEvent = useSendStoreEvent();

	if (!projektId) {
		throw new Error("No active project found");
	}

	if (metadata.isEmpty()) {
		return <></>;
	}

	const handleWrite = (
		name: keyof MetadatenStandard,
		value: MetadatenStandard[keyof MetadatenStandard],
	) => {
		if (projektId) {
			sendStoreEvent({
				type: "MODELLIERUNG.MODELL.APPLY",
				payload: {
					projektId,
					patch: {
						type: "changeMetadataStandardValue",
						payload: {
							name,
							value: value ?? "",
						},
					},
				},
			});
		}
	};

	return (
		<div data-testid="project-metadata-form-lite" data-project-id={projektId}>
			<EditForm<typeof MetadatenStandardSchema>
				fields={fields}
				onWrite={handleWrite}
				values={metadata}
				schema={MetadatenStandardSchema}
			/>
		</div>
	);
};
export default MetadataStandardEditFormLite;
