import type { ButtonProps } from "@mui/material/Button";
import Button from "@mui/material/Button";

export interface LoginButtonProps extends ButtonProps {
	label: string;
}

const LoginButton = ({ ...props }: LoginButtonProps): JSX.Element => {
	return <Button {...props}>{props.children}</Button>;
};

export default LoginButton;
