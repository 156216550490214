import type {
	ExtractNodeProps,
	Markup,
	MarkupNode,
	MarkupNodeTypeMap,
	RendererMarkupPropTypes,
	UnknownRendererImplementation,
} from "./types";
import {
	MarkupNodeType,
	RendererImplementation,
	TabGroupOrientation,
} from "./types";

export const INTERNAL_ATTRIBUTE_NAMESPACE = "xoev-suite";

export const NAME_PROP = "xoev-suite:name";
export const VALUE_PROP = "xoev-suite:value";
export const IS_LEAF_PROP = "xoev-suite:is-leaf";
export const IS_EMPTY_PROP = "xoev-suite:is-empty";
export const INDEX_PROP = "xoev-suite:index";
export const PADDING_PROP = "xoev-suite:index-padding-format";
export const NODE_COUNT_PROP = "xoev-suite:node-count";
export const RENDERER_PROP = "xoev-suite:renderer";

export enum MarkupPropertyNames {
	Bold = "xoev-suite:bold",
	CssClass = "xoev-suite:class",
	Href = "xoev-suite:href",
	Italic = "xoev-suite:italic",
	Label = "xoev-suite:label",
	Orientation = "xoev-suite:orientation",
	Renderer = "xoev-suite:renderer",
}

/**
 * Determine the type of a markup node.
 *
 * @param markupNode The markup node you want to check the type of
 * @returns The type name of the markup node @see {MarkupNodeType}
 *
 * @example
 * ```js
 * const markup = {
 *   Tree: {
 *     "xoev-suite:renderer": "Tree",
 *     NodeA: { value: "abc" },
 *     NodeB: { value: ["1", "2", "3"] },
 *     NodeC: { value: [{ name: "x" }, { name: "y" }, { name: "z" }] },
 *   },
 * };
 * getMarkupNodeType(markup.Tree); // -> "Node"
 * getMarkupNodeType(markup.Tree.NodeA); // -> "Node"
 * getMarkupNodeType(markup.Tree.NodeA.value); // -> "String"
 * getMarkupNodeType(markup.Tree.NodeB.value); // -> "StringArray"
 * getMarkupNodeType(markup.Tree.NodeC.value); // -> "NodeArray"
 * getMarkupNodeType(markup.NotSet); // -> "Empty"
 * ```
 */
export function getMarkupNodeType(markupNode: MarkupNode): MarkupNodeType {
	if (typeof markupNode === "string") return MarkupNodeType.String;
	// We're assuming, that arrays never contain mixed contents here, which
	// is what can be expected from an xml file. So if the first element is
	// a string, the rest are strings too
	if (Array.isArray(markupNode) && typeof markupNode[0] === "string")
		return MarkupNodeType.StringArray;
	// If we don't have an array, but are dealing with an object, it's a generic
	// `Markup` object
	if (!Array.isArray(markupNode) && typeof markupNode === "object") {
		return MarkupNodeType.Node;
	}
	// Else, we have an array of `Markup` objects
	if (Array.isArray(markupNode) && typeof markupNode[0] === "object")
		return MarkupNodeType.NodeArray;
	return MarkupNodeType.Empty;
}

/**
 * Check if the provided markup node matches a node type
 *
 * @param markupNode The markup node you want to check the type of
 * @param type The type you want to compare to the markup node's type
 * @returns A boolean indicating whether or not the types match
 *
 * @example
 * ```js
 * const markup = {
 *   Tree: {
 *     "xoev-suite:renderer": "Tree",
 *     NodeA: { value: "abc" },
 *     NodeB: { value: ["1", "2", "3"] },
 *     NodeC: { value: [{ name: "x" }, { name: "y" }, { name: "z" }] },
 *   },
 * };
 * isMarkupNodeType(markup.Tree, "Node"); // -> true
 * isMarkupNodeType(markup.Tree, "String"); // -> false
 * isMarkupNodeType(markup.Tree.NodeA, "Node"); // -> true
 * isMarkupNodeType(markup.Tree.NodeA.value, "String"); // -> true
 * isMarkupNodeType(markup.Tree.NodeB.value, "StringArray"); // -> true
 * isMarkupNodeType(markup.Tree.NodeC.value, "NodeArray"); // -> true
 * ```
 */
export function isMarkupNodeType<Type extends MarkupNodeType>(
	markupNode: MarkupNode,
	type: Type,
): markupNode is MarkupNodeTypeMap[Type] {
	const nodeType = getMarkupNodeType(markupNode);
	return nodeType === type;
}

/**
 * Read the renderer implementation name from a markup node.
 *
 * @param markupNode The markup node you want to check for an implementation
 * name
 * @returns A renderer implementation name or `null` when no implementation
 * could be identified
 *
 * @example
 * ```js
 * getRendererImplementation({ some: { data: "abc" } });
 * // -> null
 * getRendererImplementation({ "xoev-suite:renderer": "TabGroup" } });
 * // -> "TabGroup"
 * getRendererImplementation({ "xoev-suite:renderer": "Tree" } });
 * // -> "Tree"
 * ```
 */
export function getRendererImplementation(
	markupNode: MarkupNode,
): RendererImplementation | null {
	if (!isMarkupNodeType(markupNode, MarkupNodeType.Node)) return null;
	const rendererName = markupNode["xoev-suite:renderer"] as string;
	return rendererName in RendererImplementation
		? (rendererName as RendererImplementation)
		: null;
}

/**
 * Check if a markup node matches a given renderer implementation.
 *
 * @param markupNode The markup node you want to check for an implementation
 * name
 * @param implementation The implementation name to check against the nodes
 * implementation definition
 * @returns A boolean indicating whether or not the implementations match
 *
 * @example
 * ```js
 * isRendererImplementation({ some: { data: "abc" } }, "Tree");
 * // -> false
 * isRendererImplementation({ "xoev-suite:renderer": "Tree" } }, "Tree");
 * // -> true
 * ```
 */
export function isRendererImplementation<
	RendererImpl extends RendererImplementation,
>(
	markupNode: Markup,
	implementation: RendererImpl,
): markupNode is Markup<RendererImpl> {
	return getRendererImplementation(markupNode) === implementation;
}

/**
 * Determine if an attribute is an internal prop, that is only used as a
 * parameter to a renderer or if it is part of the markups structure.
 *
 * @param attributeName The attribute name that will be checked
 * @returns A boolean indicating if the attribute is a renderer parameter
 * or not
 *
 * @example
 * ```js
 * isRendererProp("xoev-suite:renderer");
 * // -> true
 * isRendererProp("Tree");
 * // -> false
 * ```
 */
export function isRendererProp(attributeName: string): boolean {
	return attributeName.startsWith(`${INTERNAL_ATTRIBUTE_NAMESPACE}:`);
}
/**
 * Get the children of a markup node as entry tuples (`[key, node]`).
 * All renderer properties (those beginning with `"xoev-suite"`) are removed
 * from the list.
 *
 * @param markup The markup node you want to access the children of
 * @returns An array of child node entries
 *
 * @example
 * ```js
 * extractChildNodeEntries({
 *   "xoev-suite:renderer": "Tree",
 *   dataA: "abc",
 *   dataB: { value: "123" },
 * });
 * // -> [["dataA", "abc"], ["dataB", { value: "123" }]]
 * ```
 */
export function extractChildNodeEntries(
	markup: Markup | Markup[],
): [string, MarkupNode][] {
	return Object.entries(markup).filter(([attr]) => !isRendererProp(attr));
}

/**
 * Get the children of a markup node.
 * All renderer properties (those beginning with `"xoev-suite"`) are removed
 * from the list.
 *
 * @param markup The markup node you want to access the children of
 * @returns An array of child nodes
 *
 * @example
 * ```js
 * extractChildNodes({
 *   "xoev-suite:renderer": "Tree",
 *   dataA: "abc",
 *   dataB: { value: "123" },
 * });
 * // -> ["abc", { value: "123" }]
 * ```
 */
export function extractChildNodes(markup: Markup | Markup[]): MarkupNode[] {
	return extractChildNodeEntries(markup).map(([, node]) => node);
}

/**
 * Do a breadth-first-traversal of the markup tree using a generator. This is
 * not the easiest to use method to traverse the tree, but allows stopping the
 * iteration at any time, which can improve performance significantly if you
 * only care about certain parts of the markup tree.
 *
 * @param markup The markup tree structure you want to loop through
 * @returns A Generator, that loops through each node in the tree in a
 * breadth-first-traversal
 *
 * @example
 * ```js
 * const markup = {
 *   DataA: {
 *     NodeA: "abc",
 *     NodeB: { value: ["1", "2", "3"] },
 *   },
 *   DataB: {
 *     NodeC: { value: "xyz" },
 *   },
 * };
 * for (const [key, node] of walkMarkupTreeBreadthFirst(markup)) {
 *   console.log(key, node);
 * }
 * // -> "DataA", Object
 * // -> "DataB", Object
 * // -> "NodeA", "abc"
 * // -> "NodeB", Object
 * // -> "NodeC", Object
 * // -> "value", ["1", "2", "3"]
 * // -> "value", "xyz"
 * ```
 */
export function* walkMarkupTreeBreadthFirst(
	markup: Markup | Markup[],
): Generator<[string, MarkupNode], void, void> {
	let currentNode: MarkupNode = markup;
	const queue: [string, MarkupNode][] = [];
	while (true) {
		if (
			isMarkupNodeType(currentNode, MarkupNodeType.Node) ||
			isMarkupNodeType(currentNode, MarkupNodeType.NodeArray)
		) {
			const childNodeEntries = extractChildNodeEntries(currentNode);
			for (const entry of childNodeEntries) {
				queue.push(entry);
			}
		}
		const entry = queue.shift() as [string, MarkupNode];
		if (!entry) break;
		[, currentNode] = entry;
		yield entry;
	}
}

/**
 * Do a depth-first-traversal of the markup whole tree. It will yield
 * the key of the node, the node value and the depth of the node in
 * the markup tree.
 *
 * @param markup The markup tree structure you want to traverse
 * @param depth The depth of the current node, needed for recursive call
 *
 * @example
 * ```js
 * const markup = {
 *   DataA: {
 *     NodeA: "abc",
 *     NodeB: { NodeC: "def" }
 * };
 * for (const [key, node, depth] of walkMarkupTreeDepthFirst(markup)) {
 * 	console.log(key, node, depth)
 * }
 * // -> "DataA", {NodeA: "abc", NodeB: { value: ["1", "2", "3"] }}, 0
 * // -> "NodeA", "abc", 1
 * // -> "NodeB", { NodeC: "def"}, 1
 * // -> "NodeC", "def", 2
 * ```
 */
export function* walkMarkupTreeDepthFirst(
	markup: Markup | Markup[],
	depth = 0,
): Generator<[string, MarkupNode, number], void, void> {
	const childNodeEntries = extractChildNodeEntries(markup);
	for (const [key, node] of childNodeEntries) {
		yield [key, node, depth];
		if (
			isMarkupNodeType(node, MarkupNodeType.Node) ||
			isMarkupNodeType(node, MarkupNodeType.NodeArray)
		) {
			yield* walkMarkupTreeDepthFirst(node, depth + 1);
		}
	}
}

/**
 * Do a breadth-first-traversal of the markup whole tree. The provided callback
 * will be called with each entry in the tree.
 *
 * @param markup The markup tree structure you want to loop through
 * @param callback The callback that is invoked with each entry in the tree
 *
 * @example
 * ```js
 * const markup = {
 *   DataA: {
 *     NodeA: "abc",
 *     NodeB: { value: ["1", "2", "3"] },
 *   },
 *   DataB: {
 *     NodeC: { value: "xyz" },
 *   },
 * };
 * walkMarkupTreeEntries(markup, ([key, node]) => {
 *   console.log(key, node);
 * });
 * // -> "DataA", Object
 * // -> "DataB", Object
 * // -> "NodeA", "abc"
 * // -> "NodeB", Object
 * // -> "NodeC", Object
 * // -> "value", ["1", "2", "3"]
 * // -> "value", "xyz"
 * ```
 */
export function walkMarkupTreeEntries(
	markup: Markup | Markup[],
	callback: (entry: [string, MarkupNode]) => void,
): void {
	for (const entry of walkMarkupTreeBreadthFirst(markup)) {
		callback(entry);
	}
}

export function walkMarkupTreeEntriesDepthFirst(
	markup: Markup | Markup[],
	callback: (entry: [string, MarkupNode, number]) => void,
) {
	for (const entry of walkMarkupTreeDepthFirst(markup)) {
		callback(entry);
	}
}
/**
 * Find an entry in a markup tree.
 *
 * @param markup The markup you want to search in
 * @param predicate A function that is called with each entry in the tree.
 * When it returns `true`, the entry it hit is returned
 * @returns An entry of the tree as a tuple. The first item in the tuple being
 * the key in the parent node and the second element being the corresponding
 * markup node.
 *
 * @example
 * ```js
 * const markup = {
 *   NodeA: "abc",
 *   NodeB: { value: ["1", "2", "3"] },
 * };
 * findEntryInMarkupTree(markup, ([key, node]) => node === "abc");
 * // -> ["NodeA", "abc"]
 * findEntryInMarkupTree(markup, ([key, node]) => key === "NodeB");
 * // -> ["NodeB", { value: ["1", "2", "3"] }]
 * ```
 */
export function findEntryInMarkupTree(
	markup: Markup | Markup[],
	predicate: (entry: [string, MarkupNode]) => boolean,
): [string, MarkupNode] | undefined {
	for (const entry of walkMarkupTreeBreadthFirst(markup)) {
		if (predicate(entry)) {
			return entry;
		}
	}
	return undefined;
}

/**
 * Find an element in a markup tree.
 *
 * @param markup The markup you want to search in
 * @param predicate A function that is called with each entry in the tree.
 * When it returns `true`, the element it hit is returned
 *
 * @example
 * ```js
 * const markup = {
 *   NodeA: "abc",
 *   NodeB: { value: ["1", "2", "3"] },
 * };
 * findInMarkupTree(markup, ([key, node]) => node === "abc");
 * // -> "abc"
 * findInMarkupTree(markup, ([key, node]) => key === "NodeB");
 * // -> { value: ["1", "2", "3"] }
 * ```
 */
export function findInMarkupTree(
	markup: Markup | Markup[],
	predicate: (entry: [string, MarkupNode]) => boolean,
): MarkupNode | undefined {
	const entry = findEntryInMarkupTree(markup, predicate);
	return entry ? entry[1] : undefined;
}

/**
 * Extract the renderer props that may be present in a markup node into an
 * object.
 *
 * @param markup The markup node containing renderer properties
 * @returns An object of the renderer properties of the markup node
 *
 * @example
 * ```js
 * extractNodeProps({
 *   "xoev-suite:renderer": "TabGroup",
 *   "xoev-suite:orientation": "HORIZONTAL",
 *   dataA: "abc",
 *   dataB: { value: "123" },
 * });
 * // -> {
 * //   "xoev-suite:renderer": "TabGroup",
 * //   "xoev-suite:orientation": "HORIZONTAL",
 * // }
 * ```
 */
export function extractNodeProps<
	RendererImpl extends UnknownRendererImplementation = UnknownRendererImplementation,
>(
	markup: Markup<RendererImpl>,
): RendererImpl extends keyof RendererMarkupPropTypes
	? ExtractNodeProps<RendererMarkupPropTypes[RendererImpl]>
	: Record<string, string> {
	type Return = RendererImpl extends keyof RendererMarkupPropTypes
		? ExtractNodeProps<RendererMarkupPropTypes[RendererImpl]>
		: Record<string, string>;
	const internalAttributeEntries = Object.entries(markup).filter(
		([prop, value]) => isRendererProp(prop) && !!value,
	) as [string, string][];
	return Object.fromEntries(internalAttributeEntries) as Return;
}

/**
 * Remove possible renderer props from a markup node.
 *
 * @param markup The markup node containing renderer properties
 * @returns A new markup node that does not contain renderer props any more
 *
 * @example
 * ```js
 * removeNodeProps({
 *   "xoev-suite:renderer": "TabGroup",
 *   "xoev-suite:orientation": "HORIZONTAL",
 *   dataA: "abc",
 *   dataB: { value: "123" },
 * });
 * // -> {
 * //   dataA: "abc",
 * //   dataB: { value: "123" },
 * // }
 * ```
 */
export function removeNodeProps(markup: Markup): Markup {
	const internalAttributeEntries = Object.entries(markup).filter(
		([prop]) => !isRendererProp(prop),
	);
	return Object.fromEntries(internalAttributeEntries);
}

/**
 * Add renderer props to a markup node.
 *
 * @param markup The markup node you want to add renderer props to
 * @param props The renderer props you want to add to the node
 * @returns A new markup node that contains the specified renderer props
 *
 * @example
 * ```js
 * assignNodeProps(
 *   {
 *     dataA: "abc",
 *     dataB: { value: "123" },
 *   },
 *   {
 *     "xoev-suite:renderer": "TabGroup",
 *     "xoev-suite:orientation": "HORIZONTAL",
 *   },
 * );
 * // -> {
 * //   "xoev-suite:renderer": "TabGroup",
 * //   "xoev-suite:orientation": "HORIZONTAL",
 * //   dataA: "abc",
 * //   dataB: { value: "123" },
 * // }
 * ```
 */
export function assignNodeProps<RendererImpl extends RendererImplementation>(
	markup: Markup,
	props: Record<string, string> & {
		"xoev-suite:renderer"?: RendererImpl;
	} & (RendererImpl extends keyof RendererMarkupPropTypes
			? RendererMarkupPropTypes[RendererImpl]
			: Record<string, string>),
): Markup<RendererImpl> {
	const propRecord = props as Record<string, string>;
	const extendedMarkup = { ...markup, ...propRecord } as Markup<RendererImpl>;
	return extendedMarkup;
}

/**
 * A set of helper functions that simplifiy the creation, modification and
 * rendering of a markup tree
 */
export interface HelperBag {
	getMarkupNodeType: typeof getMarkupNodeType;
	isMarkupNodeType: typeof isMarkupNodeType;
	getRendererImplementation: typeof getRendererImplementation;
	isRendererImplementation: typeof isRendererImplementation;
	isRendererProp: typeof isRendererProp;
	extractChildNodeEntries: typeof extractChildNodeEntries;
	extractChildNodes: typeof extractChildNodes;
	extractNodeProps: typeof extractNodeProps;
	removeNodeProps: typeof removeNodeProps;
	assignNodeProps: typeof assignNodeProps;
	walkMarkupTreeBreadthFirst: typeof walkMarkupTreeBreadthFirst;
	walkMarkupTreeEntries: typeof walkMarkupTreeEntries;
	findEntryInMarkupTree: typeof findEntryInMarkupTree;
	findInMarkupTree: typeof findInMarkupTree;
	enums: {
		RendererImplementation: typeof RendererImplementation;
		MarkupNodeType: typeof MarkupNodeType;
		TabGroupOrientation: typeof TabGroupOrientation;
	};
}

export const helperBag: HelperBag = {
	getMarkupNodeType,
	isMarkupNodeType,
	getRendererImplementation,
	isRendererImplementation,
	isRendererProp,
	extractChildNodeEntries,
	extractChildNodes,
	extractNodeProps,
	removeNodeProps,
	assignNodeProps,
	walkMarkupTreeBreadthFirst,
	walkMarkupTreeEntries,
	findEntryInMarkupTree,
	findInMarkupTree,
	enums: {
		RendererImplementation,
		MarkupNodeType,
		TabGroupOrientation,
	},
};
