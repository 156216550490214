import { useState } from "react";
import { Divider, FormControlLabel } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import type { DisplayRendererProps } from "../../../components/LiteModelView/DetailsView/renderers/types";
import type {
	Geschaeftsregel,
	LiteBaustein,
	LiteEigenschaft,
} from "../../../lib/validation/lite/LiteSchemas";

import "./GeschaeftsregelnDisplay.scss";
import GeschaeftsregelSingleDisplay from "../../Molecules/GeschaeftsregelSingleDisplay/GeschaeftsregelSingleDisplay";
import GeschaeftsregelWrapper from "../../Atoms/GeschaeftsregelWrapper/GeschaeftsregelWrapper";

export default function GeschaeftsregelnDisplay({
	activeNode: { geschaeftsregeln },
}: DisplayRendererProps<LiteBaustein | LiteEigenschaft>): JSX.Element {
	const [showGeschaeftsregeln, setShowGeschaeftsregeln] = useState(false);

	const isValidGeschaeftsregelnMap =
		geschaeftsregeln !== undefined && geschaeftsregeln.length > 0;

	return (
		<>
			<FormControlLabel
				control={showGeschaeftsregeln ? <ExpandLess /> : <ExpandMore />}
				label="Geschaeftsregeln"
				labelPlacement="start"
				className="geschaeftsregeln-toggle-label"
				onClick={() => setShowGeschaeftsregeln(!showGeschaeftsregeln)}
			/>
			{showGeschaeftsregeln &&
				isValidGeschaeftsregelnMap &&
				geschaeftsregeln.map((geschaeftsregel: Geschaeftsregel, index) => {
					return (
						<GeschaeftsregelWrapper key={geschaeftsregel.kennung}>
							<GeschaeftsregelSingleDisplay geschaeftsregel={geschaeftsregel} />
							{index !== geschaeftsregeln.length - 1 && (
								<Divider sx={{ borderColor: "var(--text-ghost)" }} />
							)}
						</GeschaeftsregelWrapper>
					);
				})}
		</>
	);
}
