import type { Dispatch, SetStateAction } from "react";
import type { Seq } from "immutable";
import type { ImmutableMap } from "@xoev/immutable-map";
import type {
	StateContainer,
	StateReducerMap,
	PatchCreatorMap,
	StateSelector,
} from "@xoev/state-container";
import { z } from "zod";
import type ProfilingMetadataValues from "../../types/ProflierungMetadataValues";
import type {
	SavedCodeList,
	SavedProfileDocumentation,
} from "../../types/SavedProfileData";
import type { RefType } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import type { QName, QNamePath } from "../../lib/validation/lite/LiteSchemas";

export const RestrictionIdSchema = z.string().brand("RestrictionId");
export type RestrictionId = z.infer<typeof RestrictionIdSchema>;

export interface Recursion {
	limit?: string;
	beschreibung?: string;
}

export type CodelistProfileMap = ImmutableMap<Partial<SavedCodeList>>;

export interface PropertyProfile {
	name: string;
	value?: string;
}

export type PropertyProfileMap = ImmutableMap<{
	[id: string]: ImmutableMap<PropertyProfile>;
}>;

export interface MessageProfileConfiguration {
	fixedWert?: string;
	defaultWert?: string;
	beispielWert?: string;
	beispielWertNachricht?: string[];
	codeliste?: CodelistProfileMap;
}

export interface Datatype {
	name: string;
	datentyp: QName;
}

export interface MessageProfileValues {
	beschreibung?: string;
	umsetzungshinweis?: string;
	lowerBound?: string;
	upperBound?: string;
	errorMessageRecursion?: string;
	istRekursionsStart?: boolean;
	konfiguration?: ImmutableMap<MessageProfileConfiguration>;
	datentyp?: ImmutableMap<Datatype>;
	eigenschaften?: PropertyProfileMap;
	rekursion?: ImmutableMap<Recursion>;
}

export interface RestrictionProfileValues {
	id: RestrictionId;
	name: string;
	beschreibung?: string;
	umsetzungshinweis?: string;
	profile?: ImmutableMap<{
		[nodeQNamePath: QNamePath]: ImmutableMap<Partial<MessageProfileValues>>;
	}>;
}
export type RestrictionsMap = ImmutableMap<{
	[restrictionId: RestrictionId]: ImmutableMap<RestrictionProfileValues>;
}>;
export interface DatatypeProfileValues {
	name: string;
	beschreibung?: string;
	restrictions: RestrictionsMap;
}

export interface PropertyValues {
	name: string;
	nameTechnisch: string;
	beschreibung: string;
}
export interface ProfilingConfiguration {
	eigenschaften?: PropertyValues[];
}

export type MetadataMap = ImmutableMap<Partial<ProfilingMetadataValues>>;
export type StateProfileMap = ImmutableMap<{
	[nodeQNamePath: QNamePath]: ImmutableMap<Partial<MessageProfileValues>>;
}>;
export type DocumentationMap = ImmutableMap<{
	[docTitleKey: string]: ImmutableMap<SavedProfileDocumentation>;
}>;
export type DatatypeMap = ImmutableMap<{
	[nodeQName: QName]: ImmutableMap<Partial<DatatypeProfileValues>>;
}>;
export type StateConfigMap = ImmutableMap<{
	profilierung: ImmutableMap<ProfilingConfiguration>;
}>;

// This is where the shape of the editor's state is defined
export type InnerEditorState = {
	metadaten: MetadataMap;
	profile: StateProfileMap;
	dokumentation: DocumentationMap;
	datentypen: DatatypeMap;
	konfiguration: StateConfigMap;
};
export type EditorState = ImmutableMap<InnerEditorState>;

export interface EditorMeta {
	url: string;
}

export interface EditorPatchDefinition<Payload> {
	payload: Payload;
	meta: EditorMeta;
}

// This is where the types of everything involving updates to the state is
// defined. Patch-creators and the reducer are all dependent on this map.
// Please wrap all entries with a `EditorPatchDefinition`, so we can make sure
// that the current url is always passed along with the patch, so we change
// views correctly on undo/redo

export type ProfilingNameType =
	| keyof MessageProfileValues
	| keyof MessageProfileConfiguration
	| keyof Recursion
	| keyof SavedCodeList
	| "beschreibungRekursion";

export type EditorPatchMap = {
	setContainerState: EditorPatchDefinition<EditorState>;
	setMetadata: EditorPatchDefinition<MetadataMap>;
	setMetadataValue: EditorPatchDefinition<Partial<ProfilingMetadataValues>>;
	setProfile: EditorPatchDefinition<{
		id: QNamePath;
		profile: ImmutableMap<Partial<MessageProfileValues>>;
	}>;
	setPartialProfile: EditorPatchDefinition<{
		id: QNamePath;
		values: Partial<MessageProfileValues>;
	}>;
	setValueForProfile: EditorPatchDefinition<{
		id: QNamePath;
		name: ProfilingNameType;
		value: string;
	}>;
	deleteProfile: EditorPatchDefinition<{ id: QNamePath }>;
	deletePartialProfile: EditorPatchDefinition<{
		id: QNamePath;
		keys: (keyof MessageProfileValues)[];
	}>;
	setDocumentation: EditorPatchDefinition<{
		name: string;
		documentation: ImmutableMap<SavedProfileDocumentation>;
	}>;
	changeDocumentTitle: EditorPatchDefinition<{
		oldTitle: string;
		newTitle: string;
	}>;
	deleteDocument: EditorPatchDefinition<{
		docTitle: string;
	}>;
	reorderDocuments: EditorPatchDefinition<{
		fromIndex: number;
		toIndex: number;
	}>;
	addVisibleDocument: EditorPatchDefinition<{
		docTitle: string;
	}>;
	setDatatype: EditorPatchDefinition<{
		id: QName;
		datatype: ImmutableMap<Partial<DatatypeProfileValues>>;
	}>;
	setRestrictionProfile: EditorPatchDefinition<{
		datatypeId: QName;
		restrictionId: RestrictionId;
		restrictionProfileId: QNamePath;
		profile: ImmutableMap<Partial<MessageProfileValues>>;
	}>;
	setValueForRestrictionProfile: EditorPatchDefinition<{
		datatypeId: QName;
		restrictionId: RestrictionId;
		restrictionProfileId: QNamePath;
		name: ProfilingNameType;
		value: string;
	}>;
	deletePartialRestrictionProfile: EditorPatchDefinition<{
		datatypeId: QName;
		restrictionId: RestrictionId;
		restrictionProfileId: QNamePath;
		keys: (keyof MessageProfileValues)[];
	}>;
	deleteRestrictionProfile: EditorPatchDefinition<{
		datatypeId: QName;
		restrictionId: RestrictionId;
		restrictionProfileId: QNamePath;
	}>;
	changeRestrictionSelection: EditorPatchDefinition<{
		selection: string[];
		restrictionId: RestrictionId;
	}>;
	addProperty: EditorPatchDefinition<PropertyValues>;
	deleteProperty: EditorPatchDefinition<{ nameTechnisch: string }>;
	editProperty: EditorPatchDefinition<{
		nameTechnisch: string;
		values: PropertyValues;
	}>;
};

export type EditorStateContainer = StateContainer<EditorState, EditorPatchMap>;

export type EditorReducerMap = StateReducerMap<EditorState, EditorPatchMap>;

export type EditorPatchCreatorMap = PatchCreatorMap<
	EditorState,
	EditorPatchMap
>;
export type EditorPatchCreators = {
	[K in keyof EditorPatchCreatorMap]: (
		payload: Parameters<EditorPatchCreatorMap[K]>[0],
	) => ReturnType<EditorPatchCreatorMap[K]>;
};

export type EditorStateContainerContext = {
	patchCreators: EditorPatchCreators;
	setStateContainer: Dispatch<SetStateAction<EditorStateContainer>>;
};

export type EditorStateSelector<SelectorReturn> = StateSelector<
	EditorState,
	SelectorReturn
>;

export type EditorSelectorReturn<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Selector extends (...args: any[]) => EditorStateSelector<any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
> = Selector extends (...args: any[]) => EditorStateSelector<infer T>
	? T
	: never;

export type RestrictionEntry = [
	restrictionId: RestrictionId,
	restriction: ImmutableMap<RestrictionProfileValues>,
];

export type ProfileSeqEntry = {
	restrictionId: RestrictionId;
	profileId: QNamePath;
	profile: ImmutableMap<Partial<MessageProfileValues>>;
};

export type RestrictionEntries = Seq.Indexed<RestrictionEntry>;

export type RestrictionValues = Seq.Indexed<
	ImmutableMap<RestrictionProfileValues>
>;

export interface ReferenceMapItem {
	nodeId: string;
	datatypeId: QName;
	restrictionId: RestrictionId | null;
	restrictionName: string | null;
}

export interface TransitiveRef<Ref, Identifier = string> {
	identifier: Identifier;
	ref: Ref;
	via: Identifier[];
}

export enum SelectablilityCode {
	None = "None",
	Parent = "Parent",
	Child = "Child",
	SiblingRestriction = "SiblingRestriction",
	StandardView = "StandardView",
	CodelistValues = "CodelistValues",
}

export interface SelectablilityStatusBase {
	code: SelectablilityCode;
}

export interface SelectablilityStatusNoCode extends SelectablilityStatusBase {
	code: SelectablilityCode.None;
}

export interface SelectablilityStatusParent extends SelectablilityStatusBase {
	code: SelectablilityCode.Parent;
	profileId: string;
}

export interface SelectablilityStatusChild extends SelectablilityStatusBase {
	code: SelectablilityCode.Child;
	profileId: string;
}

export interface SelectablilityStatusSiblingRestriction
	extends SelectablilityStatusBase {
	code: SelectablilityCode.SiblingRestriction;
	restrictionId: string;
	restrictionName: string;
}

export interface SelectablilityStatusStandardView
	extends SelectablilityStatusBase {
	code: SelectablilityCode.StandardView;
}

export interface SelectablilityStatusCodelistValues
	extends SelectablilityStatusBase {
	code: SelectablilityCode.CodelistValues;
}

export type SelectablilityStatus =
	| SelectablilityStatusNoCode
	| SelectablilityStatusParent
	| SelectablilityStatusChild
	| SelectablilityStatusSiblingRestriction
	| SelectablilityStatusStandardView
	| SelectablilityStatusCodelistValues;

export interface ProfileRefBase {
	type: RefType;
}

export interface MessageElementProfileRef extends ProfileRefBase {
	type: RefType.MessageElement;
	profileId: QNamePath;
}

export interface DatatypeProfileRef {
	type: RefType.Datatype;
	profileId: QNamePath;
	restrictionId: RestrictionId;
	restrictionName: string;
	datatypeId: QName;
}

export type ProfileRef = MessageElementProfileRef | DatatypeProfileRef;
