import type { LiteId } from "./IDSchemas";
import { LiteIdSchema } from "./IDSchemas";
import type { LitePath, QNamePath, QName } from "./LiteSchemas";
import { LitePathSchema, QNameSchema, QNamePathSchema } from "./LiteSchemas";

export function parseLitePath(path: LitePath): LiteId[] {
	return path.split("/").map((id) => LiteIdSchema.parse(id));
}

export function joinLitePath(path: LiteId[]): LitePath {
	return LitePathSchema.parse(path.join("/"));
}

export function parseQNamePath(path: QNamePath): QName[] {
	return path.split("/").map((id) => QNameSchema.parse(id));
}

export function joinQNamePath(path: QName[]): QNamePath {
	return QNamePathSchema.parse(path.join("/"));
}
