import { useDebugValue, useMemo, useState } from "react";
import merge from "lodash/merge";
import { useKeycloak } from "../Keycloak/KeycloakProvider";
import { RequestStatus } from "./types";
import type {
	RequestState,
	ApiFetchOptions,
	FetchHandlers,
	RequestOptions,
	Resource,
	RequestMethods,
} from "./types";
import useFetch from "./useFetch";
import { useEventHandler } from "../../hooks";

function createInitialState<ResponseData>(
	initialData?: ResponseData | null,
): RequestState<ResponseData> {
	return {
		data: initialData ?? null,
		error: null,
		headers: null,
		status: RequestStatus.Idle,
	};
}

/**
 * Request data from an endpoint in a declarative way.
 *
 * @example
 * ```tsx
 * const {
 *   // Fire a request using the request function
 *   request,
 *   // `data` will always contain the response data. No need to store the
 *   // response data manually
 *   data,
 *   // Status indicates the state of the request. Its possible values are:
 *   // "idle", "loading", "success" or "failure"
 *   status,
 *   // Error will be an object containing a `status` and a `message` if the
 *   // request fails
 *   error,
 * } = useResource<{ value: number }>(
 *   // Optionally you can provide an initial value for data
 *   0,
 * );
 * ```
 */
function useResource<
	ResponseData,
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
>({ initialData = null }: { initialData?: ResponseData | null } = {}): Resource<
	ResponseData,
	Method,
	RequestData
> {
	const { keycloak, authenticated } = useKeycloak();
	const [state, setState] = useState<RequestState<ResponseData>>(() =>
		createInitialState(initialData),
	);

	// Display a helpful label in react devtools
	useDebugValue({ data: state.data, error: state.error, status: state.status });

	const handlers: FetchHandlers<ResponseData, undefined> = useMemo(
		() => ({
			onRequest() {
				setState((prevState) => ({
					...prevState,
					status: RequestStatus.Loading,
				}));
			},
			onSuccess(response, headers) {
				setState({
					data: response,
					error: null,
					headers,
					status: RequestStatus.Success,
				});
			},
			onFailure(error) {
				setState((prevState) => ({
					...prevState,
					error,
					status: RequestStatus.Failure,
				}));
			},
		}),
		[],
	);

	const fetch = useFetch<ResponseData, Method, RequestData, undefined>(
		handlers,
	);

	const request = useEventHandler(
		(endpoint: string, options?: RequestOptions<Method, RequestData>) => {
			const config = {
				ctx: undefined,
				...merge(
					options,
					keycloak && authenticated
						? {
								headers: {
									Authorization: `Bearer ${keycloak.token}`,
								},
						  }
						: {},
				),
			} as ApiFetchOptions<Method, RequestData, undefined>;
			fetch(endpoint, config);
		},
	);

	const resource = useMemo(() => ({ ...state, request }), [request, state]);

	return resource;
}

export default useResource;
