import type { SyntheticEvent } from "react";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import type { Markup } from "../../../types";
import { RendererImplementation, TabGroupOrientation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import "./TabGroupRenderer.scss";
import {
	extractChildNodeEntries,
	extractNodeProps,
	isRendererImplementation,
	MarkupPropertyNames,
} from "../../../helpers";

const getOrientationName = (isHorizontal: boolean) => {
	return isHorizontal ? "horizontal" : "vertical";
};

const TabGroupRenderer: RendererImplementationComponent<
	RendererImplementation.TabGroup
> = ({ markup, renderer: Renderer }) => {
	const props = extractNodeProps(markup);
	const orientation =
		props[MarkupPropertyNames.Orientation] ?? TabGroupOrientation.Horizontal;
	const isHorizontal = orientation === TabGroupOrientation.Horizontal;
	const tabList = extractChildNodeEntries(markup)
		.filter((child) =>
			isRendererImplementation(child[1] as Markup, RendererImplementation.Tab),
		)
		.map((child) => child[1] as Markup<RendererImplementation.Tab>);

	const [tabIndex, setTabIndex] = useState("0");
	const labels = tabList.map((entry) => entry[MarkupPropertyNames.Label]);

	const handleTabChange = (event: SyntheticEvent, newIndex: string) => {
		setTabIndex(newIndex);
	};

	return (
		<TabContext value={tabIndex}>
			<Box
				component="div"
				className={
					isHorizontal ? "tab-group-horizontal" : "tab-group-vertical__tabs"
				}
				data-testid="tab-group-renderer"
			>
				<TabList
					orientation={getOrientationName(isHorizontal)}
					onChange={handleTabChange}
				>
					{labels.map(
						(label, index) =>
							label && (
								<Tab
									value={String(index)}
									label={label}
									key={label}
									data-orientation={getOrientationName(isHorizontal)}
									data-label={label}
									data-testid="tab"
								/>
							),
					)}
				</TabList>
				{tabList.map((tab, index) => (
					<TabPanel
						value={String(index)}
						key={tab[MarkupPropertyNames.Label]}
						data-orientation={getOrientationName(isHorizontal)}
						data-label={tab[MarkupPropertyNames.Label]}
						data-testid="tab-panel"
					>
						{tab.Header && <Renderer key={tab.Header} markup={tab.Header} />}
						<Renderer markup={tab} />
					</TabPanel>
				))}
			</Box>
		</TabContext>
	);
};

export default TabGroupRenderer;
