import type { ModelKonfiguration } from "../../../lib/validation/lite/LiteSchemas";
import {
	createFormHelpers,
	type FieldDefinition,
	type FormHelpers,
} from "../../EditFormFieldLite";

/* TODO: Move to translation files */
// TODO: Initially the required field namespace and prefix validation is switched off,
// later it is to be reactivated with the addition
// that the required field validation only takes effect
// if no value is defined in the underlying schemapaket.
export function createConfigurationFields({
	readOnly,
}: {
	readOnly: boolean;
}): FieldDefinition<ModelKonfiguration>[] {
	return [
		{
			name: "fassungVom",
			label: "Fassung vom",
			readOnly,
		},
		{
			name: "namespace",
			label: "Namensraum",
			readOnly,
			// isRequired: true,
		},
		{
			name: "prefix",
			label: "Präfix",
			readOnly,
			// isRequired: true,
		},
		{
			name: "schemaLocationBase",
			label: "Schema Location Base",
			readOnly,
		},
		{
			name: "elementFormDefault",
			label: "Element Form Default",
			readOnly,
		},
		{
			name: "standardeinstellungEigenschaften",
			label: "Standardeinstellung Eigenschaften",
			readOnly,
		},
		{
			name: "xsdGlobalElementNamePrefix",
			label: "Xsd Global Element Name Präfix",
			readOnly,
		},
		{
			name: "xsdGlobalElementNameSuffix",
			label: "Xsd Global Element Name Suffix",
			readOnly,
		},
		{
			name: "xsdNamedTypeNamePrefix",
			label: "XsdNamedType Name-Prefix",
			readOnly,
		},
		{
			name: "xsdNamedTypeNameSuffix",
			label: "XSDNamedType Name-Suffix",
			readOnly,
		},
		{
			name: "typDesCodeElements",
			label: "Typ Des Code Elements",
			readOnly,
		},
		{
			name: "nutzungNameElement",
			label: "Nutzung Name Element",
			readOnly,
		},
		{
			name: "benannterCodelistenDatentyp",
			label: "Benannter Codelisten Datentyp",
			readOnly,
		},
		{
			name: "codesSchemavalidiert",
			label: "Codes Schema validiert",
			readOnly,
		},
		{
			name: "wsdlStandardaufbauNamensraum",
			label: "Wsdl Standardaufbau Namensraum",
			readOnly,
		},
		{
			name: "wsdlStandardaufbauSubjectOperation",
			label: "Wsdl Standardaufbau Subject Operation",
			readOnly,
		},
		{
			name: "wsdlDateipraefix",
			label: "Wsdl Dateipräfix",
			readOnly,
		},
	];
}

export function createConfigurationFormHelpers({
	readOnly,
}: {
	readOnly: boolean;
}): FormHelpers<ModelKonfiguration> {
	return createFormHelpers<ModelKonfiguration>(
		createConfigurationFields({ readOnly }),
	);
}
