import { filterFalsy } from "../../../utils/lists";
import { useProjectBreadcrumb } from "../../../components/EditorNavBar/NavBarBreadcrumbs/breadCrumbs";
import NavBarBreadcrumbs from "../../../components/EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import useActiveProjectSection, {
	ProjectSection,
} from "../../../features/ProfilierungMetadataView/hooks/useActiveProjectSection";
import EditorNavBar from "../../../components/EditorNavBar";

const MetadataNavBar = (): JSX.Element => {
	const projectBreadcrumb = useProjectBreadcrumb();
	const projectSection = useActiveProjectSection();

	const breadcrumbs = filterFalsy([
		projectBreadcrumb,
		projectSection === ProjectSection.Metadata && {
			key: "__METADATA__",
			content: "Metadaten",
			to: "/profilierung/projekt/metadaten",
		},
		projectSection === ProjectSection.Properties && {
			key: "__ATTRIBUTES__",
			content: "Eigenschaftsdefinitionen",
			to: "/profilierung/projekt/eigenschaften",
		},
	]);

	return (
		<EditorNavBar data-testid="metadata-navbar">
			<NavBarBreadcrumbs items={breadcrumbs} />
		</EditorNavBar>
	);
};

export default MetadataNavBar;
