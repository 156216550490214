import { useModellierungModellSelector } from "../../../../../AppActor/actors/modellierungModel/hooks";
import type { LiteEigenschaft } from "../../../../../../lib/validation/lite/LiteSchemas";
import { createSelectInfiniteChildren } from "../../../../../AppActor/actors/modellierungModel/selectors";
import InfoRendererDisplay from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import type { DisplayRendererProps } from "../../types";

export default function PresetValuesDisplay({
	activeNode,
}: DisplayRendererProps<LiteEigenschaft>): JSX.Element {
	const children = useModellierungModellSelector(({ projekt }) =>
		createSelectInfiniteChildren(projekt)(activeNode),
	);
	if (children && children.length) return <></>;
	/* TODO: add to translations */

	return (
		<>
			<InfoRendererDisplay
				label="Fix-Wert"
				value={activeNode.fixedWert}
				fallback="Kein Fix-Wert vorhanden"
			/>
			<InfoRendererDisplay
				label="Standard-Wert"
				value={activeNode.defaultWert}
				fallback="Kein Standard-Wert vorhanden"
			/>
		</>
	);
}
