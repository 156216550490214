import { useCallback, useContext } from "react";
import { EditorSection, useEditorSection } from "../components/EditorPage";
import { Severity } from "../components/ProfilierungValidation/types";
import ProfilingNodeValidationContext from "../AtomicComponents/Organisms/ProfilierungNodeValidationProvider/ProfilierungNodeValidationContext";
import type { SeverityMap } from "../AtomicComponents/Organisms/ProfilierungNodeValidationProvider/types";
import type { LiteId } from "../lib/validation/lite/IDSchemas";
import type { RestrictionId } from "../components/EditorState/types";
import type { Nullish } from "../utils/types";

const severitiyOrder = [Severity.Error, Severity.Warning, Severity.Info];

export function useGetNodeSeverity(): (path: LiteId[]) => Severity | null {
	const maps = useContext(ProfilingNodeValidationContext);
	const section = useEditorSection();
	const getNodeSeverity = useCallback(
		(path: LiteId[]) => {
			if (!maps) return null;
			const { messageProfiles, restrictionProfiles } = maps;
			let map: SeverityMap | null = null;
			if (section === EditorSection.Datatypes) {
				map = restrictionProfiles;
			}
			if (section === EditorSection.MessageStructures) {
				map = messageProfiles;
			}
			if (!map) return null;
			for (const severity of severitiyOrder) {
				if (map[severity].has(path) || map[severity].hasChildOf(path)) {
					return severity;
				}
			}
			return null;
		},
		[maps, section],
	);
	return getNodeSeverity;
}

export function useNodeSeverity(path: Nullish<LiteId[]>): Severity | null {
	const getNodeSeverity = useGetNodeSeverity();
	if (!path) return null;
	return getNodeSeverity(path);
}

export function useGetRestrictionSeverity(): (
	restrictionId: RestrictionId,
) => Severity | null {
	const maps = useContext(ProfilingNodeValidationContext);
	const section = useEditorSection();
	const getRestrictionSeverity = useCallback(
		(restrictionId: RestrictionId) => {
			if (!maps) return null;
			const { restrictions } = maps;
			const map = section === EditorSection.Datatypes ? restrictions : null;
			if (!map) return null;
			for (const severity of severitiyOrder) {
				if (map[severity].has(restrictionId)) {
					return severity;
				}
			}
			return null;
		},
		[maps, section],
	);
	return getRestrictionSeverity;
}

export function useRestrictionSeverity(
	restrictionId: RestrictionId,
): Severity | null {
	const getRestrictionSeverity = useGetRestrictionSeverity();
	return getRestrictionSeverity(restrictionId);
}
