import type { Equals, IsNever } from "@xoev/type-test";
import type {
	ComponentType,
	DetailedHTMLProps,
	FormHTMLAttributes,
	ReactElement,
	ReactNode,
} from "react";
import type ValueOf from "../types/ValueOf";

export type NodeOrRenderPropChildren<RenderPropArgs extends unknown[]> =
	| ReactNode
	| ((...renderProps: RenderPropArgs) => ReactNode);

export type ElementOrRenderPropChildren<RenderPropArgs extends unknown[]> =
	| ReactElement
	| ((...renderProps: RenderPropArgs) => ReactElement);

/**
 * Extract the keys that are shared between two object types.
 * @example
 * ```ts
 * interface A {
 *   v: string;
 *   w: string;
 *   x: string;
 *   y: string;
 * }
 * interface B {
 *   w: string;
 *   x: string;
 *   y: string;
 *   z: string;
 * }
 * type C = SharedKeys<A, B>; // "w" | "x" | "y"
 * ```
 */
export type SharedKeys<A, B> = Extract<keyof A, keyof B>;

/**
 * Safely extend the prop type of one component from another component's prop
 * type. Keys that are present in both the base and the extending type are
 * removed from the base first and then only taken from the extending type.
 * This way, we can prevent TS from trying to merge types from duplicate keys.
 *
 * @example
 * ```ts
 * interface InputComponentProps {
 *   name: string;
 *   // The `onChange` function in the html input props has a different
 *   // signature to our custom input's `onChange`. We need to make sure
 *   // TS does not mash the signatures or take the wrong one
 *   onChange: (newValue: string, oldValue: string) => void;
 * }
 *
 * type HTMLInputProps = DetailedHTMLProps<
 *   InputHTMLAttributes<HTMLInputElement>,
 *   HTMLInputElement
 * >;
 *
 * // `InputProps` only contains the `onChange` definition from
 * // `InputComponentProps`
 * type InputProps = ExtendProps<HTMLInputProps, InputComponentProps>;
 * ```
 */
export type ExtendProps<Base, Extension> = Omit<
	Base,
	SharedKeys<Base, Extension>
> &
	Extension;

export type KeysOfOptionalValues<T> = ValueOf<{
	[K in keyof T]-?: undefined extends T[K] ? K : never;
}>;

export type KeysOfRequiredValues<T> = ValueOf<{
	[K in keyof T]-?: undefined extends T[K] ? never : K;
}>;

export type ShallowPrettify<T> = {
	[K in keyof T]: T[K];
	// eslint-disable-next-line @typescript-eslint/ban-types
} & {};

/**
 * Make all properties, that are either optional or assignable to `undefined`
 * optional.
 *
 * @example
 * ```ts
 * UndefinedToOptional<{ a?: string; b: string | undefined; c: number }>
 * // -> { a?: string; b?: string; c: number }
 * ```
 */
export type UndefinedToOptional<T> = ShallowPrettify<
	{
		[K in KeysOfOptionalValues<T>]?: T[K];
	} & {
		[K in KeysOfRequiredValues<T>]: T[K];
	}
>;

/**
 * Remove all properties from a type, that are of type `never`.
 *
 * @example
 * ```ts
 * ExcludeNeverValues<{ a: never; b: string; c: never }>
 * // -> { b: string }
 * ```
 */
export type ExcludeNeverValues<T> = {
	[K in keyof T as IsNever<T[K]> extends true ? never : K]: IsNever<
		T[K]
	> extends true
		? never
		: T[K];
};

/**
 * Extract those keys and values from two object types, that are identical.
 * Properties, that exist in one object, but not the other, will be ignored.
 * Properties, that exist in both objects, but have different types will also
 * be ignored.
 *
 * @example
 * ```ts
 * type A = { a: string; b: number; c?: symbol; d: unknown };
 * type B = { a: string; b: string; c?: symbol; f: unknown };
 * ExtractOverlap<A, B> // -> { a: string; c?: symbol }
 * ```
 */
export type ExtractOverlap<A, B> = UndefinedToOptional<
	ExcludeNeverValues<{
		[K in SharedKeys<A, B>]: Equals<A[K], B[K]> extends true ? A[K] : never;
	}>
>;

/**
 * Get the prop definitions of an HTML element.
 * @example
 * ```tsx
 * type InputProps = HTMLProps<HTMLInputElement>;
 *
 * const defaultInputProps: InputProps = {
 *   type: "text",
 *   placeholder: "Type something...",
 * };
 *
 * const input = <input {...defaultInputProps} />;
 * ```
 */
export type HTMLProps<Element extends HTMLElement> = DetailedHTMLProps<
	FormHTMLAttributes<Element>,
	Element
>;

export type WithOptional<BaseType, OptionalKeys extends keyof BaseType> = Omit<
	BaseType,
	OptionalKeys
> &
	Partial<Pick<BaseType, OptionalKeys>>;

export type WithRequired<BaseType, RequiredKeys extends keyof BaseType> = Omit<
	BaseType,
	RequiredKeys
> &
	Required<Pick<BaseType, RequiredKeys>>;

export type Falsy = "" | 0 | -0 | 0n | null | undefined | false;

/**
 * Turn all `readonly` properties of a type into mutable properties
 */
export type Mutable<T> = { -readonly [K in keyof T]: T[K] };
/**
 * Helper function to turn all `readonly` properties of a type into mutable
 * properties. It returns the same object that is passed in, but with the
 * `Mutable` type applied to it.
 */
export function toMutable<T>(readonlyValue: T): Mutable<T> {
	return readonlyValue as Mutable<T>;
}

export type ToUnion<T extends unknown[]> = T[number];

export type UnionToIntersection<T> =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(T extends any ? (x: T) => any : never) extends (x: infer R) => any
		? R
		: never;

/**
 * A general type to help with inference of `as` or `component` props. Matches
 * component functions or classes as well as tag names such as `span` or `nav`,
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyComponent = keyof JSX.IntrinsicElements | ComponentType<any>;

/**
 * Get the prop type of any component or element.
 *
 * @example
 * ```tsx
 * const MyComp = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
 * type MyCompProps = PropsOf<typeof MyComp>; // => { children: React.ReactNode };
 * type DivProps = PropsOf<"button">; // => { type: "button" | "reset" | "submit"; className?: string; ... }
 * ```
 */
export type PropsOf<ComponentT extends AnyComponent> =
	ComponentT extends ComponentType<infer AsProps>
		? AsProps
		: ComponentT extends keyof JSX.IntrinsicElements
		? JSX.IntrinsicElements[ComponentT]
		: never;

/** Helper type to mark that a type may also be null  */
export type Nullable<T> = T | null;
/** Helper type to mark that a type may also be null or undefined  */
export type Nullish<T> = T | null | undefined;
/** Helper type to mark that a type may also be undefined  */
export type Optional<T> = T | undefined;
