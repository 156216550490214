import { memoize } from "@xoev/memo";
import type { LiteDatatypeEntry } from "../../../../../AppActor/actors/modellierungModel/selectors";
import { useModellierungDatatypesAndGlobals } from "../../../../../AppActor/actors/modellierungModel/hooks";
import type {
	LiteNode,
	LiteNodeKey,
} from "../../../../../../lib/validation/lite/LiteSchemas";
import {
	isLiteBaustein,
	isLiteEigenschaft,
} from "../../../../../../lib/validation/lite/TypeGuards";
import { LiteBausteinType } from "../../../../../../lib/validation/lite/LiteEnums";

export type RefererKeys = "basisDatentyp" | "datentyp" | "referenz";

export type Referer = {
	clearText: string;
	key: LiteNodeKey;
	placeholder: string;
	label: string;
	fallback: string;
};
export type Referers = {
	[key in RefererKeys]: Referer;
};

export const getReferersConfiguration = <K extends RefererKeys>(
	key: RefererKeys = "datentyp",
): Referers[K] => {
	const referer: Referers = {
		basisDatentyp: {
			clearText: "Basisdatentyp entfernen",
			key: "basisDatentyp",
			placeholder: "kein Basisdatentyp",
			label: "Basisdatentyp",
			fallback: "kein Basisdatentyp",
		},
		referenz: {
			clearText: "Globale Eigenschaft entfernen",
			key: "referenz",
			placeholder: "keine Globale Eigenschaft",
			label: "Globale Eigenschaft",
			fallback: "Anonymer Datentyp",
		},
		datentyp: {
			clearText: "Datentyp oder globales Element entfernen",
			key: "datentyp",
			placeholder: "kein Datentyp oder globales Element",
			label: "Datentyp oder globales Element",
			fallback: "Anonymer Datentyp",
		},
	};

	return {
		...referer[key],
	};
};

const createDatatypeCountMap = memoize((datatypes: LiteDatatypeEntry[]) => {
	const countMap = new Map<string, number>();
	for (const dt of datatypes) {
		countMap.set(dt.name, (countMap.get(dt.name) ?? 0) + 1);
	}
	return countMap;
});

export function getDuplicateLabel(
	datatypes: LiteDatatypeEntry[],
	option: LiteDatatypeEntry,
) {
	const countMap = createDatatypeCountMap(datatypes);
	const isDuplicate = (countMap.get(option.name) ?? 0) > 1;
	return isDuplicate ? ` (${option.parentPath})` : "";
}

export function getOptionLabel(
	datatypes: LiteDatatypeEntry[],
	option: LiteDatatypeEntry,
) {
	return `${option.name}${getDuplicateLabel(datatypes, option)}`;
}

/**
 * Searches all Datatypes/Globale Eigenschaften for one of the possible referencing ID
 * either datentyp,basisDatentyp or referenz
 * it returns the Entry that it finds and the key of the datentyp
 * that is set in the active node
 *
 * @export
 * @param {(LiteBaustein | LiteEigenschaft)} node the active node
 * @returns {{ currentReferer: any; currentKey: RefererKeys; }} the Datatype entry that was found and the key of
 * the entry that is set in the activeNode
 */
export function useReferencingEntries(node: LiteNode) {
	let currentReferer: LiteDatatypeEntry | null = null;
	let currentKey: RefererKeys | null = null;

	const datatypes = useModellierungDatatypesAndGlobals([
		LiteBausteinType.Datentyp,
		LiteBausteinType.GlobaleEigenschaft,
		LiteBausteinType.CodeDatentyp,
	]);
	currentReferer =
		datatypes.find((dt) => {
			return (
				(isLiteBaustein(node) || isLiteEigenschaft(node)) &&
				[node.datentyp, node.basisDatentyp, node.referenz].includes(dt.id)
			);
		}) ?? null;

	if (isLiteBaustein(node) || isLiteEigenschaft(node)) {
		if (node.datentyp) {
			currentKey = "datentyp";
		}
		if (node.basisDatentyp) {
			currentKey = "basisDatentyp";
		}
		if (node.referenz) {
			currentKey = "referenz";
		}
	}
	return { currentReferer, currentKey };
}
