import type { KeyboardEvent } from "react";
import CodeListIcon from "../../Icons/CodeListIcon";
import RecursionIcon from "../../Icons/RecursionIcon";
import ChoiceIcon from "../../Icons/ChoiceIcon";
import ListNode from "../../../AtomicComponents/Organisms/ProfilierungListNode";
import { getMaxSeverityFromResults } from "../../ProfilierungValidation/helpers";
import { useProfilierungValidationResults } from "../../ProfilierungValidation/hooks";
import { ValidationResultGroup } from "../../ProfilierungValidation/types";
import ValidationLabel from "../../ProfilierungValidation/ValidationLabel";
import { NotADatatypeError } from "../../../utils/profilingHelpers";
import { useStateSelector } from "../../EditorState";
import { selectStandard } from "../../EditorState/selectors";
import { useAppSelector } from "../../../redux/hooks";
import {
	selectIsCodeliste,
	selectIsRekursionStart,
	selectQName,
} from "../../../redux/treeSlice";
import {
	isChoice,
	isGroupTypeAll,
} from "../../AppActor/actors/modellierungModel/helpers";
import type { LiteNode } from "../../../lib/validation/lite/LiteSchemas";
import AllIcon from "../../Icons/AllIcon";

const DatatypeNode = ({
	datatype,
	onActivate,
	isProfiled = false,
	isActive = false,
}: {
	datatype: LiteNode;
	onActivate?: (node: LiteNode) => void;
	isProfiled?: boolean;
	isActive?: boolean;
}): JSX.Element => {
	NotADatatypeError.assert(datatype);
	const { id, name } = datatype;
	const standard = useStateSelector(selectStandard());
	const qname = useAppSelector(selectQName(standard, datatype));
	const isCodeliste = useAppSelector(selectIsCodeliste(standard, datatype));
	const istRekursionsStart = useAppSelector(
		selectIsRekursionStart(standard, datatype),
	);
	const results = useProfilierungValidationResults(
		[
			ValidationResultGroup.RestrictionProfiles,
			ValidationResultGroup.Restrictions,
		],
		{ datatypeId: id },
	);
	const maxSeverity = getMaxSeverityFromResults(results);

	const handleClick = () => {
		onActivate?.(datatype);
	};

	const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
		if (["Enter", " "].includes(e.key)) {
			handleClick();
		}
	};

	return (
		<li>
			<ListNode
				role="menuitem"
				onClick={handleClick}
				data-testid="datatype-list__item"
				data-node-id={id}
				data-node-qname={qname}
				tabIndex={0}
				onKeyPress={handleKeyPress}
				isActive={isActive}
				isProfiled={isProfiled}
			>
				<ValidationLabel severity={maxSeverity}>{name}</ValidationLabel>
				<RecursionIcon isRecursive={!!istRekursionsStart} />
				{isChoice(datatype) && <ChoiceIcon />}
				{isGroupTypeAll(datatype) && <AllIcon />}

				<CodeListIcon isCodeList={isCodeliste} />
			</ListNode>
		</li>
	);
};

export default DatatypeNode;
