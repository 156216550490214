import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";

import EditFormFieldLite from "../../../components/EditFormFieldLite";
import type { Geschaeftsregel } from "../../../lib/validation/lite/LiteSchemas";
import "./GeschaeftsregelSingleEdit.scss";

import { placeholder } from "../../../resources/textConstants";
import ValidationDisplayLite from "../../../components/ValidationDisplayLite";
import type { LiteId } from "../../../lib/validation/lite/IDSchemas";
import { ValidationTargetField } from "../../../components/AppActor/actors/modellierungModel/validation/validators/types";
import SaveIcon from "../../../resources/icons/SaveIcon";

const GeschaeftsregelSingleEdits = function GeschaeftsregelSingleEdit({
	handleSave,
	handleClose,
	data,
	children,
	activePath,
	index,
}: {
	handleSave: (
		geschaeftsregel: Partial<Geschaeftsregel>,
		index: number,
	) => void;
	handleClose: () => void;
	data?: Geschaeftsregel;
	children?: React.ReactNode;
	activePath?: LiteId[];
	index: number;
}): JSX.Element {
	const [geschaeftsregel, setGeschaeftsregel] = useState<
		Partial<Geschaeftsregel>
	>({});
	useEffect(() => {
		if (data && JSON.stringify(data) !== JSON.stringify(geschaeftsregel)) {
			setGeschaeftsregel(data);
		}
	}, [data, geschaeftsregel]);

	const handleBlur = (name: string, value: string) => {
		const newRegel = { ...(geschaeftsregel || {}), [name]: value };
		if (newRegel !== data) {
			handleSave(newRegel, index);
		}
	};

	return (
		<>
			<EditFormFieldLite
				className="geschaeftsregel-small-input"
				definition={{
					name: "kennung",
					label: "Kennung",
				}}
				value={geschaeftsregel?.kennung}
				placeholder={placeholder.geschaeftsregeln.kennung}
				onBlur={handleBlur}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "kennung",
				}}
				error={
					<ValidationDisplayLite
						locator={{
							path: activePath,
							targetField: ValidationTargetField.GeschaeftsregelKennung,
						}}
					/>
				}
			/>
			<EditFormFieldLite
				className="geschaeftsregel-small-input"
				definition={{
					name: "spezifikation",
					label: "Spezifikation",
					type: "textarea",
				}}
				value={geschaeftsregel?.spezifikation}
				placeholder={placeholder.geschaeftsregeln.kennung}
				onBlur={handleBlur}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "spezifikation",
				}}
			/>
			<EditFormFieldLite
				className="geschaeftsregel-small-input"
				definition={{
					name: "beschreibung",
					label: "Beschreibung",
					type: "textarea",
				}}
				value={geschaeftsregel?.beschreibung}
				placeholder={placeholder.geschaeftsregeln.kennung}
				onBlur={handleBlur}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "beschreibung",
				}}
			/>
			<EditFormFieldLite
				className="geschaeftsregel-small-input"
				definition={{
					name: "verbindlichkeit",
					label: "Verbindlichkeit",
				}}
				value={geschaeftsregel?.verbindlichkeit}
				placeholder={placeholder.geschaeftsregeln.kennung}
				onBlur={handleBlur}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "verbindlichkeit",
				}}
			/>
			{children}
			<IconButton onClick={handleClose} data-testid="save-button">
				<SaveIcon />
			</IconButton>
		</>
	);
};

export default GeschaeftsregelSingleEdits;
