import type { ExpansionTree } from "./ExpansionState";
import type { LiteBausteinType } from "../../lib/validation/lite/LiteEnums";

export type PathFragment = number | string;

export interface NodeInfoType<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> {
	node: TNode;
	path: TNodeId[];
}

export type NodeEventArg<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> = NodeInfoType<TNode, TNodeId>;

export interface NodeContextType<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> extends NodeInfoType<TNode, TNodeId> {
	isOpen: boolean;
}

export type NodeElementProps = Record<string, unknown>;

export interface NodeRenderProps<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> {
	getProps: () => NodeElementProps;
	node: TNode;
	path: TNodeId[];
	isExpanded: boolean;
}

interface TreeActions<TNode, TNodeId extends PathFragment = number> {
	onOpen: (event: NodeEventArg<TNode, TNodeId>) => void;
	onClose: (event: NodeEventArg<TNode, TNodeId>) => void;
	onActivate: (event: NodeEventArg<TNode, TNodeId>) => void;
}

interface TreeCallbacks<TNode, TNodeId extends PathFragment = number> {
	getNodeId?: (node: TNode, index: number) => TNodeId;
	getChildren: (node: TNode, path: TNodeId[]) => TNode[];
	isNodeExpandable: (node: TNode, path: TNodeId[]) => boolean;
	renderNode: (renderProps: NodeRenderProps<TNode, TNodeId>) => JSX.Element;
}

export enum LiteBausteinFilter {
	Leer = "LEERE_PAKETE_AUSBLENDEN",
}

export type BausteinSchemaFilter = LiteBausteinType | LiteBausteinFilter;
export type BausteinSchemaFilters = BausteinSchemaFilter[];

export type TreeContextType<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> = {
	expansionState: ExpansionTree<TNodeId>;
} & TreeActions<TNode, TNodeId> &
	Required<TreeCallbacks<TNode, TNodeId>>;

export type TreeProps<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> = {
	rootNodes: TNode[];
	expansionState?: ExpansionTree<TNodeId>;
	className?: string;
	initialPath?: TNodeId[];
	activeFilters?: BausteinSchemaFilters;
} & TreeCallbacks<TNode, TNodeId> &
	Partial<TreeActions<TNode, TNodeId>>;

export interface TreeNodeProps<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> {
	node: TNode;
	path: TNodeId[];
	activeFilters?: BausteinSchemaFilters;
}

export type TreeEntry<
	TNode = unknown,
	TNodeId extends PathFragment = number,
> = [TNodeId[], TNode];
