import { z } from "zod";

/* IDs */

export const RawLiteIdSchema = z.string({ description: "LiteId" });
export type RawLiteId = z.infer<typeof RawLiteIdSchema>;
export const LiteIdSchema = RawLiteIdSchema.brand("LiteId");
export type LiteId = z.infer<typeof LiteIdSchema>;
export const OptionalLiteIdSchema = LiteIdSchema.optional();
export type OptionalLiteId = z.infer<typeof OptionalLiteIdSchema>;
export const LiteIdListSchema = LiteIdSchema.array();
export type LiteIdList = z.infer<typeof LiteIdListSchema>;
export const ProjektIdSchema = z.string().brand("ProjektId");
export type ProjektId = z.infer<typeof ProjektIdSchema>;
export const ProjektDbIdSchema = z.number().brand("ProjektDbId");
export type ProjektDbId = z.infer<typeof ProjektDbIdSchema>;
