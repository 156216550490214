import type {
	MetadatenStandard,
	MetadatenVersion,
} from "../../../../lib/validation/lite/LiteSchemas";
import {
	createFormHelpers,
	type FormHelpers,
} from "../../../../components/EditFormFieldLite";

export function createStandardMetadataFields({
	readOnly,
}: {
	readOnly: boolean;
}): FormHelpers<MetadatenStandard> {
	return createFormHelpers([
		{ name: "nameKurz", label: "Name (kurz)", isRequired: true, readOnly },
		{
			name: "nameLang",
			label: "Name (lang)",
			type: "textarea",
			isRequired: true,
			readOnly,
		},
		{
			name: "nameTechnisch",
			label: "Name (technisch)",
			isRequired: true,
			readOnly,
		},
		{ name: "kennung", label: "Kennung", isRequired: true, readOnly },
		{
			name: "beschreibung",
			label: "Beschreibung",
			type: "textarea",
			readOnly,
		},
		{
			name: "herausgebernameKurz",
			label: "Herausgeber (kurz)",
			readOnly,
		},
		{
			name: "herausgebernameLang",
			label: "Herausgeber (lang)",
			readOnly,
		},
		{
			name: "externeWebsite",
			label: "Externe Website",
			readOnly,
		},
	]);
}

export function createVersionMetadataFields({
	readOnly,
}: {
	readOnly: boolean;
}): FormHelpers<MetadatenVersion> {
	return createFormHelpers([
		{ name: "version", label: "Version", isRequired: true, readOnly },
		{
			name: "versionXoevHandbuch",
			label: "XÖV-Handbuch",
			readOnly,
		},
		{
			name: "versionXGenerator",
			label: "X-Generator",
			readOnly,
		},
		{
			name: "versionModellierungswerkzeug",
			label: "Modellierungswerkzeug",
			readOnly,
		},
		{ name: "lizenz", label: "Lizenz", readOnly },
		{
			name: "aenderungZurVorversion",
			label: "Änderung zur Vorversion",
			readOnly,
		},
		{ name: "bezugsort", label: "Bezugsort", readOnly },
		{ name: "beschreibung", label: "Beschreibung", readOnly },
	]);
}
