import type { MetadataMap } from "../../EditorState/types";
import metadataRules from "../rules/metadata/metadataRules";
import type {
	MetadataRule,
	MetadataValidationContext,
	MetadataValidationResult,
	ValidationTargetFieldProfiling,
	Validator,
} from "../types";
import { ValidationResultGroup, Severity } from "../types";

function createMetadataValidationResult(
	failedRule: MetadataRule,
	validationContext: MetadataValidationContext,
): MetadataValidationResult<ValidationTargetFieldProfiling> {
	const validationMsg =
		typeof failedRule.message === "function"
			? failedRule.message(validationContext)
			: failedRule.message;
	// Create an id that uniquely identifies the result
	const resultId = failedRule.id;
	return {
		targetField: failedRule.targetField,
		type: ValidationResultGroup.Metadata,
		id: resultId,
		// By default we'll assume that the severity is an error
		severity: failedRule.severity || Severity.Error,
		message: validationMsg,
		ruleId: failedRule.id,
		elementName: "Metadaten",
		elementNamePath: ["Metadaten"],
		autoFix: failedRule.autoFix,
		autoFixDescription: failedRule.autoFixDescription,
		target: failedRule.target,
	};
}

/**
 * Validate the projects metadata entered by the user
 *
 * @param metadata The metadata map
 */
export default async function* validateMetadata(
	metadata: MetadataMap,
): Validator<MetadataValidationResult<ValidationTargetFieldProfiling>> {
	for (const rule of metadataRules) {
		// Skip the validation if the map does not contain at least one of
		// the target keys
		if (rule.target && !rule.target.some((key) => metadata.has(key))) {
			continue;
		}
		const context: MetadataValidationContext = { metadata };
		if (!rule.isValid(context)) {
			// Yield the validation result here, so we can display it to the user
			yield createMetadataValidationResult(rule, context);
		} else {
			// Yield nothing here, so we know that we've advanced the validation by
			// one check
			yield null;
		}
	}
}
