import type { SyntheticEvent } from "react";
import { useState } from "react";
import { useProfilierungValidationResult } from "../../../../../../AtomicComponents/Organisms/ProfilierungValidationDisplay";
import type { TreeNodeType } from "../../../../../../types/ProfilierungHome";
import useSectionDependentProfile from "../../../../../../hooks/useSectionDependentProfile";
import { ValidationTargetFieldProfiling } from "../../../../../ProfilierungValidation/types";
import type { MultiValueField, FieldRendererDefinition } from "../../types";
import { subSelectConfigurationField } from "../../../../../EditorState/subSelectors";
import { usePresetValues } from "../../fieldRendererHelpers";
import type { LiteId } from "../../../../../../lib/validation/lite/IDSchemas";

const multiValueFields = ["beispielWertNachricht"];

const targetMapping = {
	beispielWertNachricht: ValidationTargetFieldProfiling.ExampleValueMessage,
} satisfies { [K in MultiValueField]: ValidationTargetFieldProfiling };

function getValidationTarget(
	fieldName: MultiValueField,
): ValidationTargetFieldProfiling {
	return targetMapping[fieldName];
}

function getMultiValueFieldName(fieldName: string): MultiValueField {
	if (!multiValueFields.includes(fieldName)) {
		throw new Error(
			`The field ${fieldName} is not a Multivalue-Field, please contact the Administrator if the problem persists`,
		);
	}

	return fieldName as MultiValueField;
}

export default function useMultiValueRenderer(
	activeNode: TreeNodeType,
	activePath: LiteId[],
	definition: FieldRendererDefinition,
) {
	const fieldName = getMultiValueFieldName(definition.name);
	const { profile, setProfile } = useSectionDependentProfile(activePath);
	const values =
		(subSelectConfigurationField(profile, fieldName) as string[]) || [];
	const [inputValue, setInputValue] = useState("");

	const isAddDisabled = !inputValue || values?.includes(inputValue);

	const { fixedWert } = usePresetValues(activeNode);
	const isReadOnly =
		definition.readOnly ||
		!!fixedWert ||
		!!profile?.get("konfiguration")?.get("fixedWert");

	const handleInputChange = (event: SyntheticEvent, newInputValue: string) => {
		setInputValue(newInputValue);
	};

	const handleAdd = () => {
		if (
			!isAddDisabled &&
			inputValue &&
			inputValue.length &&
			!values?.includes(inputValue)
		) {
			const nextProfile = profile.setIn(
				["konfiguration", fieldName],
				(values ?? []).concat(inputValue),
			);
			setProfile(nextProfile);
			setInputValue("");
		}
	};

	const createHandleDelete = (valueToDelete: string) => () => {
		const nextProfile = profile.setIn(
			["konfiguration", fieldName],
			values?.filter((value) => value !== valueToDelete),
		);
		setProfile(nextProfile);
	};

	const validationResults = useProfilierungValidationResult({
		nodeId: activeNode.id,
		targetField: getValidationTarget(fieldName),
	});

	return {
		values,
		inputValue,
		isAddDisabled,
		validationResults,
		isReadOnly,
		handleInputChange,
		handleAdd,
		createHandleDelete,
	};
}
