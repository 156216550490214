import { useState } from "react";
import { Link } from "react-router-dom";
import type { MouseEvent } from "react";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./Keycloak.module.scss";

export type LoggedInMenuItem = {
	link: string;
	title: string;
	value: string;
	onClick?: () => void;
};

const LoggedInMenu = ({
	menuItems,
	loggedInLabel,
}: {
	menuItems: LoggedInMenuItem[];
	loggedInLabel: string;
}): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				aria-controls={open ? "loggedin-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				className={styles["xoev-loggedin-button"]}
				id="loggedin-menu__button"
				onClick={handleClick}
				startIcon={<PersonIcon className="loggedin-menu__button__icon" />}
				data-testid="loggedin-button"
			>
				{loggedInLabel}
			</Button>
			<Menu
				anchorEl={anchorEl}
				id="loggedin-menu"
				MenuListProps={{
					"aria-labelledby": "loggedin-menu__button",
				}}
				onClose={handleClose}
				open={open}
			>
				{menuItems.map((menuItem) => (
					<MenuItem
						key={`loggedin-menu__menuItem--${menuItem.value
							.replaceAll(" ", "-")
							.toLowerCase()}`}
						{...(menuItem.link ? { component: Link, to: menuItem.link } : {})}
						{...(menuItem.onClick ? { onClick: menuItem.onClick } : {})}
					>
						{menuItem.value}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default LoggedInMenu;
