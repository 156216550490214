import { useCallback } from "react";
import type { StateProfileMap } from "../components/EditorState/types";
import useIdsAffectedByProfile from "../hooks/useIdsAffectedByProfile";
import { SerializableError } from "./error";
import type {
	LiteBaustein,
	LiteNode,
} from "../lib/validation/lite/LiteSchemas";
import type { LiteId } from "../lib/validation/lite/IDSchemas";
import { isLiteDatatype } from "../lib/validation/lite/TypeGuards";
import type { Nullish } from "./types";

export function useIsDescendantProfiled(
	profiles: StateProfileMap | undefined,
): (nodeId: LiteId) => boolean {
	const affectedIds = useIdsAffectedByProfile(profiles);
	const isDescendantProfiled = useCallback(
		(nodeId) => affectedIds.has(nodeId),
		[affectedIds],
	);

	return isDescendantProfiled;
}

export class NotADatatypeError extends SerializableError {
	constructor(datatype: Nullish<LiteNode>) {
		super({
			name: "NotADatatypeError",
			message:
				`Expected node "${datatype?.name}" id: "${datatype?.id}" ` +
				`to be a datatype.\n\n${JSON.stringify(datatype, null, 2)}`,
		});
	}

	static assert(datatype: Nullish<LiteNode>): asserts datatype is LiteBaustein {
		if (!datatype || !isLiteDatatype(datatype)) {
			throw new NotADatatypeError(datatype);
		}
	}
}
