import React from "react";
import { Fade, createTheme, ThemeProvider } from "@mui/material";
import { createRawVar, defaultTheme } from "@xoev/theme";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
const deDE$1 = {
  components: {
    MuiBreadcrumbs: {
      defaultProps: {
        expandText: "Pfad anzeigen"
      }
    },
    MuiTablePagination: {
      defaultProps: {
        getItemAriaLabel: (type) => {
          if (type === "first") {
            return "Zur ersten Seite";
          }
          if (type === "last") {
            return "Zur letzten Seite";
          }
          if (type === "next") {
            return "Zur nächsten Seite";
          }
          return "Zur vorherigen Seite";
        },
        labelRowsPerPage: "Zeilen pro Seite:",
        labelDisplayedRows: ({
          from,
          to,
          count
        }) => `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`
      }
    },
    MuiRating: {
      defaultProps: {
        getLabelText: (value) => `${value} ${value !== 1 ? "Sterne" : "Stern"}`,
        emptyLabelText: "Keine Wertung"
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        clearText: "Leeren",
        closeText: "Schließen",
        loadingText: "Wird geladen…",
        noOptionsText: "Keine Optionen",
        openText: "Öffnen"
      }
    },
    MuiAlert: {
      defaultProps: {
        closeText: "Schließen"
      }
    },
    MuiPagination: {
      defaultProps: {
        "aria-label": "Navigation via Seitennummerierung",
        getItemAriaLabel: (type, page, selected) => {
          if (type === "page") {
            return `${selected ? "" : "Gehe zu "}Seite ${page}`;
          }
          if (type === "first") {
            return "Zur ersten Seite";
          }
          if (type === "last") {
            return "Zur letzten Seite";
          }
          if (type === "next") {
            return "Zur nächsten Seite";
          }
          return "Zur vorherigen Seite";
        }
      }
    }
  }
};
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
const getGridLocalization = (gridTranslations, coreTranslations) => {
  var _coreTranslations$com, _coreTranslations$com2;
  return {
    components: {
      MuiDataGrid: {
        defaultProps: {
          localeText: _extends({}, gridTranslations, {
            MuiTablePagination: (coreTranslations == null ? void 0 : (_coreTranslations$com = coreTranslations.components) == null ? void 0 : (_coreTranslations$com2 = _coreTranslations$com.MuiTablePagination) == null ? void 0 : _coreTranslations$com2.defaultProps) || {}
          })
        }
      }
    }
  };
};
const deDEGrid = {
  // Root
  noRowsLabel: "Keine Einträge",
  noResultsOverlayLabel: "Keine Ergebnisse gefunden.",
  errorOverlayDefaultLabel: "Ein unerwarteter Fehler ist aufgetreten.",
  // Density selector toolbar button text
  toolbarDensity: "Zeilenhöhe",
  toolbarDensityLabel: "Zeilenhöhe",
  toolbarDensityCompact: "Kompakt",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Breit",
  // Columns selector toolbar button text
  toolbarColumns: "Spalten",
  toolbarColumnsLabel: "Zeige Spaltenauswahl",
  // Filters toolbar button text
  toolbarFilters: "Filter",
  toolbarFiltersLabel: "Zeige Filter",
  toolbarFiltersTooltipHide: "Verberge Filter",
  toolbarFiltersTooltipShow: "Zeige Filter",
  toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} aktive Filter` : `${count} aktiver Filter`,
  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "Suchen…",
  toolbarQuickFilterLabel: "Suchen",
  toolbarQuickFilterDeleteIconLabel: "Löschen",
  // Export selector toolbar button text
  toolbarExport: "Exportieren",
  toolbarExportLabel: "Exportieren",
  toolbarExportCSV: "Download als CSV",
  toolbarExportPrint: "Drucken",
  toolbarExportExcel: "Download als Excel",
  // Columns panel text
  columnsPanelTextFieldLabel: "Finde Spalte",
  columnsPanelTextFieldPlaceholder: "Spaltenüberschrift",
  columnsPanelDragIconLabel: "Spalte umsortieren",
  columnsPanelShowAllButton: "Zeige alle",
  columnsPanelHideAllButton: "Verberge alle",
  // Filter panel text
  filterPanelAddFilter: "Filter hinzufügen",
  filterPanelDeleteIconLabel: "Löschen",
  filterPanelLinkOperator: "Logische Operatoren",
  filterPanelOperators: "Operatoren",
  // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: "Und",
  filterPanelOperatorOr: "Oder",
  filterPanelColumns: "Spalten",
  filterPanelInputLabel: "Wert",
  filterPanelInputPlaceholder: "Wert filtern",
  // Filter operators text
  filterOperatorContains: "enthält",
  filterOperatorEquals: "ist gleich",
  filterOperatorStartsWith: "beginnt mit",
  filterOperatorEndsWith: "endet mit",
  filterOperatorIs: "ist",
  filterOperatorNot: "ist nicht",
  filterOperatorAfter: "ist nach",
  filterOperatorOnOrAfter: "ist am oder nach",
  filterOperatorBefore: "ist vor",
  filterOperatorOnOrBefore: "ist am oder vor",
  filterOperatorIsEmpty: "ist leer",
  filterOperatorIsNotEmpty: "ist nicht leer",
  filterOperatorIsAnyOf: "ist einer der Werte",
  // Filter values text
  filterValueAny: "Beliebig",
  filterValueTrue: "Ja",
  filterValueFalse: "Nein",
  // Column menu text
  columnMenuLabel: "Menü",
  columnMenuShowColumns: "Zeige alle Spalten",
  columnMenuFilter: "Filter",
  columnMenuHideColumn: "Verbergen",
  columnMenuUnsort: "Sortierung deaktivieren",
  columnMenuSortAsc: "Sortiere aufsteigend",
  columnMenuSortDesc: "Sortiere absteigend",
  // Column header text
  columnHeaderFiltersTooltipActive: (count) => count !== 1 ? `${count} aktive Filter` : `${count} aktiver Filter`,
  columnHeaderFiltersLabel: "Zeige Filter",
  columnHeaderSortIconLabel: "Sortieren",
  // Rows selected footer text
  footerRowSelected: (count) => count !== 1 ? `${count.toLocaleString()} Einträge ausgewählt` : `${count.toLocaleString()} Eintrag ausgewählt`,
  // Total row amount footer text
  footerTotalRows: "Gesamt:",
  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} von ${totalCount.toLocaleString()}`,
  // Checkbox selection text
  checkboxSelectionHeaderName: "Checkbox Auswahl",
  checkboxSelectionSelectAllRows: "Alle Zeilen auswählen",
  checkboxSelectionUnselectAllRows: "Alle Zeilen abwählen",
  checkboxSelectionSelectRow: "Zeile auswählen",
  checkboxSelectionUnselectRow: "Zeile abwählen",
  // Boolean cell text
  booleanCellTrueLabel: "Ja",
  booleanCellFalseLabel: "Nein",
  // Actions cell more text
  actionsCellMore: "Mehr",
  // Column pinning text
  pinToLeft: "Links anheften",
  pinToRight: "Rechts anheften",
  unpin: "Loslösen",
  // Tree Data
  treeDataGroupingHeaderName: "Gruppe",
  treeDataExpand: "Kinder einblenden",
  treeDataCollapse: "Kinder ausblenden",
  // Grouping columns
  groupingColumnHeaderName: "Gruppierung",
  groupColumn: (name) => `Gruppieren nach ${name}`,
  unGroupColumn: (name) => `Gruppierung nach ${name} aufheben`,
  // Master/detail
  detailPanelToggle: "Detailansicht Kippschalter",
  expandDetailPanel: "Aufklappen",
  collapseDetailPanel: "Zuklappen",
  // Row reordering text
  rowReorderingHeaderName: "Reihen neu ordnen",
  // Aggregation
  aggregationMenuItemHeader: "Aggregation",
  aggregationFunctionLabelSum: "Summe",
  aggregationFunctionLabelAvg: "Mittelwert",
  aggregationFunctionLabelMin: "Minimum",
  aggregationFunctionLabelMax: "Maximum",
  aggregationFunctionLabelSize: "Anzahl"
};
const deDE = getGridLocalization(deDEGrid, deDE$1);
const Transition = React.forwardRef(function Transition2(props, ref) {
  return /* @__PURE__ */ React.createElement(Fade, { ref, ...props });
});
const Transition$1 = Transition;
function OpenSelectIcon(props) {
  return /* @__PURE__ */ React.createElement(
    UnfoldMoreIcon,
    {
      ...props,
      sx: { fontSize: "1rem" },
      "data-testid": "custom-select-icon"
    }
  );
}
const rawVar = createRawVar(defaultTheme);
const theme = createTheme(
  {
    palette: {
      background: {
        default: rawVar("surface-1"),
        paper: rawVar("surface-0")
      },
      common: {
        black: rawVar("gray-9"),
        white: rawVar("gray-0")
      },
      text: {
        disabled: rawVar("text-ghost"),
        primary: rawVar("text-main"),
        secondary: rawVar("text-faded")
      },
      error: {
        main: rawVar("text-error")
      },
      warning: {
        main: rawVar("text-warning")
      },
      info: {
        main: rawVar("text-info")
      },
      success: {
        main: rawVar("text-success")
      },
      divider: rawVar("gray-9"),
      primary: {
        main: rawVar("surface-accent-3"),
        light: rawVar("surface-accent-2"),
        dark: rawVar("surface-accent-4"),
        contrastText: rawVar("text-inverse-main")
      },
      secondary: {
        main: rawVar("surface-accent-1"),
        light: rawVar("surface-accent-0"),
        dark: rawVar("surface-accent-2"),
        contrastText: rawVar("text-accent-faded")
      }
    },
    typography: {
      fontFamily: "var(--font-main)",
      // MUI does some math on the font size, which results in 14px font size
      // actually being 16px, which is the default browser font size anyways
      fontSize: 14,
      fontWeightBold: "var(--font-bold)",
      fontWeightMedium: "var(--font-regular)",
      fontWeightRegular: "var(--font-regular)",
      fontWeightLight: "var(--font-light)"
    },
    shape: {
      borderRadius: 2
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: "var(--background-button-disabled)"
            }
          }
        }
      },
      MuiTooltip: {
        defaultProps: { arrow: true },
        styleOverrides: {
          arrow: {
            color: "var(--background-popup)"
          },
          tooltip: {
            backgroundColor: "var(--background-popup)",
            color: "var(--text-popup)",
            fontWeight: "var(--font-regular)",
            fontSize: "var(--font-size-tiny)",
            boxShadow: "var(--shadow-1), var(--shadow-3)",
            padding: "0.5em 1em"
          }
        }
      },
      MuiInputBase: {
        defaultProps: { size: "small" },
        styleOverrides: {
          input: {
            color: "var(--text-main)",
            backgroundColor: "var(--background-input)",
            "&:disabled": {
              color: "var(--text-main)",
              fontStyle: "italic",
              WebkitTextFillColor: "var(--text-main)",
              backgroundColor: "var(--background-input-disabled)",
              opacity: 1,
              "&::placeholder": {
                WebkitTextFillColor: "var(--text-faded)",
                color: "var(--text-faded)",
                opacity: 1
              }
            }
          },
          multiline: {
            backgroundColor: "var(--background-input)",
            "&:disabled": {
              backgroundColor: "var(--background-input-disabled)"
            }
          }
        }
      },
      MuiInput: { defaultProps: { size: "small" } },
      MuiSelect: {
        defaultProps: { size: "small", IconComponent: OpenSelectIcon }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "var(--border-size-input) solid var(--color-border)",
              borderColor: "var(--color-border)",
              transition: "var(--quick-color-transition)"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderWidth: "var(--border-size-input)",
              borderColor: "var(--color-border-hover)"
            },
            "&:focus-within .MuiOutlinedInput-notchedOutline": {
              borderWidth: "var(--border-size-input)",
              borderColor: "var(--color-border-accent)"
            },
            "&:focus-within:hover .MuiOutlinedInput-notchedOutline": {
              borderWidth: "var(--border-size-input)",
              borderColor: "var(--color-border-accent-hover)"
            }
          }
        }
      },
      MuiTextField: { defaultProps: { size: "small" } },
      MuiDialog: {
        defaultProps: {
          TransitionComponent: Transition$1,
          fullWidth: true,
          maxWidth: "md"
        }
      },
      MuiSnackbar: {
        defaultProps: {
          anchorOrigin: { vertical: "top", horizontal: "center" }
        }
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border: "var(--border-size-thin) solid var(--divider)",
            borderRadius: "var(--border-radius-medium)",
            boxShadow: "var(--shadow-main)"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            background: "var(--table-head-cell-background, var(--surface-table-landmark))",
            fontWeight: "var(--font-semi-bold)"
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: "1.5rem",
            fontWeight: "var(--font-bold)"
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: { root: { color: "var(--text-main)" } }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            boxShadow: "var(--shadow-main)"
          },
          action: {
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            paddingTop: 0
          },
          icon: {
            flexShrink: 0,
            display: "flex",
            alignItems: "center"
          },
          message: {
            flexShrink: 1
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            backgroundColor: "var(--background-input)",
            "&.Mui-disabled": {
              backgroundColor: "var(--background-input-disabled)"
            }
          }
        }
      }
    }
  },
  deDE,
  deDE$1
);
const theme$1 = theme;
function MuiThemeProvider({ children }) {
  return /* @__PURE__ */ React.createElement(ThemeProvider, { theme: theme$1 }, children);
}
export {
  MuiThemeProvider,
  theme$1 as theme
};
