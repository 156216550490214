import type { SyntheticEvent } from "react";
import { useContext, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import classNames from "classnames";
import type ProfilingMetadataValues from "../../../types/ProflierungMetadataValues";
import type { FieldDefinition } from "../../../components/EditFormField";
import MetadataFormContext from "../ProfilierungMetadataForm/ProfilierungMetadataFormContext";
import { useHtmlId } from "../../../hooks";
import EditFormFieldLabel from "../../../components/EditFormField/EditFormFieldLabel";
import "./StandardSelect.scss";

const StandardSelect = ({
	definition,
}: {
	definition: FieldDefinition<ProfilingMetadataValues>;
}): JSX.Element => {
	const [inputValue, setInputValue] = useState("");
	const { disabled, handleChange, handleWrite, values } =
		useContext(MetadataFormContext);
	const { options, isRequired } = definition;
	const value = options?.find(
		(option) => option.value === values.get(definition.name),
	);
	const inputId = useHtmlId();

	const handleInputValueChange = (event: SyntheticEvent, newValue: string) => {
		setInputValue(newValue);
	};

	const handleValueChange = (
		event: SyntheticEvent,
		newValue: { value: string; label: string } | null,
	) => {
		if (newValue?.value) {
			handleChange({
				target: { name: definition.name, value: newValue.value },
			});
			handleWrite(definition.name, newValue?.value);
		}
	};

	return (
		<EditFormFieldLabel
			text={definition.label}
			htmlFor={inputId}
			isRequired={isRequired ?? false}
		>
			<Autocomplete
				id={inputId}
				options={definition.options || []}
				disabled={disabled || definition.readOnly}
				value={value || null}
				inputValue={inputValue}
				onChange={handleValueChange}
				onInputChange={handleInputValueChange}
				renderOption={(props, option) => (
					<li
						{...props}
						data-testid="standard-select-option"
						data-standard-value={option.value}
					>
						{option.label}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Standard auswählen"
						inputProps={{
							...params.inputProps,
							className: classNames(
								"standard-select__input",
								params.inputProps.className,
							),
							"data-testid": "metadata-field",
							"data-is-visible-field": definition.isHidden ? "false" : "true",
							"data-field-name": definition.name,
						}}
					/>
				)}
			/>
		</EditFormFieldLabel>
	);
};

export default StandardSelect;
