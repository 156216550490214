import { useEffect } from "react";
import { z } from "zod";
import type { AnyZodObject } from "zod";
import type { ImmutableMap } from "@xoev/immutable-map";
import type { FieldDefinition } from "../EditFormFieldLite";
import { ValidationTargetField } from "../AppActor/actors/modellierungModel/validation/validators/types";
import EditFormFieldLite from "../EditFormFieldLite";
import ValidationDisplayLite from "../ValidationDisplayLite";
import { konfigurationDropdownFields } from "./dropdownFields";
import { checkBooleanStringValue, isValidUrl } from "./helper";
import "./EditForm.scss";

type EditFormProps<TSchema extends AnyZodObject> = {
	schema: TSchema;
	values: ImmutableMap<z.infer<TSchema>>;
	fields: FieldDefinition<z.infer<TSchema>>[];
	onWrite: <K extends keyof z.infer<TSchema>>(
		name: K,
		value: z.infer<TSchema>[K],
	) => void;
};
const ValidationTargetFieldSchema = z.nativeEnum(ValidationTargetField);

export default function EditForm<TSchema extends AnyZodObject>({
	fields,
	onWrite,
	schema,
	values,
}: Readonly<EditFormProps<TSchema>>) {
	const handleWrite = (name: string, value: string) => {
		const key = schema.keyof().parse(name);
		onWrite(key, schema.shape[key].parse(checkBooleanStringValue(value)));
	};

	// TODO: Function for setting default values on the configuration tab when loading needs to be improved
	// It is necessary to wait for the ValidationWorker,
	// window.onload is used to load default values
	// when the configuration tab page is reloaded.
	useEffect(() => {
		window.onload = () => {
			konfigurationDropdownFields.forEach((dropdownfield) => {
				const field = document.querySelector(
					`[data-field-name="${dropdownfield.name}"] span`,
				);

				if (field && dropdownfield.default) {
					handleWrite(dropdownfield.name, dropdownfield.default);
				}
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<form className="edit-form">
			{fields.map((fieldDefinition) => (
				<div key={fieldDefinition.name.toString()}>
					{typeof values.get(fieldDefinition.name) === "string" &&
					isValidUrl(values.get(fieldDefinition.name) || "") &&
					fieldDefinition.readOnly ? (
						<div className="edit-form__input--linked">
							<a
								href={values.get(fieldDefinition.name)}
								rel="noreferrer"
								target="_blank"
							>
								{values.get(fieldDefinition.name)}
							</a>
						</div>
					) : (
						<>
							{konfigurationDropdownFields.some(
								(field) => field.name === fieldDefinition.name,
							) ? (
								konfigurationDropdownFields
									.filter((field) => field.name === fieldDefinition.name)
									.map((field) => (
										<EditFormFieldLite
											key={field.name}
											definition={{
												...fieldDefinition,
												name: fieldDefinition.name.toString(),
												readOnly: fieldDefinition.readOnly ?? false,
												type: "select",
												options: field.options,
											}}
											onChange={(e) =>
												e.target.name &&
												handleWrite(e.target.name, e.target.value)
											}
											value={values.get(fieldDefinition.name) || field.default}
											inputProps={{
												"data-is-visible-field": fieldDefinition.isHidden
													? "false"
													: "true",
												"data-field-name": fieldDefinition.name,
											}}
											error={
												fieldDefinition.name && (
													<ValidationDisplayLite
														locator={{
															targetField: ValidationTargetFieldSchema.parse(
																fieldDefinition.name,
															),
														}}
													/>
												)
											}
										/>
									))
							) : (
								<EditFormFieldLite
									definition={{
										...fieldDefinition,
										name: fieldDefinition.name.toString(),
										readOnly: fieldDefinition.readOnly ?? false,
									}}
									value={values.get(fieldDefinition.name) ?? ""}
									onBlur={handleWrite}
									inputProps={{
										"data-is-visible-field": fieldDefinition.isHidden
											? "false"
											: "true",
										"data-field-name": fieldDefinition.name,
									}}
									error={
										fieldDefinition.name && (
											<ValidationDisplayLite
												locator={{
													targetField: ValidationTargetFieldSchema.parse(
														fieldDefinition.name,
													),
												}}
											/>
										)
									}
								/>
							)}
						</>
					)}
				</div>
			))}
		</form>
	);
}
