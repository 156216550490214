import { z } from "zod";
import type ValueOf from "../../../types/ValueOf";
import { opaquePassthrough } from "../../../utils/schemas";
import {
	LiteGruppeTypeSchema,
	LiteBausteinTypeSchema,
	LitePaketTypeSchema,
	LiteNodeType,
} from "./LiteEnums";
import { RawLiteIdSchema, LiteIdSchema } from "./IDSchemas";

export const QNameSchema = z
	.string()
	.refine((str) => !str.includes("/"), {
		message: "QName must not include '/' characters",
	})
	.brand("QName");
export type QName = z.infer<typeof QNameSchema>;
export const QNamePathSchema = z.string().brand("QNamePath");
export type QNamePath = z.infer<typeof QNamePathSchema>;

export const LitePathSchema = z.string().brand("LitePath");
/** A list of `LiteId`s seperated by `"/"` */
export type LitePath = z.infer<typeof LitePathSchema>;
export const LiteMultiplicitySchema = z.string().regex(/(\d+|\*)\.\.(\d+|\*)/);
export type LiteMultiplicity = z.infer<typeof LiteMultiplicitySchema>;

export const GeschaeftsregelSchema = z.object({
	kennung: z.string(),
	spezifikation: z.string().optional(),
	beschreibung: z.string().optional(),
	verbindlichkeit: z.string().optional(),
});
export type Geschaeftsregel = z.infer<typeof GeschaeftsregelSchema>;
export const GeschaeftsregelnSchema = z.array(GeschaeftsregelSchema);
export type Geschaeftsregeln = z.infer<typeof GeschaeftsregelnSchema>;

export const RawLiteNodeBaseSchema = opaquePassthrough(
	z.object({
		id: RawLiteIdSchema,
		name: z.string(),
		beschreibung: z.string().optional(),
		umsetzungshinweis: z.string().optional(),
		gruppeArt: LiteGruppeTypeSchema.optional(),
		/* TODO: MOVE ME down and define optional */
		geschaeftsregeln: GeschaeftsregelnSchema.optional(),
	}),
);

export const RawLiteEigenschaftBaseSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		typ: z.literal("EIGENSCHAFT"),
		name: z.string().optional(),
		datentyp: RawLiteIdSchema.nullish(),
		attribut: z.boolean(),
		multiplizitaet: LiteMultiplicitySchema.optional(),
		fixedWert: z.string().optional(),
		defaultWert: z.string().optional(),
	}),
);

export type RawLiteEigenschaft = z.infer<
	typeof RawLiteEigenschaftBaseSchema
> & {
	eigenschaften?: RawLiteEigenschaft[];
};

export const RawLiteEigenschaftSchema: z.ZodType<RawLiteEigenschaft> =
	opaquePassthrough(
		RawLiteEigenschaftBaseSchema.extend({
			eigenschaften: z
				.lazy(() => RawLiteEigenschaftSchema)
				.array()
				.optional(),
		}),
	);

export const ValidationDefinitionSchema = opaquePassthrough(
	z.object({
		pattern: z.string().optional(),
		minValue: z.string().optional(),
		maxValue: z.string().optional(),
		fehlermeldung: z.string().optional(),
	}),
);
export type ValidationDefinition = z.infer<typeof ValidationDefinitionSchema>;

export const RawLiteBausteinSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		typ: LiteBausteinTypeSchema,
		eigenschaften: RawLiteEigenschaftSchema.array().optional(),
		kennung: z.string().optional(),
		version: z.string().optional(),
		validierung: ValidationDefinitionSchema.optional(),
		restriction: z.boolean().optional(),
		basisDatentyp: RawLiteIdSchema.nullish(),
		datentyp: RawLiteIdSchema.nullish(),
	}),
);
export type RawLiteBaustein = z.infer<typeof RawLiteBausteinSchema>;

export const SchemaDefSchema = opaquePassthrough(
	z.object({
		namespace: z.string(),
		prefix: z.string(),
	}),
);
export type SchemaDef = z.infer<typeof SchemaDefSchema>;

export const SchemaPaketKonfigurationSchema = opaquePassthrough(
	z.object({
		nutztSchema: SchemaDefSchema.array().optional(),
		name: z.string().optional(),
		prefix: z.string().optional(),
		schemaFile: z.string().optional(),
		schemaLocation: z.string().optional(),
		version: z.string().optional(),
		namespace: z.string().optional(),
		elementFormDefault: z.string().optional(),
	}),
);
export type SchemaPaketKonfiguration = z.infer<
	typeof SchemaPaketKonfigurationSchema
>;

const RawLitePaketBaseSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		typ: LitePaketTypeSchema,
		bausteine: RawLiteBausteinSchema.array(),
		konfiguration: SchemaPaketKonfigurationSchema.optional(),
	}),
);

export type RawLitePaket = z.infer<typeof RawLitePaketBaseSchema> & {
	pakete: RawLitePaket[];
};

export const RawLitePaketSchema: z.ZodType<RawLitePaket> = opaquePassthrough(
	RawLitePaketBaseSchema.extend({
		pakete: z.lazy(() => RawLitePaketSchema).array(),
	}),
);

export const MetadatenStandardSchema = opaquePassthrough(
	z.object({
		nameLang: z.string(),
		nameKurz: z.string(),
		nameTechnisch: z.string(),
		kennung: z.string(),
		beschreibung: z.string().optional(),
		herausgebernameLang: z.string().optional(),
		herausgebernameKurz: z.string().optional(),
		externeWebsite: z.string().optional(),
	}),
);
export type MetadatenStandard = z.infer<typeof MetadatenStandardSchema>;

export const MetadatenVersionSchema = opaquePassthrough(
	z.object({
		version: z.string(),
		versionXoevHandbuch: z.string().optional(),
		versionXGenerator: z.string().optional(),
		versionModellierungswerkzeug: z.string().optional(),
		lizenz: z.string().optional(),
		aenderungZurVorversion: z.string().optional(),
		bezugsort: z.string().optional(),
		beschreibung: z.string().optional(),
	}),
);
export type MetadatenVersion = z.infer<typeof MetadatenVersionSchema>;

export type Metadaten = MetadatenVersion & MetadatenStandard;

export const ModelKonfigurationSchema = opaquePassthrough(
	z.object({
		benannterCodelistenDatentyp: z.boolean().optional(),
		codesSchemavalidiert: z.boolean().optional(),
		elementFormDefault: z.string().optional(),
		fassungVom: z.string().optional(),
		namespace: z.string().optional(),
		nutzungNameElement: z.string().optional(),
		prefix: z.string().optional(),
		schemaLocationBase: z.string().optional(),
		standardeinstellungEigenschaften: z.string().optional(),
		typDesCodeElements: z.string().optional(),
		wsdlDateipraefix: z.string().optional(),
		wsdlStandardaufbauNamensraum: z.string().optional(),
		wsdlStandardaufbauSubjectOperation: z.string().optional(),
		xsdGlobalElementNamePrefix: z.string().optional(),
		xsdGlobalElementNameSuffix: z.string().optional(),
		xsdNamedTypeNamePrefix: z.string().optional(),
		xsdNamedTypeNameSuffix: z.string().optional(),
	}),
);
export type ModelKonfiguration = z.infer<typeof ModelKonfigurationSchema>;

export const RawLiteModelBaseSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		kennung: z.string(),
		pakete: RawLitePaketSchema.array(),
		metadatenStandard: MetadatenStandardSchema,
		metadatenVersion: MetadatenVersionSchema,
		konfiguration: ModelKonfigurationSchema,
	}),
);

export type RawLiteModel = z.infer<typeof RawLiteModelBaseSchema> & {
	externeModelle: RawLiteModel[];
};

export const RawLiteModelSchema: z.ZodType<RawLiteModel> = opaquePassthrough(
	RawLiteModelBaseSchema.extend({
		externeModelle: z.lazy(() => RawLiteModelSchema).array(),
	}),
);

const sharedNodeSubSchema = {
	id: LiteIdSchema,
	parent: LiteIdSchema.nullable(),
	children: LiteIdSchema.array(),
};

export const LiteEigenschaftSchema = opaquePassthrough(
	RawLiteEigenschaftBaseSchema.extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Eigenschaft),
		basisDatentyp: LiteIdSchema.nullish(),
		datentyp: LiteIdSchema.nullish(),
		referenz: LiteIdSchema.nullish(),
	}),
);
export type LiteEigenschaft = z.infer<typeof LiteEigenschaftSchema>;

export const LiteBausteinSchema = opaquePassthrough(
	RawLiteBausteinSchema.omit({
		eigenschaften: true,
	}).extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Baustein),
		basisDatentyp: LiteIdSchema.nullish(),
		datentyp: LiteIdSchema.nullish(),
		referenz: LiteIdSchema.nullish(),
	}),
);
export type LiteBaustein = z.infer<typeof LiteBausteinSchema>;

export const LitePaketSchema = opaquePassthrough(
	RawLitePaketBaseSchema.omit({
		bausteine: true,
	}).extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Paket),
	}),
);
export type LitePaket = z.infer<typeof LitePaketSchema>;

export const LiteModelSchema = opaquePassthrough(
	RawLiteModelBaseSchema.omit({
		pakete: true,
	}).extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Model),
	}),
);
export type LiteModel = z.infer<typeof LiteModelSchema>;

export type LiteNode = LiteModel | LitePaket | LiteBaustein | LiteEigenschaft;
export type LiteNodeKey = ValueOf<{
	[V in LiteNode as V["liteType"]]: keyof V;
}>;
