import { useEffect, useState } from "react";
import createImmutableMap from "@xoev/immutable-map";
import type {
	FieldRendererComponentProps,
	FieldRendererKeys,
} from "../../types";
import type { SavedCodeList } from "../../../../../../types/SavedProfileData";
import { CodeListType } from "../../../../../../types/SavedProfileData";
import useSectionDependentProfile from "../../../../../../hooks/useSectionDependentProfile";
import {
	subSelectCodelistType,
	subSelectCodelistValues,
} from "../../../../../EditorState/subSelectors";
import EditFormField from "../../../../../EditFormField";
import CodelistComboBox from "./CodeListComboBox";
import { useHtmlId } from "../../../../../../hooks";
import { useProfilierungValidationResult } from "../../../../../../AtomicComponents/Organisms/ProfilierungValidationDisplay";
import type { FixerFn } from "../../../../../ProfilierungValidation/types";
import { ValidationTargetFieldProfiling } from "../../../../../ProfilierungValidation/types";
import ValidationFormatter from "../../../../../ProfilierungValidation/ValidationFormatter";
import { runFixer } from "../../../../../ProfilierungValidation/helpers";
import DynamicInfo from "../../../../../ui/DynamicInfo";
import { useAppSelector } from "../../../../../../redux/hooks";
import { selectIsCodeliste } from "../../../../../../redux/treeSlice";
import { useStateSelector } from "../../../../../EditorState";
import { selectStandard } from "../../../../../EditorState/selectors";
import { useReferenceProfile } from "../../fieldRendererHelpers";
import "./CodeListRestrictionRenderer.scss";

const CodeListRestrictionRenderer = ({
	activeNode,
	activePath,
}: FieldRendererComponentProps<
	FieldRendererKeys,
	ValidationTargetFieldProfiling.CodelistValues
>): JSX.Element => {
	const { profile, setProfile, hasParentZeroCardinality } =
		useSectionDependentProfile(activePath);
	const referenceProfile = useReferenceProfile(profile, activeNode);

	const validationResultId = useHtmlId();
	const validationResults = useProfilierungValidationResult({
		nodeId: activeNode.id,
		targetField: ValidationTargetFieldProfiling.CodelistValues,
	});

	const referenceRestrictions = subSelectCodelistValues(referenceProfile);
	const isUsingReferenceRestrictions =
		!!referenceRestrictions && referenceRestrictions.length > 0;
	const readOnly = hasParentZeroCardinality || isUsingReferenceRestrictions;
	const profileRestrictions = subSelectCodelistValues(profile);
	const restrictions = referenceRestrictions ?? profileRestrictions ?? [];

	const initialTypeReference = subSelectCodelistType(referenceProfile);
	const initialTypeProfile = subSelectCodelistType(profile);
	const initialType =
		initialTypeReference ?? initialTypeProfile ?? CodeListType.WhiteList;
	const [localType, setLocalType] = useState(initialType);

	useEffect(() => {
		if (initialType) {
			setLocalType(initialType);
		}
	}, [initialType]);

	const updatePartialCodelist = ({
		values = restrictions,
		type = localType,
	}: {
		values?: string[];
		type?: CodeListType;
	}) => {
		let newProfile = profile || createImmutableMap();
		if (!values?.length) {
			newProfile = newProfile.deleteIn(["konfiguration", "codeliste", "werte"]);
			newProfile = newProfile.deleteIn(["konfiguration", "codeliste", "typ"]);
		} else {
			const newCodelist = createImmutableMap<Partial<SavedCodeList>>({
				typ: type,
				werte: values,
				kennung: profile.getIn(["konfiguration", "codeliste", "kennung"]),
				version: profile.getIn(["konfiguration", "codeliste", "version"]),
			});
			newProfile = newProfile.setIn(
				["konfiguration", "codeliste"],
				newCodelist,
			);
		}
		setProfile(newProfile);
	};

	const handleSelectChange = (e: { target: { value: string } }) => {
		const nextType = e.target.value as CodeListType;
		setLocalType(nextType);
		updatePartialCodelist({ type: nextType });
	};

	// The parent ProfilingEditForm only handles standard auto fixes with string
	// values. Since this is a special case, we need to run the fixing ourselves
	const handleFix = (
		fixer: FixerFn<ValidationTargetFieldProfiling.CodelistValues>,
	) => {
		runFixer(fixer, ValidationTargetFieldProfiling.CodelistValues, (values) =>
			updatePartialCodelist({ values }),
		);
	};

	const standard = useStateSelector(selectStandard());
	const isCodeliste = useAppSelector(selectIsCodeliste(standard, activeNode));
	const iconInfoKey =
		// TODO: Is this the correct check?
		// activeNode.nodeType === NodeType.CodeList
		isCodeliste ? "codeListDatatype" : "codeListElement";

	return (
		<div className="codelist-restriction">
			<h3 className="codelist-restriction__heading">
				Einschränkung der Codelistenwerte
			</h3>
			<EditFormField
				inputProps={{ "data-testid": "cl-restriction-select" }}
				definition={{
					label: (
						<>
							Art der Einschränkung <DynamicInfo infoKey={iconInfoKey} />
						</>
					),
					name: "cl-type",
					type: "select",
					readOnly,
					options: [
						{
							label: "Zulässige Codelistenwerte",
							value: CodeListType.WhiteList,
							"data-testid": "cl-restriction-option",
							"data-option": "whitelist",
						},
						{
							label: "Ausgeschlossene Codelistenwerte",
							value: CodeListType.BlackList,
							"data-testid": "cl-restriction-option",
							"data-option": "blacklist",
						},
					],
				}}
				value={localType}
				onChange={handleSelectChange}
			/>
			<div className="codelist-restriction__input-wrapper">
				<CodelistComboBox
					activeNode={activeNode}
					activePath={activePath}
					isReadOnly={readOnly}
					label={
						<>
							{localType === CodeListType.BlackList
								? "Ausgeschlossene Werte"
								: "Zulässige Werte"}
							<DynamicInfo infoKey={iconInfoKey} />
						</>
					}
					ariaDescribedBy={validationResultId}
					restrictions={restrictions}
					updatePartialCodelist={updatePartialCodelist}
				/>
			</div>
			{validationResults.length > 0 && (
				<div id={validationResultId}>
					<ValidationFormatter<ValidationTargetFieldProfiling.CodelistValues>
						results={validationResults}
						onFix={handleFix}
						value={restrictions}
					/>
				</div>
			)}
		</div>
	);
};

export default CodeListRestrictionRenderer;
