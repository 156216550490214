import { v4 as uuid } from "uuid";
import type { ActorRefFrom } from "xstate";
import { assertEvent, raise, setup } from "xstate";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../EventStore/helpers";
import { selectActiveProjectIdFromSystem } from "../navigation/hooks";
import type {
	AddNodeShortcutEvent,
	ExtractStoreEventPayload,
} from "../../EventStore/StoreEvent";
import type { NodeInsertPosition, NodeTarget } from "./types";
import type { ProjektId } from "../../../../lib/validation/lite/IDSchemas";
import { LiteIdSchema } from "../../../../lib/validation/lite/IDSchemas";
import {
	assertHasProject,
	canDeleteNode,
	canInsertNode,
	getInsertInstructionsFromEvent,
	getUndoRedoNavigationTarget,
	isDeletedNodeReferenced,
	listReferences,
	stringifyReferences,
} from "./helpers";
import {
	getCanRedoFromSystem,
	getCanUndoFromSystem,
	getModellierungProjektFromSystem,
	getNodeTargetPathFromSystem,
	getPatchIndicesFromSystem,
} from "./hooks";
import { getEventLogFromSystem } from "../../EventStore/hooks";
import { getEffectApi } from "../effectApi/hooks";
import { createSelectNode } from "./selectors";
import type { ProjektMeta } from "../project/types";
import type { LiteKind } from "./LiteKind";

export type ModellierungModellCommandActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof modellierungModellCommandMachine
>;
type ModellierungModellCommandEvent =
	| { type: "UNDO" }
	| { type: "REDO" }
	| { type: "UPDATE_VIEW.UNDO"; projektId: ProjektId }
	| { type: "UPDATE_VIEW.REDO"; projektId: ProjektId }
	| { type: "UPDATE_VIEW"; projektId: ProjektId; mode: "undo" | "redo" }
	| ({
			type: "ALERT_ADD_ERROR";
	  } & ExtractStoreEventPayload<"MODELLIERUNG.NODE.ADD.FORBIDDEN">)
	| ({
			type: "ALERT_DELETE_ERROR";
	  } & ExtractStoreEventPayload<"MODELLIERUNG.NODE.DELETE.FORBIDDEN">)
	| ({
			type: "PROCESS_DELETE_NODE";
	  } & ExtractStoreEventPayload<"MODELLIERUNG.NODE.DELETE.REQUEST">)
	| ({
			type: "CONFIRM_DELETE_NODE";
	  } & ExtractStoreEventPayload<"MODELLIERUNG.NODE.DELETE.REQUEST_CONFIRMATION">)
	| ({
			type: "DELETE_NODE";
	  } & ExtractStoreEventPayload<"MODELLIERUNG.NODE.DELETE.CONFIRMED">)
	| {
			type: "ADD_NODE";
			kind: LiteKind;
			position: NodeInsertPosition;
			target: NodeTarget;
	  }
	| { type: "REQUEST_DELETE_NODE"; target: NodeTarget };

const addNodeEventsMap: { [TEvent in AddNodeShortcutEvent["type"]]: 0 } = {
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.PAKET": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.SCHEMA_PAKET": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.DATENTYP": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.CODE_DATENTYP": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.NACHRICHT": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.GLOBALE_EIGENSCHAFT": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.PAKET": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.SCHEMA_PAKET": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.DATENTYP": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.CODE_DATENTYP": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.NACHRICHT": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.GLOBALE_EIGENSCHAFT": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.PAKET": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.SCHEMA_PAKET": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.DATENTYP": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.CODE_DATENTYP": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.NACHRICHT": 0,
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.GLOBALE_EIGENSCHAFT": 0,

	"SHORTCUT.STRUCTURE_VIEW.ADD_CHILD.EIGENSCHAFT": 0,
	"SHORTCUT.STRUCTURE_VIEW.ADD_CHILD.ATTRIBUT": 0,
	"SHORTCUT.STRUCTURE_VIEW.ADD_ABOVE.EIGENSCHAFT": 0,
	"SHORTCUT.STRUCTURE_VIEW.ADD_ABOVE.ATTRIBUT": 0,
	"SHORTCUT.STRUCTURE_VIEW.ADD_BELOW.EIGENSCHAFT": 0,
	"SHORTCUT.STRUCTURE_VIEW.ADD_BELOW.ATTRIBUT": 0,
};
const addNodeEvents = Object.keys(
	addNodeEventsMap,
) as (keyof typeof addNodeEventsMap)[];

const addTranslation = Object.fromEntries(
	addNodeEvents.map(
		(eventType) =>
			[
				eventType,
				() => ({
					type: "ADD_NODE",
					...getInsertInstructionsFromEvent(eventType),
				}),
			] as const,
	),
);

const modellierungModellCommandMachine = setup({
	types: {
		events: {} as ModellierungModellCommandEvent,
		context: {} as ProjektMeta,
		input: {} as ProjektMeta,
	},
	actors: {
		translateEvents: translateStoreEvents<ModellierungModellCommandEvent>({
			"SHORTCUT.UNDO": () => ({ type: "UNDO" }),
			"SHORTCUT.REDO": () => ({ type: "REDO" }),
			"MODELLIERUNG.MODELL.UNDO": ({ payload }) => ({
				type: "UPDATE_VIEW.UNDO",
				projektId: payload.projektId,
			}),
			"MODELLIERUNG.MODELL.REDO": ({ payload }) => ({
				type: "UPDATE_VIEW.REDO",
				projektId: payload.projektId,
			}),
			"MODELLIERUNG.NODE.ADD.FORBIDDEN": ({ payload }) => ({
				type: "ALERT_ADD_ERROR",
				...payload,
			}),
			"SHORTCUT.BAUSTEIN_VIEW.DELETE": () => ({
				type: "REQUEST_DELETE_NODE",
				target: "baustein",
			}),
			"SHORTCUT.STRUCTURE_VIEW.DELETE": () => ({
				type: "REQUEST_DELETE_NODE",
				target: "structure",
			}),
			"MODELLIERUNG.NODE.DELETE.REQUEST": ({ payload }) => ({
				type: "PROCESS_DELETE_NODE",
				...payload,
			}),
			"MODELLIERUNG.NODE.DELETE.REQUEST_CONFIRMATION": ({ payload }) => ({
				type: "CONFIRM_DELETE_NODE",
				...payload,
			}),
			"MODELLIERUNG.NODE.DELETE.CONFIRMED": ({ payload }) => ({
				type: "DELETE_NODE",
				...payload,
			}),
			"MODELLIERUNG.NODE.DELETE.FORBIDDEN": ({ payload }) => ({
				type: "ALERT_DELETE_ERROR",
				...payload,
			}),
			...addTranslation,
		}),
	},
	actions: {
		sendUndo: ({ context, system, event }) => {
			assertEvent(event, "UNDO");
			const activeId = selectActiveProjectIdFromSystem(system);
			// Only react to events from this project
			if (activeId !== context.projektId) return;
			if (!getCanUndoFromSystem(system, context.projektId)) return;
			sendToEventStore(system, {
				type: "MODELLIERUNG.MODELL.UNDO",
				payload: { projektId: context.projektId },
			});
		},
		sendRedo: ({ context, system, event }) => {
			assertEvent(event, "REDO");
			const activeId = selectActiveProjectIdFromSystem(system);
			// Only react to events from this project
			if (activeId !== context.projektId) return;
			if (!getCanRedoFromSystem(system, context.projektId)) return;
			sendToEventStore(system, {
				type: "MODELLIERUNG.MODELL.REDO",
				payload: { projektId: context.projektId },
			});
		},
		raiseUpdateView: raise(
			({ event }) => {
				assertEvent(event, ["UPDATE_VIEW.UNDO", "UPDATE_VIEW.REDO"]);
				return {
					type: "UPDATE_VIEW",
					projektId: event.projektId,
					mode: event.type === "UPDATE_VIEW.UNDO" ? "undo" : "redo",
				} as const;
			},
			// Wait until all microtasks are executed, so the undo/redo stack is up
			// to date and we can access the current patch
			{ delay: 0 },
		),
		updateViewAfterUndoRedo: ({ event, system }) => {
			assertEvent(event, "UPDATE_VIEW");
			const { navigate } = getEffectApi(system);
			const eventLog = getEventLogFromSystem(system);
			const eventLogIndices = getPatchIndicesFromSystem(
				system,
				event.projektId,
			);
			const eventLogIndex = eventLogIndices[event.mode];
			const location = getUndoRedoNavigationTarget(eventLog, eventLogIndex);
			if (!location) return;
			navigate(location);
		},
		sendAddEvent: ({ context: { projektId }, event, system }) => {
			assertEvent(event, "ADD_NODE");
			const projekt = getModellierungProjektFromSystem(system, projektId);
			assertHasProject(projekt);
			const { kind, position, target } = event;
			// If the actor is not the active project, ignore the event
			const activeProjectId = selectActiveProjectIdFromSystem(system);
			if (activeProjectId !== projektId) return;
			const insertPath = getNodeTargetPathFromSystem(system, projektId, target);
			if (!insertPath) return;
			const payload = { insertPath, kind, position, target };
			if (!canInsertNode(projekt, insertPath, kind, position)) {
				sendToEventStore(system, {
					type: "MODELLIERUNG.NODE.ADD.FORBIDDEN",
					payload: { ...payload, projektId },
				});
			} else {
				sendToEventStore(system, {
					type: "MODELLIERUNG.MODELL.APPLY",
					payload: {
						projektId,
						patch: {
							type: "addNode",
							payload: { newNodeId: LiteIdSchema.parse(uuid()), ...payload },
						},
					},
				});
			}
		},
		sendRequestDeleteEvent: ({ context: { projektId }, event, system }) => {
			assertEvent(event, "REQUEST_DELETE_NODE");
			const { target } = event;
			// If the actor is not the active project, ignore the event
			const activeProjectId = selectActiveProjectIdFromSystem(system);
			if (activeProjectId !== projektId) return;
			const deletePath = getNodeTargetPathFromSystem(system, projektId, target);
			if (!deletePath) return;
			sendToEventStore(system, {
				type: "MODELLIERUNG.NODE.DELETE.REQUEST",
				payload: { deletePath, target, projektId },
			});
		},
		processDeleteRequest: ({ context, event, system }) => {
			assertEvent(event, "PROCESS_DELETE_NODE");
			const projekt = getModellierungProjektFromSystem(
				system,
				context.projektId,
			);
			assertHasProject(projekt);
			const { target, deletePath, projektId } = event;
			const payload = { deletePath, target, projektId };
			if (!canDeleteNode(projekt, deletePath, target)) {
				sendToEventStore(system, {
					type: "MODELLIERUNG.NODE.DELETE.FORBIDDEN",
					payload,
				});
			} else if (isDeletedNodeReferenced(projekt, deletePath)) {
				sendToEventStore(system, {
					type: "MODELLIERUNG.NODE.DELETE.REQUEST_CONFIRMATION",
					payload,
				});
			} else {
				sendToEventStore(system, {
					type: "MODELLIERUNG.NODE.DELETE.CONFIRMED",
					payload,
				});
			}
		},
		confirmDelete: ({ context, event, system }) => {
			assertEvent(event, "CONFIRM_DELETE_NODE");
			const projekt = getModellierungProjektFromSystem(
				system,
				context.projektId,
			);
			assertHasProject(projekt);
			const { target, deletePath, projektId } = event;
			const payload = { deletePath, target, projektId };
			const nodeId = deletePath.at(-1);
			const node = nodeId && createSelectNode(projekt)(nodeId);
			if (!node) {
				throw new Error(
					`Node that was requested to be deleted could not be found. id: "${nodeId}"`,
				);
			}
			const refString = stringifyReferences(
				listReferences(projekt, deletePath),
			);

			sendToEventStore(system, {
				type: "CONFIRMATION.OPEN",
				payload: { ...payload, refString },
			});
		},
		sendDeleteEvent: ({ event, system }) => {
			assertEvent(event, "DELETE_NODE");
			const { target, deletePath, projektId } = event;
			const payload = { deletePath, target };
			sendToEventStore(system, {
				type: "MODELLIERUNG.MODELL.APPLY",
				payload: { projektId, patch: { type: "deleteNode", payload } },
			});
		},
		alertAddError: ({ event, system }) => {
			assertEvent(event, "ALERT_ADD_ERROR");
			const { kind } = event;
			sendToEventStore(system, {
				type: "NOTIFICATION.ALERT",
				payload: {
					severity: "info",
					text: `Ein Knoten vom typ ${kind} kann nicht an dieser Stelle erstellt werden`,
					autoHide: true,
				},
			});
		},
		alertDeleteError: ({ event, system }) => {
			assertEvent(event, "ALERT_DELETE_ERROR");
			sendToEventStore(system, {
				type: "NOTIFICATION.ALERT",
				payload: {
					severity: "info",
					text: `Der Knoten kann an dieser Stelle nicht gelöscht werden`,
					autoHide: true,
				},
			});
		},
	},
	guards: {
		isSelf: ({ event, context }) => {
			if (!("projektId" in event)) {
				throw new Error(
					`Guard "isSelf" cannot be used with event "${event.type}"`,
				);
			}
			return event.projektId === context.projektId;
		},
	},
}).createMachine({
	id: "modellierungModell:command",
	context: ({ input }) => ({ ...input }),
	invoke: { src: "translateEvents" },
	on: {
		UNDO: { actions: "sendUndo" },
		REDO: { actions: "sendRedo" },
		"UPDATE_VIEW.UNDO": {
			guard: "isSelf",
			actions: "raiseUpdateView",
		},
		"UPDATE_VIEW.REDO": {
			guard: "isSelf",
			actions: "raiseUpdateView",
		},
		UPDATE_VIEW: { actions: "updateViewAfterUndoRedo" },
		ADD_NODE: { actions: "sendAddEvent" },
		REQUEST_DELETE_NODE: { actions: "sendRequestDeleteEvent" },
		PROCESS_DELETE_NODE: { guard: "isSelf", actions: "processDeleteRequest" },
		CONFIRM_DELETE_NODE: { guard: "isSelf", actions: "confirmDelete" },
		DELETE_NODE: { guard: "isSelf", actions: "sendDeleteEvent" },
		ALERT_ADD_ERROR: { guard: "isSelf", actions: "alertAddError" },
		ALERT_DELETE_ERROR: { guard: "isSelf", actions: "alertDeleteError" },
	},
});

export default modellierungModellCommandMachine;
