import { v4 as uuid } from "uuid";
import { extractChildNodeEntries } from "../../../helpers";
import type { RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import styles from "./ListRenderer.module.scss";
import UnknownMarkup from "../UnknownMarkup";

const ListRenderer: RendererImplementationComponent<
	RendererImplementation.List
> = ({ markup, renderer: Renderer }) => {
	const children = extractChildNodeEntries(markup);

	if (children.length === 0) return <UnknownMarkup markup={markup} />;

	return (
		<ul className={styles["xoev-renderer-list"]} data-testid="list-renderer">
			{
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				children.map(([unusedVar, childMarkup]) => {
					return (
						<Renderer
							markup={childMarkup}
							key={`xoev-renderer-list-key-${uuid()}`}
						/>
					);
				})
			}
		</ul>
	);
};

export default ListRenderer;
