import type { LiteId } from "../lib/validation/lite/IDSchemas";
import useActiveRestriction from "../components/DatatypesView/useActiveRestriction";
import { EditorSection, useEditorSection } from "../components/EditorPage";
import type { RestrictionId } from "../components/EditorState/types";
import { useActiveNode } from "../components/MessageProfilingView/useActiveNode";
import { useProfilierungValidationResults } from "../components/ProfilierungValidation/hooks";
import type {
	Locator,
	ValidationResultBase,
	ValidationTargetFieldProfiling,
} from "../components/ProfilierungValidation/types";
import { ValidationResultGroup } from "../components/ProfilierungValidation/types";

export default function useProfilierungValidationResult<
	TargetField extends ValidationTargetFieldProfiling,
>({
	nodeId: nodeIdParam,
	restrictionId: restrictionIdParam,
	targetField,
}: {
	nodeId?: LiteId;
	restrictionId?: RestrictionId;
	targetField?: TargetField;
}): ValidationResultBase<TargetField>[] {
	const section = useEditorSection();
	const { activeNode } = useActiveNode();
	const activeRestriction = useActiveRestriction();
	const nodeId = nodeIdParam ?? activeNode.id;
	const restrictionId = restrictionIdParam ?? activeRestriction?.get("id");
	const resultGroup =
		section === EditorSection.MessageStructures
			? ValidationResultGroup.MessageProfiles
			: ValidationResultGroup.RestrictionProfiles;
	const locator:
		| Locator<ValidationResultGroup.MessageProfiles>
		| Locator<ValidationResultGroup.RestrictionProfiles> =
		section === EditorSection.MessageStructures
			? { nodeId }
			: { nodeId, restrictionId };
	const results = useProfilierungValidationResults<
		ValidationResultGroup,
		TargetField
	>(resultGroup, { ...locator, targetField });
	return results;
}
