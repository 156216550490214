import type { MouseEvent } from "react";
import { useRef } from "react";
import type { MessageType } from "./MessageOverview/types";

// eslint-disable-next-line import/prefer-default-export
export const useDoubleClick = (
	onClick: (message: MessageType) => void,
	onDbClick: (message: MessageType) => void,
	delay = 500,
) => {
	const timePassed = useRef(0);
	return (e: MouseEvent, message: MessageType) => {
		if (e.detail === 1) {
			setTimeout(() => {
				if (Date.now() - timePassed.current >= delay) {
					onClick(message);
				}
			}, delay);
		}

		if (e.detail === 2) {
			timePassed.current = Date.now();
			onDbClick(message);
		}
	};
};
