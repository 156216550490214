import type { TreeNodeType } from "../../types/ProfilierungHome";
import { useAppSelector } from "../../redux/hooks";
import { selectModellContainer } from "../../redux/treeSlice";
import type { LiteId } from "../../lib/validation/lite/IDSchemas";
import type { Nullish } from "../../utils/types";
import type { RootState } from "../../redux/store";
import { selectUsedByFromModell } from "../AppActor/actors/modellierungModel/search/worker/resolveOccurrences";

const EMPTY_USED_BY: LiteId[][] = [];

const selectUsedBy =
	(standard: Nullish<string>, nodeId: Nullish<LiteId>) =>
	(state: RootState) => {
		const modell = selectModellContainer(standard)(state);
		if (!modell || !nodeId) return EMPTY_USED_BY;
		return selectUsedByFromModell(modell, nodeId);
	};

function useUsedBy(activeNode: TreeNodeType, standard: Nullish<string>) {
	return useAppSelector(selectUsedBy(standard, activeNode.id));
}

export default useUsedBy;
