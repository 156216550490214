import merge from "lodash/merge";
import { createSlice } from "@reduxjs/toolkit";
import type { AppInfoResponse } from "../components/AppInfoProvider/types";
import { apiSlice } from "./apiSlice";
import type { RootState } from "./store";

const initialState: AppInfoResponse = {
	config: {
		"docs-plattform": "https://docs.xoev.de",
		"google-site-verification": "",
	},
	keycloak: {
		clientId: "suite",
		realm: "xoev",
		url: "",
	},
};

export const appInfoSlice = createSlice({
	name: "appInfo",
	initialState,
	reducers: {
		setAppInfo(state, action) {
			return merge(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			apiSlice.endpoints.getAppInfo.matchFulfilled,
			(state, { payload }) => {
				return { ...state, ...payload };
			},
		);
	},
});

export const { setAppInfo } = appInfoSlice.actions;

export const getAppInfo = (state: RootState) => state.appInfo;

export default appInfoSlice.reducer;
