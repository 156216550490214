import { useCallback } from "react";
import type { Patch } from "@xoev/state-container";
import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import { useEventHandler } from ".";
import { toMutable } from "../utils/types";
import useActiveDataType from "../components/DatatypesView/useActiveDataType";
import useActiveRestriction from "../components/DatatypesView/useActiveRestriction";
import useActiveRestrictionProfile from "../components/DatatypesView/useActiveRestrictionProfile";
import { EditorSection, useEditorSection } from "../components/EditorPage";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../components/EditorState";
import {
	selectDeepProfile,
	selectHasParentProfiledDatatype,
	selectHasParentZeroCardinality,
	selectHasRestrictionParentProfiledDatatype,
	selectHasRestrictionParentZeroCardinality,
	selectIsChildDatatypedOrProfiled,
	selectIsRestrictionChildDatatypedOrProfiled,
	selectProfiles,
	selectRestrictionProfileMap,
	selectStandard,
} from "../components/EditorState/selectors";
import { RestrictionIdSchema } from "../components/EditorState/types";
import type {
	RestrictionId,
	EditorPatchMap,
	MessageProfileConfiguration,
	MessageProfileValues,
} from "../components/EditorState/types";
import type { QName, QNamePath } from "../lib/validation/lite/LiteSchemas";
import type { LiteId } from "../lib/validation/lite/IDSchemas";
import {
	QNamePathSchema,
	QNameSchema,
} from "../lib/validation/lite/LiteSchemas";
import { useAppSelector } from "../redux/hooks";
import { selectQName, selectQNamePath } from "../redux/treeSlice";

export interface SectionDependentProfileInfo {
	profile: ImmutableMap<Partial<MessageProfileValues>>;
	setProfile: (profile: ImmutableMap<Partial<MessageProfileValues>>) => void;
	deleteProfile: () => void;
	setValueForProfile: (
		name: keyof MessageProfileValues | keyof MessageProfileConfiguration,
		value: string,
	) => void;
	isParentProfiledDatatype: boolean;
	hasParentZeroCardinality: boolean;
	isChildDatatypedOrProfiled: boolean;
}
type EditorPatchTypes = Patch<EditorPatchMap, keyof EditorPatchMap>;

const EMPTY_PROFILE = createImmutableMap<Partial<MessageProfileValues>>({});

function useProfileCallback() {
	const standard = useStateSelector(selectStandard());
	const { activeDataType } = useActiveDataType();
	const datatypeQName = useAppSelector(selectQName(standard, activeDataType));
	const activeRestriction = useActiveRestriction();
	const datatypeId = activeDataType?.id;
	const restrictionId =
		activeRestriction?.get("id") || RestrictionIdSchema.parse("");
	const {
		activePath: activeRestrictionPath,
		isFallback: isRestrictionFallback,
	} = useActiveRestrictionProfile(datatypeId);
	const isRestrictionProfile = !isRestrictionFallback;
	const activeQNamePath = useAppSelector(
		selectQNamePath(standard, activeRestrictionPath),
	);
	const callback = useCallback(
		<T>(
			isMessageProfileCallback: () => T,
			isRestrictionProfileCallback: (context: {
				datatypeId: QName;
				restrictionId: RestrictionId;
				restrictionProfileId: QNamePath;
			}) => T,
		) => {
			return isRestrictionProfile
				? isRestrictionProfileCallback({
						datatypeId: datatypeQName || QNameSchema.parse(""),
						restrictionId,
						restrictionProfileId: QNamePathSchema.parse(activeQNamePath || ""),
				  })
				: isMessageProfileCallback();
		},
		[activeQNamePath, datatypeQName, isRestrictionProfile, restrictionId],
	);
	return callback;
}

function useNodeMeta(activePath: LiteId[]) {
	const profileCallback = useProfileCallback();
	const standard = useStateSelector(selectStandard());
	const qnamePath = useAppSelector(selectQNamePath(standard, activePath));

	const profileMap = useStateSelector(
		profileCallback(
			() => selectProfiles(),
			({ datatypeId, restrictionId }) =>
				selectRestrictionProfileMap(datatypeId, restrictionId),
		),
	);
	const isParentProfiledDatatype = useStateSelector(
		profileCallback(
			() => selectHasParentProfiledDatatype(qnamePath),
			({ datatypeId, restrictionId, restrictionProfileId }) =>
				selectHasRestrictionParentProfiledDatatype(
					datatypeId,
					restrictionId,
					restrictionProfileId,
				),
		),
	);
	const hasParentZeroCardinality = useStateSelector(
		profileCallback(
			() => selectHasParentZeroCardinality(qnamePath),
			({ datatypeId, restrictionId, restrictionProfileId }) =>
				selectHasRestrictionParentZeroCardinality(
					datatypeId,
					restrictionId,
					restrictionProfileId,
				),
		),
	);
	const isChildDatatypedOrProfiled = useStateSelector(
		profileCallback(
			() => selectIsChildDatatypedOrProfiled(qnamePath),
			({ datatypeId, restrictionId, restrictionProfileId }) =>
				selectIsRestrictionChildDatatypedOrProfiled(
					datatypeId,
					restrictionId,
					restrictionProfileId,
				),
		),
	);

	return {
		profileMap,
		isParentProfiledDatatype,
		hasParentZeroCardinality,
		isChildDatatypedOrProfiled,
	};
}

function useProfileActions(activePath: LiteId[]) {
	const applyPatch = useApplyPatch();
	const {
		setProfile,
		setRestrictionProfile,
		setValueForProfile,
		setValueForRestrictionProfile,
		deleteProfile: deleteMessageProfile,
		deleteRestrictionProfile,
	} = useEditorPatchCreators();
	const profileCallback = useProfileCallback();
	const standard = useStateSelector(selectStandard());
	const qnamePath = useAppSelector(selectQNamePath(standard, activePath));

	const setNewProfile = useEventHandler(
		(profile: ImmutableMap<Partial<MessageProfileValues>>) => {
			if (!qnamePath) return;
			applyPatch(
				profileCallback<EditorPatchTypes>(
					() => setProfile({ id: qnamePath, profile }),
					({ datatypeId, restrictionId, restrictionProfileId }) =>
						setRestrictionProfile({
							datatypeId,
							restrictionId,
							restrictionProfileId,
							profile,
						}),
				),
			);
		},
	);
	const deleteProfile = useEventHandler(() => {
		if (!qnamePath) return;
		applyPatch(
			profileCallback<EditorPatchTypes>(
				() => deleteMessageProfile({ id: qnamePath }),
				({ datatypeId, restrictionId, restrictionProfileId }) =>
					deleteRestrictionProfile({
						datatypeId,
						restrictionId,
						restrictionProfileId,
					}),
			),
		);
	});

	const setNewValueForProfile = useEventHandler(
		(
			name: keyof MessageProfileValues | keyof MessageProfileConfiguration,
			value: string,
		) => {
			if (!qnamePath) return;
			applyPatch(
				profileCallback<EditorPatchTypes>(
					() => setValueForProfile({ id: qnamePath, name, value }),
					({ datatypeId, restrictionId, restrictionProfileId }) =>
						setValueForRestrictionProfile({
							datatypeId,
							restrictionId,
							restrictionProfileId,
							name,
							value,
						}),
				),
			);
		},
	);

	return {
		setProfile: setNewProfile,
		deleteProfile,
		setValueForProfile: setNewValueForProfile,
	};
}

export default function useSectionDependentProfile(
	activePath: LiteId[],
): SectionDependentProfileInfo {
	const { activeDataType } = useActiveDataType();
	const activeRestriction = useActiveRestriction();
	const section = useEditorSection();
	if (
		section === EditorSection.Datatypes &&
		(!activeDataType || !activeRestriction)
	) {
		throw new Error(
			`Should have active datatype and active restriction in datatypes view`,
		);
	}
	const standard = useStateSelector(selectStandard());
	const qnamePath = useAppSelector(selectQNamePath(standard, activePath));

	const {
		hasParentZeroCardinality,
		isChildDatatypedOrProfiled,
		isParentProfiledDatatype,
		profileMap,
	} = useNodeMeta(activePath);
	const { deleteProfile, setProfile, setValueForProfile } =
		useProfileActions(activePath);

	const profile =
		useStateSelector((state) =>
			qnamePath
				? selectDeepProfile(qnamePath, profileMap)(state)
				: EMPTY_PROFILE,
		) ?? EMPTY_PROFILE;

	return toMutable({
		profile,
		setProfile,
		deleteProfile,
		setValueForProfile,
		isParentProfiledDatatype,
		hasParentZeroCardinality,
		isChildDatatypedOrProfiled,
	} as const);
}
