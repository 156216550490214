import type { EventStoreLog } from "../../../../EventStore/eventStore.machine";
import type { ProjektMeta } from "../../../project/types";
import ModellWorkerBridge from "../../ModellWorkerBridge";
import type { ModellHandlerMap } from "../../ModellWorkerBridge/types";
import type { RawLiteModel } from "../../../../../../lib/validation/lite/LiteSchemas";
import type { ProjektId } from "../../../../../../lib/validation/lite/IDSchemas";
import { ProjektType } from "../../../../../../lib/validation/lite/LiteEnums";
import type {
	ModellEventLogEntry,
	SearchActionMap,
	SearchResult,
} from "../types";

export default class SearchWorkerBridge {
	worker: ModellWorkerBridge<SearchActionMap>;

	constructor(worker: ModellWorkerBridge<SearchActionMap>) {
		this.worker = worker;
	}

	static async fromEventLog(
		projektId: ProjektId,
		eventLog: EventStoreLog,
		createWorker: () => Worker,
	): Promise<SearchWorkerBridge> {
		const worker = await ModellWorkerBridge.fromEventLog<SearchActionMap>(
			projektId,
			eventLog,
			createWorker,
			1,
		);
		return new SearchWorkerBridge(worker);
	}

	static async fromProfilierung(
		rawModel: RawLiteModel,
		createWorker: () => Worker,
	): Promise<SearchWorkerBridge> {
		// Blank values are currently still being used
		// until the profiling has also been transferred
		// to the eventStore Actor system
		const projektId = "" as ProjektId;
		const projektMeta = {
			dbId: 1,
			projektId,
			kennung: "",
			projektType: ProjektType.Standard,
		} as ProjektMeta;
		const modellEvents: ModellEventLogEntry[] = [];

		const worker = new ModellWorkerBridge<SearchActionMap>(
			projektId,
			createWorker,
		);
		await worker.init(projektMeta, rawModel, modellEvents);
		return new SearchWorkerBridge(worker);
	}

	search(term: string): Promise<SearchResult> {
		return this.worker.bridge.enqueue("search", { term });
	}

	static connectWorker(handlerMap: ModellHandlerMap<SearchActionMap>) {
		ModellWorkerBridge.connectWorker(handlerMap);
	}
}
