import { z } from "zod";

export enum LiteNodeType {
	Model = "MODELL",
	Paket = "PAKET",
	Baustein = "BAUSTEIN",
	Eigenschaft = "EIGENSCHAFT",
}
export const LiteNodeTypeSchema = z.nativeEnum(LiteNodeType);

export enum LiteBausteinType {
	Datentyp = "DATENTYP",
	CodeDatentyp = "CODE_DATENTYP",
	Nachricht = "NACHRICHT",
	GlobaleEigenschaft = "EIGENSCHAFT",
	GlobaleEigenschaftGruppe = "EIGENSCHAFT_GRUPPE",
}
export const LiteBausteinTypeSchema = z.nativeEnum(LiteBausteinType);

export enum LiteGruppeType {
	Sequence = "SEQUENCE",
	Choice = "CHOICE",
	All = "ALL",
}
export const LiteGruppeTypeSchema = z.nativeEnum(LiteGruppeType);

export enum LitePaketType {
	Schema = "SCHEMA",
	Simple = "SIMPLE",
}
export const LitePaketTypeSchema = z.nativeEnum(LitePaketType);

export enum ProjektType {
	Modellierung = "MODELLIERUNG",
	Genericode = "GENERICODE",
	Profilierung = "PROFIL",
	Standard = "STANDARD",
}
export const ProjektTypeSchema = z.nativeEnum(ProjektType);
