import { useSendStoreEvent } from "../../../../../AppActor/EventStore/hooks";
import {
	SchemaPaketKonfigurationSchema,
	type LitePaket,
} from "../../../../../../lib/validation/lite/LiteSchemas";
import { ValidationTargetField } from "../../../../../AppActor/actors/modellierungModel/validation/validators/types";
import EditFormFieldLite from "../../../../../EditFormFieldLite";
import ValidationDisplayLite from "../../../../../ValidationDisplayLite";
import type { EditRendererProps } from "../../types";

export default function SchemaPaketEdit({
	activeNode,
	activePath,
	projekt,
	isReadOnly,
}: Readonly<EditRendererProps<LitePaket>>): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();

	const handleWrite = (name: string, value: string) => {
		const nodeId = activeNode.id;
		sendStoreEvent({
			type: "MODELLIERUNG.MODELL.APPLY",
			payload: {
				projektId: projekt.id,

				patch: {
					type: "changePaketConfig",
					payload: {
						name: SchemaPaketKonfigurationSchema.keyof().parse(name),
						value: value || undefined,
						nodeId,
					},
				},
			},
		});
	};

	return (
		<>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "prefix",
					label: "Präfix",
				}}
				value={activeNode.konfiguration?.prefix || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "prefix",
				}}
				error={
					<ValidationDisplayLite
						locator={{
							targetField: ValidationTargetField.SchemaPrefix,
							path: activePath,
						}}
					/>
				}
			/>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "schemaFile",
					label: "Dateiname",
				}}
				value={activeNode.konfiguration?.schemaFile || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "schemaFile",
				}}
				error={
					<ValidationDisplayLite
						locator={{
							targetField: ValidationTargetField.SchemaDateiname,
							path: activePath,
						}}
					/>
				}
			/>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "namespace",
					label: "Namensraum",
				}}
				value={activeNode.konfiguration?.namespace || ""}
				onBlur={handleWrite}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "namespace",
				}}
				error={
					<ValidationDisplayLite
						locator={{
							targetField: ValidationTargetField.SchemaUri,
							path: activePath,
						}}
					/>
				}
			/>
			<EditFormFieldLite
				definition={{
					readOnly: isReadOnly,
					name: "elementFormDefault",
					label: "Element Form Default",
					type: "select",
					options: [
						{ value: "", label: "Projekteinstellung" },
						{ value: "qualified", label: "qualified" },
						{ value: "unqualified", label: "unqualified" },
					],
				}}
				onChange={(e) =>
					e.target.name && handleWrite(e.target.name, e.target.value)
				}
				value={activeNode.konfiguration?.elementFormDefault || ""}
				inputProps={{
					"data-testid": "details-field",
					"data-field-name": "elementFormDefault",
				}}
				error={
					<ValidationDisplayLite
						locator={{
							targetField: ValidationTargetField.ElementFormDefault,
							path: activePath,
						}}
					/>
				}
			/>
		</>
	);
}
