import { isLiteEigenschaft } from "../../../../lib/validation/lite/TypeGuards";
import type { MessageProfileValues } from "../../../EditorState/types";
import { parseMultiplizitaet } from "../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/CardinalityRenderer/CardinalityRenderer";
import type { ProfileRule, ProfileValidationContext } from "../../types";
import { ValidationTargetFieldProfiling } from "../../types";
import { addTargetField } from "../helpers";

type BoundKeys = "lowerBound" | "upperBound";
interface SkipWhenNotDefinedOptions<Keys extends BoundKeys = BoundKeys> {
	target?: Keys[];
	trim?: boolean;
}
type BoundRecord<Keys extends BoundKeys> = {
	[K in Keys]: Exclude<MessageProfileValues[K], undefined>;
};
type SkipWhenNotDefinedContext<Keys extends BoundKeys> =
	ProfileValidationContext & BoundRecord<Keys>;

const validBoundRegex = /^(\*|\d+)$/;

function parseBound(bound: string): number | null {
	if (bound === "*") return Infinity;
	const num = Number(bound);
	if (Number.isNaN(num)) return null;
	return num;
}

const defaultOptions: Required<SkipWhenNotDefinedOptions> = {
	target: ["lowerBound", "upperBound"],
	trim: true,
};
function skipWhenNotDefined<Keys extends BoundKeys = BoundKeys>(
	isValid: (context: SkipWhenNotDefinedContext<Keys>) => boolean,
	options?: SkipWhenNotDefinedOptions<Keys>,
) {
	const { target, trim } = {
		...defaultOptions,
		...options,
	} as Required<SkipWhenNotDefinedOptions<Keys>>;
	const augmented: ProfileRule["isValid"] = (context) => {
		const { profile } = context;
		const shouldSkip = target.some((key) => {
			const bound = profile.get(key);
			return trim ? !bound?.trim() : !bound;
		});
		if (shouldSkip) return true;
		const bounds: Partial<BoundRecord<Keys>> = {};
		target.forEach((key) => {
			bounds[key] = profile.get(key) as Exclude<
				MessageProfileValues[Keys],
				undefined
			>;
		});
		return isValid({ ...context, ...(bounds as BoundRecord<Keys>) });
	};
	return augmented;
}

function getComparableBounds<Key extends BoundKeys>(
	context: SkipWhenNotDefinedContext<Key>,
	boundKey: Key,
): null | [profileBound: number, stdBound: number] {
	const { [boundKey]: boundStr, standardNode, profile, id } = context;
	const boundValue = parseBound(boundStr);
	// If the bound is not parsable return early, we'll check that elswhere
	if (boundValue === null) return null;
	const bounds: Partial<ReturnType<typeof parseMultiplizitaet>> =
		isLiteEigenschaft(standardNode)
			? parseMultiplizitaet(standardNode.multiplizitaet)
			: {};
	const stdBoundStr = bounds[boundKey];
	if (!stdBoundStr) {
		const stdFormat = JSON.stringify(standardNode, null, 2);
		const profileFormat = JSON.stringify(profile.toJS(), null, 2);
		throw new Error(
			`Unexpected missing bound in standard: The "${boundKey}" for ` +
				`node with id "${id}" was entered by the user, but the standard ` +
				`does not define a ${boundKey}. This node should not have allowed ` +
				`editing the bounds.\n\nStandard node: ${stdFormat}\nProfiled ` +
				`node: ${profileFormat}`,
		);
	}
	const stdBoundValue = parseBound(stdBoundStr) as number;
	return [boundValue, stdBoundValue];
}

const cardinalityRules = addTargetField<
	ValidationTargetFieldProfiling.Cardinality,
	ProfileRule<ValidationTargetFieldProfiling.Cardinality>
>(ValidationTargetFieldProfiling.Cardinality, [
	{
		id: "cardinality-is-defined",
		target: ["lowerBound", "upperBound"],
		isValid({ profile }) {
			const hasLowerBound = !!profile.get("lowerBound")?.trim();
			const hasUpperBound = !!profile.get("upperBound")?.trim();
			// Since bounds are not required having no lower AND no upper bound is
			// valid. But if one bound is defined, the other bound has to be defined
			// too
			return hasLowerBound === hasUpperBound;
		},
		message: ({ profile }) =>
			"Die Kardinalität muss eine Ober- und eine Untergrenze enthalten, " +
			`aber nur eine ${profile.get("lowerBound")?.trim() ? "Unter" : "Ober"}` +
			"grenze wurde gefunden.",
	},
	{
		id: "lowerBound-is-valid-char",
		target: ["lowerBound"],
		isValid: skipWhenNotDefined(
			({ lowerBound }) => validBoundRegex.test(lowerBound),
			{ target: ["lowerBound"] },
		),
		message:
			"Die angegebene Untergrenze ist nicht valide, bitte geben Sie " +
			"nur ganze, positive Zahlen an.",
	},
	{
		id: "upperBound-is-valid-char",
		target: ["upperBound"],
		isValid: skipWhenNotDefined(
			({ upperBound }) => validBoundRegex.test(upperBound),
			{ target: ["upperBound"] },
		),
		message:
			"Die angegebene Obergrenze ist nicht valide, bitte geben Sie " +
			'nur ganze, positive Zahlen oder "*" an.',
	},
	{
		id: "lowerBound-has-no-surrounding-whitespace",
		target: ["lowerBound"],
		isValid: skipWhenNotDefined(
			({ lowerBound }) => lowerBound === lowerBound.trim(),
			{ target: ["lowerBound"], trim: false },
		),
		message: "Die Untergrenze darf keine umgebenden Leerzeichen enthalten.",
		autoFix:
			(invalidValue) =>
			(fieldName, { skip }) => {
				if (fieldName !== "lowerBound") return skip;
				return invalidValue.trim();
			},
		autoFixDescription: "Umgebende Leerzeichen entfernen",
	},
	{
		id: "upperBound-has-no-surrounding-whitespace",
		target: ["upperBound"],
		isValid: skipWhenNotDefined(
			({ upperBound }) => upperBound === upperBound.trim(),
			{ target: ["upperBound"], trim: false },
		),
		message: "Die Obergrenze darf keine umgebenden Leerzeichen enthalten.",
		autoFix:
			(invalidValue) =>
			(fieldName, { skip }) => {
				if (fieldName !== "upperBound") return skip;
				return invalidValue.trim();
			},
		autoFixDescription: "Umgebende Leerzeichen entfernen",
	},
	{
		id: "lowerBound-is-not-star",
		target: ["lowerBound"],
		isValid: skipWhenNotDefined(({ lowerBound }) => lowerBound !== "*", {
			target: ["lowerBound"],
		}),
		message:
			'Die Untergrenze darf nicht "*" sein. Wenn Sie alle Angaben ' +
			'zulassen möchten verwenden Sie die Kardinalität "0..*".',
	},
	{
		id: "lowerBound-is-less-than-or-eq-upperBound",
		target: ["lowerBound", "upperBound"],
		isValid: skipWhenNotDefined(
			({ lowerBound: lowerBoundStr, upperBound: upperBoundStr }) => {
				const lowerBound = parseBound(lowerBoundStr);
				const upperBound = parseBound(upperBoundStr);
				// We'll check if the bounds are not parsable correctly in another rule
				if (lowerBound === null || upperBound === null) return true;
				return lowerBound <= upperBound;
			},
		),
		message: "Die Untergrenze darf nicht größer als die Obergrenze sein.",
	},
	{
		id: "lowerBound-is-not-less-than-std",
		target: ["lowerBound"],
		isValid: skipWhenNotDefined(
			(context) => {
				const boundSet = getComparableBounds(context, "lowerBound");
				if (!boundSet) return true;
				const [lowerBound, stdLower] = boundSet;
				return lowerBound >= stdLower;
			},
			{
				target: ["lowerBound"],
			},
		),
		message:
			"Die Untergrenze darf nicht unter der Untergrenze des Standards liegen",
	},
	{
		id: "upperBound-is-not-greater-than-std",
		target: ["upperBound"],
		isValid: skipWhenNotDefined(
			(context) => {
				const boundSet = getComparableBounds(context, "upperBound");
				if (!boundSet) return true;
				const [upperBound, stdUpper] = boundSet;
				return upperBound <= stdUpper;
			},
			{
				target: ["upperBound"],
			},
		),
		message:
			"Die Obergrenze darf nicht über der Obergrenze des Standards liegen",
	},
]);

export default cardinalityRules;
