import { useCallback, useEffect, useState } from "react";
import { Button, Divider, FormControlLabel, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useSendStoreEvent } from "../../../components/AppActor/EventStore/hooks";
import type { EditRendererProps } from "../../../components/LiteModelView/DetailsView/renderers/types";
import EditIcon from "../../../resources/icons/EditIcon";
import type {
	Geschaeftsregel,
	Geschaeftsregeln,
	LiteBaustein,
	LiteEigenschaft,
} from "../../../lib/validation/lite/LiteSchemas";

import "./GeschaeftsregelnEdit.scss";
import GeschaeftsregelWrapper from "../../Atoms/GeschaeftsregelWrapper/GeschaeftsregelWrapper";
import GeschaeftsregelSingleDisplay from "../../Molecules/GeschaeftsregelSingleDisplay/GeschaeftsregelSingleDisplay";
import DeleteIcon from "../../../resources/icons/DeleteIcon";
import ValidationDisplayLite from "../../../components/ValidationDisplayLite";
import { ValidationTargetField } from "../../../components/AppActor/actors/modellierungModel/validation/validators/types";
import useValidationResults from "../../../components/ValidationDisplayLite/useValidationResults";
import GeschaeftsregelSingleEdit from "../../Molecules/GeschaeftsregelSingleEdit";
import { labels } from "../../../resources/textConstants";
import getKennungValidationErrors from "./utils";

export default function GeschaeftsregelnEdit({
	activeNode,
	projekt,
	activePath,
}: EditRendererProps<LiteBaustein | LiteEigenschaft>): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [showGeschaeftsregeln, setShowGeschaeftsregeln] = useState(true);
	const [geschaeftsregeln, setGeschaeftsregeln] = useState<Geschaeftsregeln>(
		[],
	);
	useEffect(() => {
		if (activeNode.geschaeftsregeln) {
			setGeschaeftsregeln(activeNode.geschaeftsregeln);
		}
	}, [activeNode.geschaeftsregeln]);

	const validationResults =
		useValidationResults<ValidationTargetField.GeschaeftsregelKennung>({
			path: activePath,
			targetField: ValidationTargetField.GeschaeftsregelKennung,
		});

	const deleteGeschaeftsRegel = (index: number) => {
		const updatedGeschaeftsregeln = geschaeftsregeln;
		const nodeId = activeNode.id;

		updatedGeschaeftsregeln?.splice(index, 1);
		sendStoreEvent({
			type: "MODELLIERUNG.MODELL.APPLY",
			payload: {
				projektId: projekt.id,
				patch: {
					type: "changeGeschaeftsregeln",
					payload: {
						value: updatedGeschaeftsregeln || [],
						nodeId,
					},
				},
			},
		});
	};

	/* adds or updates geschaeftsregeln */
	const saveGeschaeftsregel = useCallback(
		(geschaeftsregel: Geschaeftsregel, index?: number) => {
			if (index && index !== activeIndex) {
				throw new Error(
					`ID missmatch in saveGeschaeftsregel ${index}:${activeIndex}`,
				);
			}
			const updatedGeschaeftsregeln: Geschaeftsregeln = geschaeftsregeln || [];
			const newIndex =
				index !== undefined ? index : activeNode?.geschaeftsregeln?.length || 0;

			if (!index) {
				setActiveIndex(newIndex);
			}
			if (geschaeftsregel.kennung === undefined) {
				throw new Error(
					`Missing Kennung ${geschaeftsregel} ${index}:${activeIndex}`,
				);
			} else {
				updatedGeschaeftsregeln[newIndex] = geschaeftsregel;
				setGeschaeftsregeln(updatedGeschaeftsregeln);
			}
			const nodeId = activeNode.id;
			sendStoreEvent({
				type: "MODELLIERUNG.MODELL.APPLY",
				payload: {
					projektId: projekt.id,
					patch: {
						type: "changeGeschaeftsregeln",
						payload: { value: updatedGeschaeftsregeln, nodeId },
					},
				},
			});
		},
		[
			activeIndex,
			activeNode?.geschaeftsregeln?.length,
			activeNode.id,
			geschaeftsregeln,
			projekt.id,
			sendStoreEvent,
		],
	);
	const handleSave = useCallback(
		(geschaeftsregel, index) => {
			saveGeschaeftsregel(geschaeftsregel, index);
		},
		[saveGeschaeftsregel],
	);
	const closeGeschaeftsregel = () => {
		setActiveIndex(null);
	};
	const handleAddGeschaeftsregel = () => {
		saveGeschaeftsregel({ kennung: "" });
	};

	return (
		<>
			<FormControlLabel
				control={showGeschaeftsregeln ? <Remove /> : <Add />}
				label="Geschaeftsregeln"
				labelPlacement="start"
				className="geschaeftsregeln-toggle-label"
				onClick={() => setShowGeschaeftsregeln(!showGeschaeftsregeln)}
			/>
			{!showGeschaeftsregeln && (
				<ValidationDisplayLite
					locator={{
						path: activePath,
						targetField: ValidationTargetField.GeschaeftsregelKennung,
					}}
				/>
			)}
			{showGeschaeftsregeln && (
				<>
					{geschaeftsregeln &&
						geschaeftsregeln.length > 0 &&
						geschaeftsregeln.map((geschaeftsregel: Geschaeftsregel, index) => {
							return (
								<GeschaeftsregelWrapper
									// eslint-disable-next-line react/no-array-index-key
									key={`${index}`}
								>
									{index === activeIndex ? (
										<GeschaeftsregelSingleEdit
											handleSave={handleSave}
											handleClose={closeGeschaeftsregel}
											data={geschaeftsregel}
											activePath={activePath}
											index={index}
										>
											<IconButton
												onClick={() => deleteGeschaeftsRegel(index)}
												data-testid="delete-button"
											>
												<DeleteIcon />
											</IconButton>
										</GeschaeftsregelSingleEdit>
									) : (
										<GeschaeftsregelSingleDisplay
											geschaeftsregel={geschaeftsregel}
											error={getKennungValidationErrors({
												geschaeftsregel,
												validationResults,
												activeNode,
												activePath,
											})}
										>
											<IconButton
												onClick={() => deleteGeschaeftsRegel(index)}
												data-testid="delete-button"
											>
												<DeleteIcon />
											</IconButton>
											<IconButton
												onClick={() => setActiveIndex(index)}
												data-testid="edit-button"
											>
												<EditIcon />
											</IconButton>
										</GeschaeftsregelSingleDisplay>
									)}
									{index !== geschaeftsregeln.length - 1 && (
										<Divider sx={{ borderColor: "var(--text-ghost)" }} />
									)}
								</GeschaeftsregelWrapper>
							);
						})}
					<div>
						<Button
							onClick={handleAddGeschaeftsregel}
							variant="outlined"
							endIcon={<EditIcon />}
						>
							{labels.geschaeftsregeln.neueRegel}
						</Button>
					</div>
				</>
			)}
		</>
	);
}
