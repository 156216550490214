import { Seq } from "immutable";
import type { ImmutableMap } from "@xoev/immutable-map";
import type {
	PropertyValues,
	DatatypeProfileValues,
	MessageProfileValues,
	ProfileSeqEntry,
	RestrictionProfileValues,
	SelectablilityStatus,
	DatatypeMap,
	MessageProfileConfiguration,
	RestrictionId,
} from "./types";
import { SelectablilityCode } from "./types";
import type { QName } from "../../lib/validation/lite/LiteSchemas";
import { QNamePathSchema } from "../../lib/validation/lite/LiteSchemas";

type NullableProfile =
	| ImmutableMap<Partial<MessageProfileValues>>
	| null
	| undefined;

export function subSelectDatatype(profile: NullableProfile) {
	return profile?.get("datentyp") || null;
}

export function subSelectDatatypeName(profile: NullableProfile) {
	return profile?.getIn(["datentyp", "name"]) || null;
}

export function subSelectDatatypeDatatype(profile: NullableProfile) {
	return profile?.getIn(["datentyp", "datentyp"]) || null;
}

export function subSelectRestrictionId(
	datatypes: DatatypeMap,
	datatypeQName: QName,
	restrictionName: string,
) {
	const restrictions = datatypes.get(datatypeQName)?.get("restrictions");
	const restriction =
		restrictions?.find((res) => res.get("name") === restrictionName) ?? null;
	return restriction?.get("id") ?? null;
}
export function subSelectRecursion(profile: NullableProfile) {
	return profile?.get("rekursion") ?? null;
}
export function subSelectRecursionLimit(profile: NullableProfile) {
	return subSelectRecursion(profile)?.get("limit") ?? null;
}
export function subSelectRecursionDesc(profile: NullableProfile) {
	return subSelectRecursion(profile)?.get("beschreibung") ?? null;
}
export function subSelectUsedProperties(profile: NullableProfile) {
	return profile?.get("eigenschaften") || null;
}
export function subSelectContainsProperties(
	profile: NullableProfile,
	propertyNameTechnisch: string,
) {
	return profile?.hasIn(["eigenschaften", propertyNameTechnisch]) ?? false;
}
export function subSelectUsedPropertySeq(profile: NullableProfile) {
	return subSelectUsedProperties(profile)?.valueSeq() || Seq.Indexed();
}
export function subSelectSelectableProperties(
	profile: NullableProfile,
	properties: PropertyValues[],
) {
	const usedProperties = subSelectUsedProperties(profile);
	return properties.filter(
		(prop) =>
			!usedProperties
				?.map((propProfile) => propProfile.get("name"))
				?.includes(prop.nameTechnisch),
	);
}
export function subSelectPropertyById(
	properties: PropertyValues[] | null | undefined,
	nameTechnisch: string,
) {
	return (
		properties?.find((prop) => prop.nameTechnisch === nameTechnisch) || null
	);
}
export function subSelectDatatypeRestrictionProfile(
	datatypes: DatatypeMap,
	datatypeId: QName,
	restrictionId: RestrictionId,
) {
	return (
		datatypes
			.get(datatypeId)
			?.get("restrictions")
			?.get(restrictionId)
			?.get("profile")
			?.get(QNamePathSchema.parse(datatypeId)) ?? null
	);
}
export function subSelectCodelist(profile: NullableProfile) {
	return profile?.getIn(["konfiguration", "codeliste"]) ?? null;
}
export function subSelectCodelistValues(profile: NullableProfile) {
	return profile?.getIn(["konfiguration", "codeliste", "werte"]) ?? null;
}
export function subSelectCodelistType(profile: NullableProfile) {
	return profile?.getIn(["konfiguration", "codeliste", "typ"]) ?? null;
}

export function subSelectCodelistIdentifier(profile: NullableProfile) {
	return profile?.getIn(["konfiguration", "codeliste", "kennung"]) ?? null;
}

export function subSelectCodelistVersion(profile: NullableProfile) {
	return profile?.getIn(["konfiguration", "codeliste", "version"]) ?? null;
}

export function subSelectHasCodelistValueOverlap(
	profileA: NullableProfile,
	profileB: NullableProfile,
): boolean {
	const valuesA = subSelectCodelistValues(profileA);
	const valuesB = subSelectCodelistValues(profileB);
	return !!(valuesA && valuesA.length > 0 && valuesB && valuesB.length > 0);
}

export const EMPTY_SELECTABILITY_STATUS = {
	code: SelectablilityCode.None as const,
};
export function subSelectSelectabilityStatus({
	parentProfileId,
	childProfileId,
	refProfile,
	activeRestriction,
	refRestrictionId,
}: {
	parentProfileId: string | null;
	childProfileId: string | null;
	refProfile: ImmutableMap<Partial<MessageProfileValues>> | null | undefined;
	activeRestriction: ImmutableMap<RestrictionProfileValues> | null | undefined;
	refRestrictionId: string | null;
}): SelectablilityStatus {
	if (!activeRestriction) return { code: SelectablilityCode.StandardView };
	if (parentProfileId) {
		return {
			profileId: parentProfileId,
			code: SelectablilityCode.Parent as const,
		};
	}
	if (childProfileId) {
		return {
			profileId: childProfileId,
			code: SelectablilityCode.Child as const,
		};
	}
	// Check if the node is profiled by a different restriction of the same
	// datatype
	const dtRef = subSelectDatatype(refProfile);
	const dtRefName = dtRef?.get("name");
	if (
		dtRef &&
		activeRestriction &&
		dtRefName !== undefined &&
		dtRefName !== activeRestriction.get("name") &&
		refRestrictionId !== null
	) {
		return {
			code: SelectablilityCode.SiblingRestriction,
			restrictionName: dtRefName,
			restrictionId: refRestrictionId,
		};
	}
	if (subSelectCodelist(refProfile)) {
		return { code: SelectablilityCode.CodelistValues };
	}
	return EMPTY_SELECTABILITY_STATUS;
}
export function subSelectRestrictionProfileSeq(
	datatype: ImmutableMap<Partial<DatatypeProfileValues>>,
) {
	const restrictionEntries =
		datatype
			.get("restrictions")
			?.entrySeq()
			.map(([k, v]) => [k, v] as const) ?? Seq.Indexed();
	return restrictionEntries.flatMap<ProfileSeqEntry>(
		([currentRestrictionId, restriction]) => {
			const profiles = restriction.get("profile");
			const restrictionName = restriction.get("name");
			if (!profiles || profiles.isEmpty()) return Seq.Indexed();
			return profiles.entrySeq().map(([profileId, profile]) => ({
				restrictionId: currentRestrictionId,
				restrictionName,
				profileId,
				profile,
			}));
		},
	);
}

export function subSelectHasZeroCardinality(profile: NullableProfile) {
	return (
		profile?.get("lowerBound") === "0" && profile.get("upperBound") === "0"
	);
}

export function subSelectIsProfiled(
	datatypes: DatatypeMap | undefined,
	qname: QName,
) {
	const restrictions = datatypes?.get(qname)?.get("restrictions");
	return !!(restrictions && !restrictions.isEmpty());
}

export function subSelectConfigurationField(
	profile: NullableProfile,
	fieldName: keyof MessageProfileConfiguration,
) {
	return profile?.get("konfiguration")?.get(fieldName);
}
