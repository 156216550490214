import {
	INDEX_PROP,
	NAME_PROP,
	VALUE_PROP,
	helperBag,
	isMarkupNodeType,
} from "../../helpers";
import type { MarkupNode, RendererPropKey } from "../../types";
import { MarkupNodeType } from "../../types";
import type { RenderHelpers } from "../types";

export function getNodeAttribute(
	node: MarkupNode,
	rendererProp: RendererPropKey,
) {
	return (
		(node &&
			isMarkupNodeType(node, MarkupNodeType.Node) &&
			node[rendererProp] &&
			node[rendererProp]?.toString()) ||
		null
	);
}

export function getNodeName(node: MarkupNode) {
	return getNodeAttribute(node, NAME_PROP);
}

export function getNodeValue(node: MarkupNode) {
	return getNodeAttribute(node, VALUE_PROP);
}

export function getNodeIndex(node: MarkupNode) {
	return getNodeAttribute(node, INDEX_PROP);
}

export const mockedRenderHelpers: RenderHelpers = {
	getMessageId: () => "",
	...helperBag,
	render() {},
	onMount() {},
	onUnmount() {},
};
