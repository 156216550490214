import type {
	Geschaeftsregel,
	LiteNode,
} from "../../../lib/validation/lite/LiteSchemas";
import type { ValidationResult } from "../../../components/AppActor/actors/modellierungModel/validation/validators/types";
import { ValidationTargetField } from "../../../components/AppActor/actors/modellierungModel/validation/validators/types";
import ValidationDisplayLite from "../../../components/ValidationDisplayLite";
import type { LiteId } from "../../../lib/validation/lite/IDSchemas";

// /* Returns the validation Error only for specific items of the array */
const getKennungValidationErrors = <TNode extends LiteNode = LiteNode>({
	geschaeftsregel,
	validationResults,
	activeNode,
	activePath,
}: {
	geschaeftsregel: Geschaeftsregel;
	validationResults: ValidationResult<ValidationTargetField.GeschaeftsregelKennung>[];
	activeNode?: TNode;
	activePath: LiteId[];
}): JSX.Element => {
	let returnElement: JSX.Element = <></>;

	if (
		validationResults &&
		validationResults.find(
			(validationResult) =>
				validationResult.ruleId === "schema-unique-geschaeftsregeln",
		)
	) {
		const results = activeNode?.geschaeftsregeln?.filter((regel) => {
			return regel.kennung === geschaeftsregel.kennung;
		});
		if (results && results?.length > 1) {
			returnElement = (
				<ValidationDisplayLite
					locator={{
						path: activePath,
						targetField: ValidationTargetField.GeschaeftsregelKennung,
					}}
				/>
			);
		}
	}
	return returnElement;
};
export default getKennungValidationErrors;
