type DropddownFieldConfig = {
	name: string;
	options: { value: string; label: string }[];
	default?: string;
}[];

// eslint-disable-next-line import/prefer-default-export
export const konfigurationDropdownFields: DropddownFieldConfig = [
	{
		name: "elementFormDefault",
		options: [
			{ value: "qualified", label: "qualified" },
			{ value: "unqualified", label: "unqualified" },
		],
		default: "qualified",
	},
	{
		name: "standardeinstellungEigenschaften",
		options: [
			{ value: "Element", label: "Element" },
			{ value: "kein Wert", label: "kein Wert" },
		],
		default: "Element",
	},
	{
		name: "benannterCodelistenDatentyp",
		options: [
			{ value: "true", label: "true" },
			{ value: "false", label: "false" },
		],
		default: "true",
	},
	{
		name: "codesSchemavalidiert",
		options: [
			{ value: "true", label: "true" },
			{ value: "false", label: "false" },
		],
		default: "true",
	},
	{
		name: "wsdlStandardaufbauSubjectOperation",
		options: [
			{
				value: "ModelPrefixStandardVersion",
				label: "ModelPrefixStandardVersion",
			},
			{ value: "ModelPrefixModulVersion", label: "ModelPrefixModulVersion" },
		],
		default: "ModelPrefixStandardVersion",
	},
	{
		name: "wsdlDateipraefix",
		options: [
			{
				value: "keinPraefix",
				label: "keinPraefix",
			},
			{ value: "ModelName", label: "ModelName" },
		],
		default: "keinPraefix",
	},
];
