import type { LiteNode } from "../../../../lib/validation/lite/LiteSchemas";
import { LiteNodeType } from "../../../../lib/validation/lite/LiteEnums";

export default class UnknownLiteNodeTypeError extends Error {
	constructor(node: LiteNode) {
		super(
			`Unknown node encountered. Expected node of type ` +
				`${Object.values(LiteNodeType)}. ` +
				`Found:\n${JSON.stringify(node, null, 2)}`,
		);
	}
}
