import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import type {
	GridColDef,
	GridRenderCellParams,
	GridSelectionModel,
} from "@mui/x-data-grid";
import { DataGrid, deDE } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
	selectIsTreeAvailable,
	selectMessages,
} from "../../../redux/treeSlice";
import { RequestStatus } from "../../Api/types";
import { useStateSelector } from "../../EditorState";
import {
	selectIsMessageProfiled,
	selectState,
} from "../../EditorState/selectors";
import { useTreeRequest } from "../../TreeStructureProvider/TreeStructureProvider";
import { GridCellExpand, QuickFilterToolbar } from "../../ui/mui";
import {
	QNameSchema,
	type QName,
} from "../../../lib/validation/lite/LiteSchemas";

interface RowModel {
	id: QName;
	name: string;
	number: number;
}

const renderElementName = (params: GridRenderCellParams<string>) => (
	<GridCellExpand
		value={params.value || ""}
		width={params.colDef.computedWidth}
	/>
);
const columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Alle auswählen",
		hideable: false,
		renderCell: renderElementName,
		flex: 1,
	},
];

const MessageSelection = ({
	standard,
	headerTitle,
	selectionModel,
	handleSelectionChange,
}: {
	standard: string;
	headerTitle: string;
	selectionModel: QName[];
	handleSelectionChange: (newGridSelection: GridSelectionModel) => void;
}): JSX.Element => {
	const state = useStateSelector(selectState());
	const messages = useAppSelector(selectMessages(standard));

	const isStandardAvailable = useAppSelector(selectIsTreeAvailable(standard));

	const { requestTree, treeStatus } = useTreeRequest();

	const isLoading = treeStatus === RequestStatus.Loading;

	useEffect(() => {
		if (!isStandardAvailable) {
			requestTree({ standard });
		}
	}, [isStandardAvailable, requestTree, standard]);

	const [filterMessages, setFilterMessages] = useState(false);

	const rows: RowModel[] =
		messages
			?.filter(({ qnamePath }) =>
				filterMessages ? selectIsMessageProfiled(qnamePath)(state) : true,
			)
			?.map(({ node: msg, qnamePath }) => ({
				id: QNameSchema.parse(qnamePath),
				name: msg.name || "",
				number: parseInt(msg.id.split(".").pop() as string, 10),
			}))
			.sort((a, b) => a.number - b.number) || [];

	const handleFilterChange = () => {
		setFilterMessages((prevState) => !prevState);
	};

	return (
		<>
			<h3>{headerTitle}</h3>
			<FormGroup>
				<FormControlLabel
					control={
						<Switch checked={filterMessages} onChange={handleFilterChange} />
					}
					label="Nur eingeschränkte Nachrichten anzeigen"
				/>
			</FormGroup>
			<DataGrid
				loading={isLoading}
				columns={columns}
				rows={rows}
				selectionModel={selectionModel}
				onSelectionModelChange={handleSelectionChange}
				components={{ Toolbar: QuickFilterToolbar }}
				localeText={{
					noRowsLabel: "Keine Nachrichten verfügbar",
					...deDE.components.MuiDataGrid.defaultProps.localeText,
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: "number", sort: "asc" }],
					},
					pagination: {
						pageSize: 25,
					},
				}}
				componentsProps={{
					baseCheckbox: { "data-testid": "message-selection-checkbox" },
				}}
				checkboxSelection
			/>
		</>
	);
};

export default MessageSelection;
