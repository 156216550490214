import classNames from "classnames";
import type { Markup } from "../../../types";
import notifications from "../../../../../../resources/textConstants/notifications.json";
import styles from "./UnknownMarkup.module.scss";

const UnknownMarkup = ({
	markup,
	className,
}: {
	markup: Markup;
	className?: string;
}): JSX.Element => {
	return (
		<span
			className={classNames(styles["xoev-markup--unknown"], className)}
			data-testid="unknown-markup"
		>
			{`${notifications.messages.unknown.markup} ${JSON.stringify(markup)}`}
		</span>
	);
};

export default UnknownMarkup;
