import { useProfilierungValidationResults } from "../hooks";
import type {
	AutoFixValue,
	FixerFn,
	Locator,
	ValidationResultGroup,
	ValidationTargetFieldProfiling,
} from "../types";
import ValidationFormatter from "../ValidationFormatter";

function ValidationDisplay<
	ResultGroup extends ValidationResultGroup,
	TargetField extends ValidationTargetFieldProfiling = ValidationTargetFieldProfiling,
>({
	resultGroup,
	locator,
	value,
	onFix,
}: {
	resultGroup: ResultGroup | ResultGroup[];
	locator?: Locator<ResultGroup, TargetField>;
	value?: AutoFixValue<TargetField>;
	onFix?: (fixer: FixerFn<TargetField>) => void;
}): JSX.Element | null {
	const results = useProfilierungValidationResults<ResultGroup, TargetField>(
		resultGroup,
		locator,
	);
	return results.length > 0 ? (
		<ValidationFormatter<TargetField>
			results={results}
			value={value}
			onFix={onFix}
		/>
	) : null;
}

export default ValidationDisplay;
