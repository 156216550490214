import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { placeholder } from "../resources/textConstants";
import type { RootState } from "./store";
import { memoize, weakMemoize } from "../utils/memoization";
import type { Nullish } from "../utils/types";
import type {
	LiteBaustein,
	LiteNode,
	QName,
	QNamePath,
	RawLiteModel,
} from "../lib/validation/lite/LiteSchemas";
import type { LiteId } from "../lib/validation/lite/IDSchemas";
import { parseQNamePath } from "../lib/validation/lite/helpers";
import { isLiteBaustein } from "../lib/validation/lite/TypeGuards";
import {
	ProjektDbIdSchema,
	ProjektIdSchema,
} from "../lib/validation/lite/IDSchemas";
import { LiteNodeType, ProjektType } from "../lib/validation/lite/LiteEnums";
import { createLiteProject } from "../components/AppActor/actors/modellierungModel/helpers";
import type { LiteModellContainer } from "../components/AppActor/actors/modellierungModel/types";
import {
	createSelectChildrenFromModell,
	createSelectDefinitionPathFromModell,
	createSelectIsRecursiveFromModell,
	selectBausteinQNameMapFromModell,
	selectDatatypeOrReferenceFromModell,
	selectDatatypesFromModell,
	selectIdPathFromQNamePathFromModell,
	selectIsCodelisteFromModell,
	selectIsDefiniedInStandard,
	selectNachrichtenFromModell,
	selectOwnDatatypesFromModell,
	selectQNameFromModell,
	selectQNamePathFromModell,
	selectReferencedDatatypeFromModell,
	selectRootModellFromModell,
} from "../components/AppActor/actors/modellierungModel/selectors";
import { filterFalsy } from "../utils/lists";
import { encodeXPath } from "../utils/url";

const TREE_SELECTOR_MEMO_CONFIG = { cacheSize: 50 };
function defaultMemo<Fn extends Parameters<typeof memoize>[0]>(fn: Fn) {
	return memoize(fn, TREE_SELECTOR_MEMO_CONFIG);
}

export type TreeState = {
	[standard in string]?: {
		modell: LiteModellContainer;
		iteration: number;
	} | null;
};

const initialState: TreeState = {};

const treeSlice = createSlice({
	name: "tree",
	initialState,
	reducers: {
		setTree(
			state,
			action: PayloadAction<{ standard: string; rawModel: RawLiteModel }>,
		) {
			const { standard, rawModel } = action.payload;
			const { modell } = createLiteProject(
				rawModel,
				{
					dbId: ProjektDbIdSchema.parse(0),
					kennung: rawModel.kennung,
					projektId: ProjektIdSchema.parse(standard),
					projektType: ProjektType.Modellierung,
				},
				"Neues Modellierungsprojekt",
			);
			// eslint-disable-next-line no-param-reassign
			state[standard] = {
				modell: modell as never,
				iteration: (state[standard]?.iteration ?? 0) + 1,
			};
		},
	},
});

export const { setTree } = treeSlice.actions;

export const selectNodePoolIteration =
	(standard: Nullish<string>) => (state: RootState) =>
		standard ? state.tree[standard]?.iteration ?? -1 : -1;

export const selectTree = (standard: Nullish<string>) => (state: RootState) =>
	standard ? state.tree[standard] : null;

export const selectIsTreeAvailable =
	(standard: Nullish<string>) => (state: RootState) =>
		!!selectTree(standard)(state);

export const selectModellContainer =
	(standard: Nullish<string>) =>
	(state: RootState): LiteModellContainer | null =>
		standard ? state.tree[standard]?.modell ?? null : null;

const selectNodeFromModell = (
	modell: Nullish<LiteModellContainer>,
	nodeId: Nullish<LiteId>,
): LiteNode | null => {
	if (!modell?.nodes || !nodeId) return null;
	return (
		modell.nodes.getIn([LiteNodeType.Eigenschaft, nodeId]) ||
		modell.nodes.getIn([LiteNodeType.Baustein, nodeId]) ||
		modell.nodes.getIn([LiteNodeType.Paket, nodeId]) ||
		modell.nodes.getIn([LiteNodeType.Model, nodeId]) ||
		null
	);
};

export const selectNode =
	(standard: Nullish<string>, nodeId: Nullish<LiteId>) =>
	(state: RootState): LiteNode | null =>
		standard
			? selectNodeFromModell(selectModellContainer(standard)(state), nodeId)
			: null;

const selectRootNodeFromNodePool = (
	modell: Nullish<LiteModellContainer>,
): LiteNode | null => (modell ? selectRootModellFromModell(modell) : null);
export const selectRootNode =
	(standard: Nullish<string>) =>
	(state: RootState): LiteNode | null =>
		selectRootNodeFromNodePool(selectModellContainer(standard)(state));

// We don't really need to memoize `selectNodes` for perfomance reasons, since
// it is a pretty cheap operation, but it always creates a new array, even if
// the inputs are the same. This can create unnecessary renders and re
// executions of hooks
const memoizedSelectNodesFromModell = defaultMemo(
	(
		modell: Nullish<LiteModellContainer>,
		nodeIds: Nullish<LiteId[]>,
	): (LiteNode | null)[] => {
		if (!modell || !nodeIds) return [];
		return nodeIds.map((nodeId) => {
			const node = selectNodeFromModell(modell, nodeId);
			return node || null;
		});
	},
);
export const selectNodesFromModell = (
	modell: Nullish<LiteModellContainer>,
	nodeIds: Nullish<LiteId[]>,
): (LiteNode | null)[] => memoizedSelectNodesFromModell(modell, nodeIds);
export const selectNodes =
	(standard: Nullish<string>, nodeIds: Nullish<LiteId[]>) =>
	(state: RootState): (LiteNode | null)[] =>
		memoizedSelectNodesFromModell(
			selectModellContainer(standard)(state),
			nodeIds,
		);

export const selectChildrenFromModell = (
	modell: Nullish<LiteModellContainer>,
	nodeId: Nullish<LiteId>,
	path: Nullish<LiteId[]>,
): LiteNode[] | null => {
	const node = selectNodeFromModell(modell, nodeId);
	if (!modell || !nodeId || !path || !node) return null;
	return createSelectChildrenFromModell(modell)(node, path);
};

export const selectChildren =
	(
		standard: Nullish<string>,
		nodeId: Nullish<LiteId>,
		path: Nullish<LiteId[]>,
	) =>
	(state: RootState): LiteNode[] | null =>
		selectChildrenFromModell(
			selectModellContainer(standard)(state),
			nodeId,
			path,
		);

export const selectDatatypes =
	(standard: Nullish<string>) =>
	(state: RootState): LiteNode[] | null => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return null;
		return selectDatatypesFromModell(modell);
	};
export const selectOwnDatatypes =
	(standard: Nullish<string>) =>
	(state: RootState): LiteNode[] | null => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return null;
		return selectOwnDatatypesFromModell(modell);
	};

export const selectIsDataypeInStandard =
	(standard: Nullish<string>, datatypeId: Nullish<LiteId>) =>
	(state: RootState): boolean => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return false;
		return selectIsDefiniedInStandard(modell, datatypeId);
	};

export const selectNodeParentsFromModell = defaultMemo(
	(modell: Nullish<LiteModellContainer>, nodeId: LiteId): LiteId[] | null => {
		if (!modell) return null;
		return createSelectDefinitionPathFromModell(modell)(nodeId);
	},
);

// Constructing a set from lots of ids is not something we want to do on every
// function call, so we memoize the function
const createDatatypeIdSet = weakMemoize(
	(ids: LiteId[]): Set<LiteId> => new Set(ids),
);
// Always fall back to the same empty list, so the memoization is more efficient
const emptyDatatypeIds: LiteId[] = [];
const selectDatatypeIdsFromModell = memoize(
	(modell: Nullish<LiteModellContainer>): LiteId[] => {
		if (!modell) return emptyDatatypeIds;
		const datatypes = selectDatatypesFromModell(modell);
		if (!datatypes) return emptyDatatypeIds;
		return datatypes.map((node) => node.id);
	},
);
export const selectIsDatatypeFromModell = (
	modell: Nullish<LiteModellContainer>,
	standard: Nullish<string>,
	id: Nullish<LiteId>,
): boolean => {
	if (!id || !standard || !modell) return false;
	const datatypeIds = selectDatatypeIdsFromModell(modell);
	const datatypeIdSet = createDatatypeIdSet(datatypeIds);
	return datatypeIdSet.has(id);
};
export const selectIsDatatype =
	(standard: string, id: Nullish<LiteId>) =>
	(state: RootState): boolean =>
		selectIsDatatypeFromModell(
			selectModellContainer(standard)(state),
			standard,
			id,
		);

export const selectReferencedDatatype =
	(standard: Nullish<string>, node: Nullish<LiteNode>) =>
	(state: RootState): LiteBaustein | null => {
		const modell = selectModellContainer(standard)(state);
		if (!node || !modell) return null;
		return selectReferencedDatatypeFromModell(modell, node);
	};

export const selectDatatypeOrReference =
	(standard: Nullish<string>, node: Nullish<LiteNode>) =>
	(state: RootState): LiteBaustein | null => {
		const modell = selectModellContainer(standard)(state);
		if (!node || !modell) return null;
		return selectDatatypeOrReferenceFromModell(modell, node);
	};

export const selectIsCodeliste =
	(standard: Nullish<string>, node: Nullish<LiteNode>) =>
	(state: RootState): boolean => {
		const modell = selectModellContainer(standard)(state);
		if (!node || !modell) return false;
		return selectIsCodelisteFromModell(modell, node);
	};

export const selectIsRekursionStart =
	(standard: Nullish<string>, node: Nullish<LiteNode>) =>
	(state: RootState): boolean => {
		const modell = selectModellContainer(standard)(state);
		if (!node || !modell) return false;
		const datatype = selectReferencedDatatype(standard, node)(state);
		const isDatatypeRecursive = datatype
			? createSelectIsRecursiveFromModell(modell)(datatype)
			: false;
		const isNodeRecursive = createSelectIsRecursiveFromModell(modell)(node);
		return isDatatypeRecursive || isNodeRecursive;
	};

export const selectQNamePath =
	(standard: Nullish<string>, path: LiteId[]) =>
	(state: RootState): QNamePath | null => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return null;
		return selectQNamePathFromModell(modell, path);
	};

export const selectIdPathFromQNamePath =
	(standard: Nullish<string>, qnamePath: Nullish<QNamePath>) =>
	(state: RootState): LiteId[] | null => {
		const modell = selectModellContainer(standard)(state);
		if (!modell || !qnamePath) return null;
		return selectIdPathFromQNamePathFromModell(
			modell,
			parseQNamePath(qnamePath),
		);
	};

export const selectDefinitionPath =
	(standard: Nullish<string>, nodeId: LiteId) =>
	(state: RootState): LiteId[] => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return [];
		return createSelectDefinitionPathFromModell(modell)(nodeId);
	};

export type NachrichtenDef = {
	node: LiteNode;
	path: LiteId[];
	qnamePath: QNamePath;
};
// To find all message nodes, we need to traverse the whole tree, since we
// don't know how deeply nested these nodes are. We also cannot return
// early when we have found a message node by just collecting all its
// siblings, because it is not required for all message nodes to be part of
// a single package node. This is an expensive calculation, so memoizing it
// is essential
const memoizedSelectMessagesFromModell = defaultMemo(
	(modell: Nullish<LiteModellContainer>): NachrichtenDef[] => {
		if (!modell) return [];
		return selectNachrichtenFromModell(modell).map((node) => {
			const path = createSelectDefinitionPathFromModell(modell)(node.id);
			return { node, path, qnamePath: selectQNamePathFromModell(modell, path) };
		});
	},
);
export const selectMessagesFromModell = (
	modell: Nullish<LiteModellContainer>,
): NachrichtenDef[] => memoizedSelectMessagesFromModell(modell);
export const selectMessages =
	(standard: Nullish<string>) =>
	(state: RootState): NachrichtenDef[] =>
		memoizedSelectMessagesFromModell(selectModellContainer(standard)(state));

export const selectQName =
	(standard: Nullish<string>, node: Nullish<LiteNode>) =>
	(state: RootState): QName | null => {
		const modell = selectModellContainer(standard)(state);
		if (!modell || !node) return null;
		return selectQNameFromModell(modell, node);
	};

export const selectBausteinIdByQName =
	(standard: Nullish<string>, qname: QName) =>
	(state: RootState): LiteId | null => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return null;
		const bausteinMap = selectBausteinQNameMapFromModell(modell);
		return bausteinMap.get(qname)?.id ?? null;
	};

type BreadcrumbLinkDef = { name: string; link: string };
export const selectNachrichtenBreadcrumbsFromModell = memoize(
	(
		modell: LiteModellContainer,
		path: LiteId[],
		basePath: string,
	): BreadcrumbLinkDef[] => {
		const nodes = filterFalsy(selectNodesFromModell(modell, path));
		return nodes
			.map((node) => ({
				name: node.name || placeholder.anonymousStructure,
				qname: selectQNameFromModell(modell, node),
			}))
			.map(({ name }, i, list): BreadcrumbLinkDef => {
				const segments = list.slice(0, i + 1).map((item) => item.qname);
				const link = `${basePath}${encodeXPath(segments.join("/"))}`;
				return { name, link };
			});
	},
);
const EMPTY_BREADCRUMB_LINKS: BreadcrumbLinkDef[] = [];
export const selectNachrichtenBreadcrumbs =
	(standard: Nullish<string>, path: LiteId[], basePath: string) =>
	(state: RootState): BreadcrumbLinkDef[] => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return EMPTY_BREADCRUMB_LINKS;
		return selectNachrichtenBreadcrumbsFromModell(modell, path, basePath);
	};

export const selectRestrictionBreadcrumbsFromModell = memoize(
	(
		modell: LiteModellContainer,
		path: LiteId[],
		basePath: string,
	): BreadcrumbLinkDef[] => {
		const nodes = filterFalsy(selectNodesFromModell(modell, path));
		const bausteinIndex = nodes.findIndex((node) => isLiteBaustein(node));
		const restrictionPath = nodes.slice(bausteinIndex).map((node) => node.id);
		return selectNachrichtenBreadcrumbsFromModell(
			modell,
			restrictionPath,
			basePath,
		).slice(1);
	},
);
export const selectRestrictionBreadcrumbs =
	(standard: Nullish<string>, path: LiteId[], basePath: string) =>
	(state: RootState): BreadcrumbLinkDef[] => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return EMPTY_BREADCRUMB_LINKS;
		return selectRestrictionBreadcrumbsFromModell(modell, path, basePath);
	};

export default treeSlice.reducer;
