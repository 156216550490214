export const checkBooleanStringValue = (value: string | boolean) => {
	if (value === "true") {
		return true;
	}
	if (value === "false") {
		return false;
	}
	return value;
};

export const isValidUrl = (urlString: string) =>
	// eslint-disable-next-line @microsoft/sdl/no-insecure-url
	!!(urlString.startsWith("http://") || urlString.startsWith("https://"));
