import { subSelectRecursionLimit } from "../../../EditorState/subSelectors";
import type { ProfileRule } from "../../types";
import { ValidationTargetFieldProfiling } from "../../types";
import { addTargetField } from "../helpers";

const validRecursionRegex = /^(\*|\d+)$/;

const recursionRules = addTargetField<
	ValidationTargetFieldProfiling.RecursionLimit,
	ProfileRule<ValidationTargetFieldProfiling.RecursionLimit>
>(ValidationTargetFieldProfiling.RecursionLimit, [
	{
		id: "recursion-is-valid-number",
		target: ["rekursion"],
		isValid({ profile }) {
			const recursionLimit = subSelectRecursionLimit(profile)?.trim();
			// The recursion is not a required field, so an empty value is fine
			if (!recursionLimit) return true;
			return validRecursionRegex.test(recursionLimit);
		},
		message:
			"Die angegebene Rekursionsgrenze ist nicht valide, bitte geben Sie " +
			'nur ganze, positive Zahlen oder "*" an.',
	},
	{
		id: "recursion-has-no-surrounding-whitespace",
		target: ["rekursion"],
		isValid({ profile }) {
			const recursionLimit = subSelectRecursionLimit(profile);
			// The recursion is not a required field, so an empty value is fine
			if (!recursionLimit) return true;
			return recursionLimit === recursionLimit.trim();
		},
		message:
			"Die Rekursionsgrenze darf keine umgebenden Leerzeichen enthalten.",
		autoFix: (invalidValue) => () => invalidValue.trim(),
		autoFixDescription: "Umgebende Leerzeichen entfernen",
	},
]);

export default recursionRules;
