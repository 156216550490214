import type { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MenuItem } from "@xoev/ui";
import { useActiveProjectId } from "../../AppActor/actors/navigation/hooks";
import { useSendStoreEvent } from "../../AppActor/EventStore/hooks";
import {
	useModellierungModellSelectorById,
	useProjektBaseSegmentById,
	useTabName,
} from "../../AppActor/actors/modellierungModel/hooks";
import type { ProjektId } from "../../../lib/validation/lite/IDSchemas";
import { ProjektType } from "../../../lib/validation/lite/LiteEnums";
import "./ProjectTab.scss";
import StandardIcon from "../../../resources/icons/StandardIcon";
import { ModellierungIcon } from "../../../resources/iconMaps/icons";

const ProjectTab = ({ projektId }: { projektId: ProjektId }): JSX.Element => {
	const activeProjektId = useActiveProjectId();
	const baseSegment = useProjektBaseSegmentById(projektId);
	const isProjectActive = activeProjektId === projektId;
	const send = useSendStoreEvent();
	const tabName = useTabName(projektId);
	const projektMeta = useModellierungModellSelectorById(
		projektId,
		({ projekt }) => ({ kennung: projekt.kennung, projektType: projekt.type }),
	);

	const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		send({ type: "PROJECT.CLOSE", payload: { projektId } });
	};

	const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
		if (!projektMeta) return;
		e.preventDefault();
		const { kennung, projektType } = projektMeta;
		send({ type: "GOTO.TAB", payload: { projektId, kennung, projektType } });
	};

	return (
		<MenuItem className="project-tab">
			<MenuItem.Link
				as={Link}
				to={`${baseSegment}/metadaten/standard`}
				onClick={handleClick}
				isActive={isProjectActive}
				className="project-tab__link"
				data-testid="project-tab"
				data-project-id={projektId}
				data-project-name={tabName}
			>
				{projektMeta && projektMeta.projektType === ProjektType.Standard && (
					<StandardIcon sx={{ fill: "#FF7923", marginRight: "8px" }} />
				)}
				{projektMeta &&
					projektMeta.projektType === ProjektType.Modellierung && (
						<ModellierungIcon sx={{ fill: "#FF7923", marginRight: "8px" }} />
					)}
				{tabName}
				<IconButton
					color="inherit"
					size="small"
					aria-label="Tab schließen"
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</MenuItem.Link>
		</MenuItem>
	);
};

export default ProjectTab;
