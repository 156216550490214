import { useCallback, useState } from "react";
import type { LiteNode } from "../../lib/validation/lite/LiteSchemas";
import {
	isLiteBaustein,
	isLitePaket,
	isLiteSchema,
} from "../../lib/validation/lite/TypeGuards";
import { LiteBausteinType } from "../../lib/validation/lite/LiteEnums";
import {
	type BausteinSchemaFilter,
	type BausteinSchemaFilters,
	LiteBausteinFilter,
} from "./types";

const allFilters = [
	LiteBausteinType.Datentyp,
	LiteBausteinType.CodeDatentyp,
	LiteBausteinType.Nachricht,
	LiteBausteinType.GlobaleEigenschaft,
];

export function useTreeFilters() {
	const [activeFilters, setActiveFilters] =
		useState<BausteinSchemaFilters>(allFilters);

	const toggleFilter = useCallback(
		(...nodeTypes: BausteinSchemaFilters) => {
			nodeTypes.forEach((nodeType) => {
				const index = activeFilters.indexOf(nodeType);

				if (index === -1) {
					setActiveFilters([...activeFilters, nodeType]);
				} else {
					setActiveFilters([
						...activeFilters.slice(0, index),
						...activeFilters.slice(index + 1),
					]);
				}
			});
		},
		[activeFilters, setActiveFilters],
	);

	const removeFilter = useCallback(
		(nodeType: BausteinSchemaFilter) => {
			setActiveFilters(activeFilters.filter((el) => el !== nodeType));
		},
		[activeFilters, setActiveFilters],
	);

	return { activeFilters, toggleFilter, removeFilter };
}

export default function filterTreeNodes<TNode extends LiteNode>(
	nodes: TNode[],
	filters: BausteinSchemaFilters = allFilters,
) {
	return nodes.filter((node) => {
		if (
			filters.includes(LiteBausteinFilter.Leer) &&
			(isLiteSchema(node) || isLitePaket(node))
		) {
			return node.children.length > 0;
		}

		if (isLiteBaustein(node) && allFilters.includes(node.typ)) {
			return filters.includes(node.typ);
		}

		return true;
	});
}
