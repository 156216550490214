import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import { createCommandId, createQueryId } from "./helpers";
import type { ModellierungModellCommandActorRef } from "./modellierungModel.command.machine";
import modellierungModellCommandMachine from "./modellierungModel.command.machine";
import type { ModellierungModellQueryActorRef } from "./modellierungModel.query.machine";
import modellierungModellQueryMachine from "./modellierungModel.query.machine";
import type { ProjektMeta, StandardProjekt } from "../project/types";
import type { ValidationActorRef } from "./validation/validation.machine";
import validationMachine from "./validation/validation.machine";
import type { RawLiteModel } from "../../../../lib/validation/lite/LiteSchemas";
import type { ModellierungSearchActorRef } from "./search/modellierungSearch.machine";
import modellierungSearchMachine from "./search/modellierungSearch.machine";
import type { ConfirmationActorRef } from "./confirmation/confirmation.machine";
import confirmationMachine from "./confirmation/confirmation.machine";

export type ModellierungModellActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof modellierungModellMachine
>;

const modellierungModellMachine = setup({
	types: {
		context: {} as ProjektMeta & {
			query: ModellierungModellQueryActorRef;
			command: ModellierungModellCommandActorRef;
			validation: ValidationActorRef;
			search: ModellierungSearchActorRef;
			confirmation: ConfirmationActorRef;
		},
		input: {} as ProjektMeta & {
			projekt: StandardProjekt;
			rawModel: RawLiteModel;
		},
	},
	actors: {
		query: modellierungModellQueryMachine,
		command: modellierungModellCommandMachine,
		validation: validationMachine,
		search: modellierungSearchMachine,
		confirmation: confirmationMachine,
	},
}).createMachine({
	id: "modellierungModell",
	context: ({ input, spawn }) => ({
		...input,
		query: spawn("query", {
			systemId: createQueryId(input.projektId),
			id: createQueryId(input.projektId),
			input,
		}),
		command: spawn("command", {
			systemId: createCommandId(input.projektId),
			id: createCommandId(input.projektId),
			input,
		}),
		validation: spawn("validation", {
			systemId: `validation:${input.projektId}`,
			input,
		}),
		search: spawn("search", {
			systemId: `modellierungSearch:${input.projektId}`,
			id: `modellierungSearch:${input.projektId}`,
			input,
		}),
		confirmation: spawn("confirmation", {
			systemId: `confirmation:${input.projektId}`,
			input,
		}),
	}),
});

export default modellierungModellMachine;
