import { IconButton, Tooltip } from "@mui/material";
import {
	validationIcons,
	validationSuccessIcon,
	validationLabels,
} from "../../ProfilierungValidation/helpers";
import type { MessageType } from "../MessageOverview/types";
import { Severity } from "../../ProfilierungValidation/types";

const ValidationIcon = ({
	message,
	handleInfoClick,
}: {
	message: MessageType;
	handleInfoClick: () => void;
}): JSX.Element => {
	const getSeverity = (): Severity | "success" => {
		const { schemaValidierung, schematronValidierung } =
			message.validierung || {};
		const { fehler: schemaFehler, warnungen: schemaWarnungen } =
			(schemaValidierung &&
				schemaValidierung.zusammenfassung.pruefschritt[0]) ||
			{};

		const { fehler: schematronFehler, warnungen: schematronWarnungen } =
			(schematronValidierung &&
				schematronValidierung.zusammenfassung.pruefschritt[0]) ||
			{};
		if (
			schemaFehler === 0 &&
			schemaWarnungen === 0 &&
			schematronFehler === 0 &&
			schematronWarnungen === 0
		) {
			return "success";
		}
		if (
			schemaFehler === 0 &&
			schemaWarnungen !== 0 &&
			schematronFehler === 0 &&
			schemaWarnungen !== 0
		) {
			return Severity.Warning;
		}
		return Severity.Error;
	};
	const serverity = getSeverity();
	const isSuccess = serverity === "success";
	return (
		<Tooltip title="Validierungsreport öffnen">
			<IconButton
				onClick={handleInfoClick}
				data-testid="open-validation-report"
				aria-label={
					isSuccess
						? "Es gibt keine Validierungswarnungen oder -fehler"
						: validationLabels[serverity]
				}
			>
				{isSuccess ? validationSuccessIcon : validationIcons[serverity]}
			</IconButton>
		</Tooltip>
	);
};

export default ValidationIcon;
