import { useSelector } from "@xstate/react";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import { getFromSystem } from "../../../../utils/machines";
import type { NavigationActorRef } from "./navigation.machine";
import type {
	NavigationQueryActorRef,
	NavigationQuerySnapshot,
} from "./navigation.query.machine";
import type { NavigationCommandActorRef } from "./navigation.command.machine";
import { useSelectFromSystem } from "../../hooks";
import type { ProjektId } from "../../../../lib/validation/lite/IDSchemas";

export function getNavigationFromSystem(
	system: AnyActorSystem,
): NavigationActorRef {
	return getFromSystem(system, "navigation");
}

export function getNavigationQueryFromSystem(
	system: AnyActorSystem,
): NavigationQueryActorRef {
	return getFromSystem(system, "navigation:query");
}

export function getLocationFromSystem(system: AnyActorSystem): string {
	return getNavigationQueryFromSystem(system).getSnapshot().context.location;
}

export function getNavigationCommandFromSystem(
	system: AnyActorSystem,
): NavigationCommandActorRef {
	return getFromSystem(system, "navigation:command");
}

export function useNavigationRef(): NavigationActorRef {
	return useSelectFromSystem((system) => getNavigationFromSystem(system));
}

export function useNavigationCommandRef(): NavigationCommandActorRef {
	return useSelectFromSystem((system) =>
		getNavigationCommandFromSystem(system),
	);
}

export function useNavigationQueryRef(): NavigationQueryActorRef {
	return useSelectFromSystem((system) => getNavigationQueryFromSystem(system));
}

export function selectActiveProjektId(
	snapshot: NavigationQuerySnapshot,
): ProjektId | null {
	return snapshot.context.activeProjectId;
}

function selectModellPath(
	projektId: ProjektId,
	snapshot: NavigationQuerySnapshot,
): string[] | null {
	if (!projektId) return null;
	return snapshot.context.activePaths[projektId] || null;
}

function selectActiveModellPath(
	snapshot: NavigationQuerySnapshot,
): string[] | null {
	const projektId = selectActiveProjektId(snapshot);
	if (!projektId) return null;
	return selectModellPath(projektId, snapshot);
}

export function getModellPathFromSystem(
	system: AnyActorSystem,
	projektId: ProjektId,
): string[] | null {
	return selectModellPath(
		projektId,
		getNavigationQueryFromSystem(system).getSnapshot(),
	);
}

export function selectActiveProjectIdFromSystem(
	system: AnyActorSystem,
): string | null {
	return selectActiveProjektId(
		getNavigationQueryFromSystem(system).getSnapshot(),
	);
}

export function useActiveProjectId(): ProjektId | null {
	const ref = useNavigationQueryRef();
	return useSelector(ref, selectActiveProjektId);
}

export function useActiveModellPath(): string[] | null {
	const ref = useNavigationQueryRef();
	return useSelector(ref, selectActiveModellPath);
}
