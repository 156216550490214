import type { SnapshotFrom } from "xstate";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import { getFromSystem, useActorRefSelector } from "../../../../utils/machines";
import { useSelectFromSystem } from "../../hooks";
import {
	getModellPathFromSystem,
	useActiveProjectId,
} from "../navigation/hooks";
import { assertHasProject, createQueryId } from "./helpers";
import type { LiteDatatypeEntry, RefererSelection } from "./selectors";
import {
	selectRootModell,
	EMPTY_DATATYPES,
	createSelectReferences,
	selectBausteinRootNode,
	selectModellierungDatatypesAndGlobals,
	selectIsCodelisteFromModell,
	selectDatatypeOrReferenceFromModell,
	selectNodeFromModell,
	selectQNamePathFromModell,
	createSelectDefinitionPathFromModell,
	selectIsDefiniedInStandard,
} from "./selectors";
import type {
	ModellierungModellQueryActorRef,
	ModellierungModellQueryContext,
} from "./modellierungModel.query.machine";
import type { StandardProjekt } from "../project/types";
import { getTreePathVariants } from "../treeState/helpers";
import type { LiteModellContainer, NodeTarget } from "./types";
import { joinLitePath } from "../../../../lib/validation/lite/helpers";
import { LiteIdListSchema } from "../../../../lib/validation/lite/IDSchemas";
import {
	LiteBausteinType,
	ProjektType,
} from "../../../../lib/validation/lite/LiteEnums";
import type {
	LitePath,
	QNamePath,
	MetadatenStandard,
	MetadatenVersion,
	ModelKonfiguration,
	LiteNode,
	LiteBaustein,
} from "../../../../lib/validation/lite/LiteSchemas";
import type {
	LiteId,
	ProjektId,
} from "../../../../lib/validation/lite/IDSchemas";
import resolveOccurrences, {
	selectUsedByFromModell,
} from "./search/worker/resolveOccurrences";
import { LiteKind, getLiteNodeKind } from "./LiteKind";
import type { CodelisteInfo } from "../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/fieldRendererHelpers";
import { selectCodelistInfoFromModell } from "../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/fieldRendererHelpers";
import {
	createNodeTargetHref,
	createProjektBaseSegment,
} from "../navigation/helpers";
import { getProjectsQueryFromSystem } from "../projects/hooks";
import type { Nullish } from "../../../../utils/types";

export interface Datatype {
	path: LiteId[];
	id: LitePath;
	name: string | undefined;
	qnamePath: QNamePath;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emptyMap: ImmutableMap<any> = createImmutableMap();

const EMPTY_PATHS: LiteId[][] = [];

export function getModellierungModellQueryRef(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
): ModellierungModellQueryActorRef | null {
	if (!projektId) return null;
	return getFromSystem(system, createQueryId(projektId));
}

export function selectPatchIndices(context: ModellierungModellQueryContext): {
	undo: number | null;
	redo: number | null;
} {
	const undo = context.stack.at(context.cursor)?.eventLogIndex || null;
	const redo = context.stack.at(context.cursor - 1)?.eventLogIndex || null;
	return { undo, redo };
}

export function getPatchIndicesFromSystem(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
): { undo: number | null; redo: number | null } {
	const ref = getModellierungModellQueryRef(system, projektId);
	const context = ref?.getSnapshot().context;
	return (context && selectPatchIndices(context)) || { undo: null, redo: null };
}

export function getProjektIdByIdOrKennungFromSystem(
	system: AnyActorSystem,
	projektIdOrKennung: string | null | undefined,
): ProjektId | null {
	if (!projektIdOrKennung) return null;
	const projekteRef = getProjectsQueryFromSystem(system);
	const projekte = projekteRef.getSnapshot().context.projects;
	const tryById = projekte[projektIdOrKennung as ProjektId];
	const byId =
		tryById &&
		tryById.getSnapshot().context.projektType === ProjektType.Modellierung
			? tryById
			: null;
	const byKennung = Object.values(projekte).find((projekt) => {
		const { kennung, projektType } = projekt.getSnapshot().context;
		return (
			projektType === ProjektType.Standard && kennung === projektIdOrKennung
		);
	});
	const projekt = byId || byKennung;
	return projekt?.getSnapshot().context.projektId ?? null;
}

export function getModellierungProjektFromSystem(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
): StandardProjekt | null {
	const ref = getModellierungModellQueryRef(system, projektId);
	return ref?.getSnapshot().context.projekt || null;
}

function selectCanUndo(context: ModellierungModellQueryContext): boolean {
	return context.cursor > 0;
}
function selectCanRedo(context: ModellierungModellQueryContext): boolean {
	return context.cursor < context.stack.length;
}

export function getCanUndoFromSystem(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
) {
	const ref = getModellierungModellQueryRef(system, projektId);
	if (!ref) return false;
	return selectCanUndo(ref.getSnapshot().context);
}

export function getCanRedoFromSystem(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
) {
	const ref = getModellierungModellQueryRef(system, projektId);
	if (!ref) return false;
	return selectCanRedo(ref.getSnapshot().context);
}

export function useModellierungModellQueryRef(
	projektId: ProjektId | null | undefined,
): ModellierungModellQueryActorRef | null {
	return useSelectFromSystem((system) =>
		getModellierungModellQueryRef(system, projektId),
	);
}

export function useModellierungModellSelectorById<TReturn>(
	projektId: ProjektId | null | undefined,
	selector: (context: ModellierungModellQueryContext) => TReturn,
): TReturn | null {
	const ref = useModellierungModellQueryRef(projektId);
	return useActorRefSelector(ref, (snapshot) => selector(snapshot.context));
}

export function useModellierungModellSnapshotSelector<TReturn>(
	selector: (
		snapshot: SnapshotFrom<ModellierungModellQueryActorRef>,
	) => TReturn,
): TReturn | null {
	const id = useActiveProjectId();
	const ref = useModellierungModellQueryRef(id);
	return useActorRefSelector(ref, selector);
}

export function useModellierungModellSelector<TReturn>(
	selector: (context: ModellierungModellQueryContext) => TReturn,
): TReturn | null {
	return useModellierungModellSnapshotSelector((snapshot) =>
		selector(snapshot.context),
	);
}

export function useModellierungProjektById(
	projektId: ProjektId | null | undefined,
): StandardProjekt | null {
	return useModellierungModellSelectorById(projektId, ({ projekt }) => projekt);
}

export function useModellierungProjekt(): StandardProjekt | null {
	const id = useActiveProjectId();
	return useModellierungProjektById(id);
}

export function useModellierungDatatypesAndGlobals(
	datatypesToSelect: RefererSelection = [
		LiteBausteinType.Datentyp,
		LiteBausteinType.GlobaleEigenschaft,
	],
): LiteDatatypeEntry[] {
	return (
		useModellierungModellSelector((context) =>
			selectModellierungDatatypesAndGlobals(context.projekt, datatypesToSelect),
		) ?? EMPTY_DATATYPES
	);
}

export function getNodeTargetPathFromSystem(
	system: AnyActorSystem,
	projektId: ProjektId,
	target: NodeTarget,
) {
	const projekt = getModellierungProjektFromSystem(system, projektId);
	assertHasProject(projekt);
	const bausteinRoot = selectBausteinRootNode(projekt);
	const defaultModellPath = bausteinRoot && [bausteinRoot.id];
	const modellPath =
		getModellPathFromSystem(system, projektId) || defaultModellPath;
	if (!modellPath) return null;
	const { fullBausteinPath, fullPath } = getTreePathVariants(
		projekt.modell,
		LiteIdListSchema.parse(modellPath),
	);
	return { baustein: fullBausteinPath, structure: fullPath }[target];
}

export function useModell(): LiteModellContainer | null {
	return useModellierungModellSelector((context) => context.projekt.modell);
}

export function useKonfiguration(): ImmutableMap<ModelKonfiguration> {
	const modell = useModellierungModellSelector((context) => {
		const rootModell = selectRootModell(context.projekt);
		return createImmutableMap(rootModell.konfiguration);
	});

	if (modell === null) {
		return emptyMap;
	}

	return modell;
}

export function useTabName(projektId: ProjektId) {
	return useModellierungModellSelectorById(projektId, (context) => {
		const rootModell = selectRootModell(context.projekt);
		return rootModell.metadatenStandard.nameKurz;
	});
}

export function useMetadataStandard(): ImmutableMap<MetadatenStandard> {
	const modell = useModellierungModellSelector((context) => {
		const rootModell = selectRootModell(context.projekt);
		return createImmutableMap(rootModell.metadatenStandard);
	});

	if (modell === null) {
		return emptyMap;
	}

	return modell;
}

export function useMetadataVersion(): ImmutableMap<MetadatenVersion> {
	const modell = useModellierungModellSelector((context) => {
		const rootModell = selectRootModell(context.projekt);
		return createImmutableMap(rootModell.metadatenVersion);
	});

	if (modell === null) {
		return emptyMap;
	}

	return modell;
}

export function useUsedBy(nodeId: LiteId): LiteId[][] {
	return (
		useModellierungModellSelector(({ projekt }) => {
			const references = createSelectReferences(projekt)(nodeId);
			return resolveOccurrences(projekt.modell, references);
		}) || EMPTY_PATHS
	);
}

export function useCanUndo() {
	return useModellierungModellSelector(selectCanUndo) ?? false;
}
export function useCanRedo() {
	return useModellierungModellSelector(selectCanRedo) ?? false;
}

export function useIsCodeliste(node: LiteNode): boolean {
	return (
		useModellierungModellSelector(({ projekt }) =>
			selectIsCodelisteFromModell(projekt.modell, node),
		) ?? false
	);
}

export function useCodelisteNode(node: LiteNode): LiteBaustein | null {
	return useModellierungModellSelector(({ projekt }) => {
		const dt = selectDatatypeOrReferenceFromModell(projekt.modell, node);
		if (dt && getLiteNodeKind(dt) === LiteKind.CodeDatentyp) return dt;
		return null;
	});
}

export function useCodelisteData(node: LiteNode): CodelisteInfo | null {
	return useModellierungModellSelector(({ projekt }) => {
		return selectCodelistInfoFromModell(projekt.modell, node);
	});
}

export function useDatatypes(nodeId: LiteId): Datatype[] | null {
	return useModellierungModellSelector(({ projekt }) => {
		return selectUsedByFromModell(projekt.modell, nodeId).map((path) => {
			const refId = path.at(-1);
			const name = refId && selectNodeFromModell(projekt.modell, refId)?.name;
			const qnamePath = selectQNamePathFromModell(projekt.modell, path);
			return { path, id: joinLitePath(path), name, qnamePath };
		});
	});
}

export function useProjektBaseSegmentById(projektId: ProjektId): string | null {
	return useModellierungModellSelectorById(projektId, ({ projekt }) =>
		createProjektBaseSegment(projekt),
	);
}

export function useProjektBaseSegment(): string | null {
	return useModellierungModellSelector(({ projekt }) =>
		createProjektBaseSegment(projekt),
	);
}

export function useNodeTargetHref(fullPath: Nullish<LiteId[]>): string | null {
	return useModellierungModellSelector(({ projekt }) =>
		fullPath ? createNodeTargetHref(projekt, fullPath) : null,
	);
}

export function useDefinitionPath(nodeId: Nullish<LiteId>): LiteId[] | null {
	return useModellierungModellSelector(({ projekt }) =>
		nodeId
			? createSelectDefinitionPathFromModell(projekt.modell)(nodeId)
			: null,
	);
}

export function useIsDefinedInMainModell(nodeId: Nullish<LiteId>): boolean {
	return (
		useModellierungModellSelector(({ projekt }) =>
			selectIsDefiniedInStandard(projekt.modell, nodeId),
		) ?? false
	);
}
