import type { LiteId } from "../../../lib/validation/lite/IDSchemas";
import type { RestrictionId } from "../../../components/EditorState/types";
import type {
	AutoFixValue,
	FixerFn,
	ValidationTargetFieldProfiling,
} from "../../../components/ProfilierungValidation/types";
import ValidationFormatter from "../../../components/ProfilierungValidation/ValidationFormatter";
import useProfilierungValidationResult from "../../../hooks/useProfilierungValidationResult";

function ProfilierungValidationDisplay<
	TargetField extends ValidationTargetFieldProfiling = ValidationTargetFieldProfiling,
>({
	nodeId,
	restrictionId,
	targetField,
	value,
	onFix,
}: {
	nodeId?: LiteId;
	restrictionId?: RestrictionId;
	targetField?: TargetField;
	value?: AutoFixValue<TargetField>;
	onFix?: (fixer: FixerFn<TargetField>) => void;
}): JSX.Element | null {
	const results = useProfilierungValidationResult({
		nodeId,
		restrictionId,
		targetField,
	});
	return results.length > 0 ? (
		<ValidationFormatter<TargetField>
			results={results}
			value={value}
			onFix={onFix}
		/>
	) : null;
}

export default ProfilierungValidationDisplay;
