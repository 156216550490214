import type { ReactNode } from "react";
import { useKeycloak } from "./KeycloakProvider";
import LoginButton from "./LoginButton";
import LoggedInMenu from "./LoggedInMenu";
import styles from "./Keycloak.module.scss";
import { menus } from "../../resources/textConstants";

const Keycloak = ({ children }: { children: ReactNode }): JSX.Element => {
	const { keycloak, authenticated } = useKeycloak();
	const { loggedInMenu } = menus;

	if (!keycloak) {
		return (
			<span className={styles["xoev-login-info"]}>
				Die Anmeldung steht aktuell nicht zur Verfügung
			</span>
		);
	}

	const handleLogout = () => {
		keycloak.logout({ redirectUri: window.origin });
	};

	const handleLogin = () => {
		keycloak.login();
	};

	const menuItems = loggedInMenu.map((menu) => {
		const serverUrl = `${keycloak.authServerUrl}/realms/${keycloak.realm}`;
		return {
			...menu,
			link: menu.link?.includes("{{app}}")
				? `${serverUrl}${menu.link.replace("{{app}}", window.location.origin)}`
				: menu.link,
			...(menu.link?.includes("{{logout}}")
				? { link: "#", onClick: handleLogout }
				: {}),
		};
	});

	if (!authenticated) {
		return (
			<LoginButton
				aria-label="Login"
				className={styles["xoev-login-button"]}
				data-testid="login-button"
				label="XÖV-Suite Login"
				onClick={handleLogin}
				type="button"
			>
				{children}
			</LoginButton>
		);
	}

	return (
		<LoggedInMenu
			menuItems={menuItems}
			loggedInLabel={keycloak.tokenParsed?.preferred_username}
		/>
	);
};

export default Keycloak;
