import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import { VisuallyHidden } from "../../../ui";
import { getMaxSeverityFromResults } from "../../../ProfilierungValidation/helpers";
import { useValidationContext } from "../../../ProfilierungValidation/hooks";
import type {
	ValidationResultBase,
	ValidationTargetFieldProfiling,
} from "../../../ProfilierungValidation/types";
import { ValidationStatus } from "../../../ProfilierungValidation/types";
import { createValidationTableData, getTrigger } from "./helpers";
import { useAppSelector } from "../../../../redux/hooks";
import { selectModellContainer } from "../../../../redux/treeSlice";
import { useStateSelector } from "../../../EditorState";
import { selectStandard } from "../../../EditorState/selectors";

function ValidationDialog(): JSX.Element {
	const { result, status } = useValidationContext();
	const [isOpen, setIsOpen] = useState(false);

	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	const allValidationResults: ValidationResultBase<ValidationTargetFieldProfiling>[] =
		Object.values(result).flat();
	const maxValidationSeverity = getMaxSeverityFromResults(allValidationResults);
	const shouldShowDialog =
		allValidationResults.length > 0 && status !== ValidationStatus.Running;

	const { icon, label } = getTrigger(maxValidationSeverity, status);

	const rows = useMemo(
		() =>
			modell ? createValidationTableData(modell, allValidationResults) : [],
		[allValidationResults, modell],
	);

	const handleOpen = () => {
		if (shouldShowDialog) {
			setIsOpen(true);
		}
	};
	const handleClose = () => setIsOpen(false);

	const openButton = (
		<Tooltip title={label}>
			<IconButton
				sx={{ cursor: shouldShowDialog ? "pointer" : "auto" }}
				aria-label={label}
				onClick={handleOpen}
			>
				{icon}
			</IconButton>
		</Tooltip>
	);

	if (!shouldShowDialog) {
		return openButton;
	}

	return (
		<>
			{openButton}
			<Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
				<div data-testid="validation-dialog">
					<DialogTitle>Validierungsergebnisse</DialogTitle>
					<DialogContent>
						<Stack spacing={2}>
							<DialogContentText>{label}</DialogContentText>
							{allValidationResults.length > 0 && (
								<TableContainer sx={{ maxHeight: "55vh" }}>
									<Table stickyHeader aria-label="Validierungsergebnisse">
										<TableHead>
											<TableRow>
												<TableCell>Typ</TableCell>
												<TableCell>Feld</TableCell>
												<TableCell>Validierungsergebnis</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rows.map((row) => (
												<TableRow key={row.id}>
													<TableCell>
														<VisuallyHidden>{row.severityLabel}</VisuallyHidden>
														<Tooltip title={row.severityLabel} aria-hidden>
															<span>{row.severityIcon}</span>
														</Tooltip>
													</TableCell>
													<TableCell>
														<Tooltip title={`Springe zu: ${row.pathLabel}`}>
															<Link to={row.elementLink} onClick={handleClose}>
																{row.fieldLabel}
															</Link>
														</Tooltip>
													</TableCell>
													<TableCell>{row.message}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={handleClose}
							data-testid="close-validation-dialog"
						>
							Schließen
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
}

export default ValidationDialog;
