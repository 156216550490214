import type { RestrictionRule } from "../../types";
import { ValidationTargetFieldProfiling } from "../../types";
import { addTargetField } from "../helpers";
import { validation } from "../../../../resources/textConstants";

const restrictionRules = addTargetField<
	ValidationTargetFieldProfiling.RestrictionName,
	RestrictionRule<ValidationTargetFieldProfiling.RestrictionName>
>(ValidationTargetFieldProfiling.RestrictionName, [
	{
		id: "restriction-name-is-not-whitespace",
		target: ["name"],
		isValid({ restriction }) {
			const name = restriction.get("name") || "";
			return name === name.trim();
		},
		message: validation.rules.restriction.notWhitespace.message,
		autoFix: (invalidValue) => () => invalidValue.trim(),
		autoFixDescription: validation.rules.restriction.notWhitespace.description,
	},
	{
		id: "restriction-name-is-not-standard-name",
		target: ["name"],
		isValid({ restriction, standardDatatype }) {
			const name = restriction.get("name") || "";
			return name !== standardDatatype.name;
		},
		message: validation.rules.restriction.notStandardName.message,
	},
	{
		id: "restriction-name-is-not-unique",
		target: ["name"],
		isValid({ restriction, restrictions }) {
			return (
				restrictions
					.filter((res) => {
						return (
							res.get("id") !== restriction.get("id") &&
							res.get("name") === restriction.get("name")
						);
					})
					.count() === 0
			);
		},
		message: validation.rules.restriction.notUnique.message,
	},
]);

export default restrictionRules;
