import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import downloadFile from "js-file-download";
import { getFileNameFromHeaders } from "../utils/requests";
import { RequestMethods } from "../components/Api/types";
import type { AppInfoResponse } from "../components/AppInfoProvider/types";
import type ErrorMessagesType from "../types/ErrorMessages";
import type { RawLiteModel } from "../lib/validation/lite/LiteSchemas";
import type {
	ApiProjekt,
	ApiProjektList,
	ApiUploadProjekt,
} from "../lib/validation/lite/ApiSchemas";
import type { ProjektDbId } from "../lib/validation/lite/IDSchemas";
import { RawLiteModelSchema } from "../lib/validation/lite/LiteSchemas";
import {
	ApiProjektListSchema,
	ApiUploadProjektSchema,
} from "../lib/validation/lite/ApiSchemas";
import type { StandardType } from "../types/ProfilierungHome";

export type RequestNodeOptions = {
	standard: string;
};

export interface ApiExportProjekt {
	file: Blob;
	headers: { [k: string]: string };
}

export type RequestCodelistType = string[][];

export type RequestCodeListOptions = {
	kennung: string | null;
	version?: string | null;
};

export enum TreeInfoSet {
	Details = "DETAILS",
	Full = "FULL",
}

export interface TreeFetchOptions {
	standard: string | undefined;
}

function createTreeFetchUrl({ standard }: TreeFetchOptions): string {
	return `/v1/standards/${standard}`;
}

function createCodelistUrl(kennung: string | null, version?: string | null) {
	const requestKennung = version ? `${kennung}_${version}` : kennung;
	return `/v1/codelisten/${requestKennung}/codes`;
}
/**
 * Have a single api slice to effectively take advantage of automated re-fetching by
 * later defining tag relationships across endpoints
 * See Tip at https://redux-toolkit.js.org/rtk-query/api/createApi
 */
export const apiSlice = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: "/api",
		prepareHeaders: async (headers, { getState }) => {
			const { appInfo } = getState() as {
				appInfo: AppInfoResponse;
			};

			if (appInfo.keycloak?.token) {
				headers.set("Authorization", `Bearer ${appInfo.keycloak.token}`);
			}
		},
	}),
	tagTypes: ["Projects"],
	endpoints: (builder) => ({
		getAppInfo: builder.query<AppInfoResponse, void>({
			query: () => `/actuator/info`,
		}),
		exportStandardProjekt: builder.mutation<
			ApiProjektList,
			{ id: ProjektDbId; name: string }
		>({
			query: ({ id, name }) => ({
				url: `/v2/projekte/${id}`,
				headers: {
					accept: "application/zip",
					"Content-Type": "application/json",
				},
				method: RequestMethods.Get,
				responseHandler: async (response) => {
					if (response.ok) {
						const blob = await response.blob();
						const { headers } = response;

						const fileName = getFileNameFromHeaders(
							Object.fromEntries(headers),
							`${name}.zip`,
						);
						downloadFile(blob, fileName, "application/zip");
						/**
						 * RTK Query expects response handlers to return an object with "serializable" data,
						 * but does not consider blobs serializable. Since we use the js-file-download library
						 * to handle the download and don't actually need to return anything, we just return
						 * an empty object.
						 */
						return { data: {} };
					}
					throw new Error(response.statusText);
				},
			}),
		}),
		exportModellierungProjekt: builder.mutation<
			ApiExportProjekt,
			{ id: ProjektDbId; name: string; data: ApiUploadProjekt }
		>({
			query: ({ id, name, data }) => ({
				url: `/v2/projekte/${id}`,
				body: data,
				headers: {
					accept: "application/zip",
					"Content-Type": "application/json",
				},
				method: RequestMethods.Post,
				cache: "no-cache",
				responseHandler: async (response) => {
					if (response.ok) {
						const blob = await response.blob();
						const { headers } = response;

						const fileName = getFileNameFromHeaders(
							Object.fromEntries(headers),
							`${name}.zip`,
						);
						downloadFile(blob, fileName, "application/zip");
						/**
						 * RTK Query expects response handlers to return an object with "serializable" data,
						 * but does not consider blobs serializable. Since we use the js-file-download library
						 * to handle the download and don't actually need to return anything, we just return
						 * an empty object.
						 */
						return { data: {} };
					}
					throw new Error(response.statusText);
				},
			}),
			invalidatesTags: ["Projects"],
		}),
		deleteProject: builder.mutation<void, ProjektDbId>({
			query: (projectId) => ({
				url: `/v2/projekte/${projectId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Projects"],
		}),
		duplicateProject: builder.mutation<ApiUploadProjekt, ApiProjekt>({
			query: (body) => ({
				url: "/v2/projekte",
				method: "PUT",
				headers: {
					Accept: "application/json",
				},
				body,
			}),
			transformResponse: (response: ApiUploadProjekt) =>
				ApiUploadProjektSchema.parseAsync(response),
			invalidatesTags: ["Projects"],
		}),
		getCodeList: builder.query<RequestCodelistType, RequestCodeListOptions>({
			query: (args) => {
				const { kennung, version } = args;
				return { url: createCodelistUrl(kennung, version) };
			},
		}),
		getErrorMessages: builder.query<ErrorMessagesType, void>({
			query: () => "/v1/meldungen",
		}),
		getProjekt: builder.query<ApiProjektList, void>({
			query: (id) => `/v2/projekte/${id}`,
			transformResponse: (response: ApiProjektList) =>
				ApiProjektListSchema.parse(response),
		}),
		getProjekte: builder.query<ApiProjektList, void>({
			query: () => "/v2/projekte",
			transformResponse: (response: ApiProjektList) =>
				ApiProjektListSchema.parse(response),
			providesTags: ["Projects"],
		}),
		getStandards: builder.query<StandardType[], void>({
			query: () => "/v1/standards",
		}),
		getTree: builder.query<RawLiteModel, TreeFetchOptions>({
			query: (args) => {
				return { url: createTreeFetchUrl(args) };
			},
			transformResponse: (response) => {
				return RawLiteModelSchema.parseAsync(response);
			},
		}),
		uploadProjekt: builder.mutation<ApiUploadProjekt, { data: FormData }>({
			query: ({ data }) => ({
				url: "/v2/projekte/",
				body: data,
				headers: {
					accept: "application/json",
				},
				method: RequestMethods.Put,
				formData: true,
			}),
			transformResponse: (response) =>
				ApiUploadProjektSchema.parseAsync(response),
			invalidatesTags: ["Projects"],
		}),
	}),
});

export const {
	useGetAppInfoQuery,
	useExportStandardProjektMutation,
	useExportModellierungProjektMutation,
	useDeleteProjectMutation,
	useDuplicateProjectMutation,
	useLazyGetCodeListQuery,
	useGetErrorMessagesQuery,
	useGetProjekteQuery,
	useLazyGetProjekteQuery,
	useGetStandardsQuery,
	useGetTreeQuery,
	useLazyGetTreeQuery,
	useUploadProjektMutation,
} = apiSlice;
