import type { ActorRefFrom, SnapshotFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import { translateStoreEvents } from "../../EventStore/helpers";
import type { ProjektId } from "../../../../lib/validation/lite/IDSchemas";
import type { ExtractStoreEventPayload } from "../../EventStore/StoreEvent";

export type NavigationQueryActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof navigationQueryMachine
>;
export type NavigationQueryContext = {
	location: string;
	activeProjectId: null | ProjektId;
	activePaths: { [TProjektId in ProjektId]?: string[] };
};
export type NavigationQuerySnapshot = SnapshotFrom<
	// eslint-disable-next-line no-use-before-define
	typeof navigationQueryMachine
>;
type NavigationQueryEvent =
	| ({ type: "UPDATE_LOCATION" } & ExtractStoreEventPayload<"NAVIGATE.PARSED">)
	| { type: "CLOSE_PROJECT"; projektId: ProjektId };

const navigationQueryMachine = setup({
	types: {
		events: {} as NavigationQueryEvent,
		context: {} as NavigationQueryContext,
	},
	actors: {
		translateEvents: translateStoreEvents<NavigationQueryEvent>(
			{
				"NAVIGATE.PARSED": ({ payload }) => ({
					type: "UPDATE_LOCATION",
					...payload,
				}),
				"PROJECT.CLOSE": ({ payload }) => ({
					type: "CLOSE_PROJECT",
					projektId: payload.projektId,
				}),
			},
			{ replayEventLog: true },
		),
	},
	actions: {
		updateLocation: assign({
			location: ({ event }) => {
				assertEvent(event, "UPDATE_LOCATION");
				return event.location;
			},
		}),
		parseLocation: assign({
			activeProjectId: ({ event }) => {
				assertEvent(event, "UPDATE_LOCATION");
				return event.projektId;
			},
			activePaths: ({ context: { activePaths }, event }) => {
				assertEvent(event, "UPDATE_LOCATION");
				const { projektId, modellPath } = event;
				if (!projektId || !modellPath) return activePaths;
				return { ...activePaths, [projektId]: modellPath };
			},
		}),
		closeProject: assign({
			activePaths: ({ context: { activePaths }, event }) => {
				assertEvent(event, "CLOSE_PROJECT");
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { [event.projektId]: _omit, ...nextPaths } = activePaths;
				return nextPaths;
			},
		}),
	},
}).createMachine({
	id: "navigation:query",
	context: {
		location: "/",
		activeProjectId: null,
		activePaths: {},
	},
	invoke: { src: "translateEvents" },
	on: {
		UPDATE_LOCATION: { actions: ["updateLocation", "parseLocation"] },
	},
});

export default navigationQueryMachine;
