import EditIcon from "@mui/icons-material/Edit";
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from "@mui/material";
import { useState } from "react";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../../components/EditorState";
import { selectProperties } from "../../../components/EditorState/selectors";
import DeletePropertyButton from "../../Molecules/ProfilierungPropertyDeleteButton/ProfilierungPropertyDeleteButton";
import type { PropertyValues } from "../../../components/EditorState/types";
import PropertyEditRow, {
	PropertyEditMode,
} from "../ProfilierungPropertyEditRow/ProfilierungPropertyEditRow";
import { VisuallyHidden } from "../../../components/ui";
import "./ProfilierungPropertiesTable.scss";
import { normalizeIdentifier } from "../../../utils/misc";

export default function PropertiesTable(): JSX.Element {
	const properties = useStateSelector(selectProperties()) || [];
	const applyPatch = useApplyPatch();
	const { addProperty, deleteProperty, editProperty } =
		useEditorPatchCreators();

	const [editingRowName, setEditingRowName] = useState<string | null>(null);

	const handleAdd = (values: Omit<PropertyValues, "nameTechnisch">) => {
		const nameTechnisch = normalizeIdentifier(values.name);
		applyPatch(addProperty({ nameTechnisch, ...values }));
	};

	const handleDelete = (nameTechnisch: string) => {
		applyPatch(deleteProperty({ nameTechnisch }));
	};

	const createEditConfirmHandler =
		(name: string) => (values: PropertyValues) => {
			const normalizedNameTechnisch = normalizeIdentifier(name);
			applyPatch(
				editProperty({ nameTechnisch: normalizedNameTechnisch, values }),
			);
			setEditingRowName(null);
		};

	const createEditHandler = (nameTechnisch: string) => () => {
		setEditingRowName(nameTechnisch);
	};

	const handleCancel = () => {
		setEditingRowName(null);
	};

	return (
		<div className="attributes-table" data-testid="attributes-table">
			<VisuallyHidden>
				<h3>Definition der Eigenschaft</h3>
			</VisuallyHidden>
			<TableContainer className="attributes-table__container">
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Beschreibung</TableCell>
							<TableCell>Aktion</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{properties.map((prop) =>
							editingRowName === prop.nameTechnisch ? (
								<PropertyEditRow
									key={prop.nameTechnisch}
									properties={properties.filter((a) => a !== prop)}
									mode={PropertyEditMode.Edit}
									onConfirm={createEditConfirmHandler(prop.name)}
									onCancel={handleCancel}
									initialState={prop}
									autoFocus
								/>
							) : (
								<TableRow
									key={prop.nameTechnisch}
									data-testid="attribute-row"
									data-attribute-id={prop.nameTechnisch}
								>
									<TableCell data-testid="attribute-name">
										{prop.name}
									</TableCell>
									<TableCell data-testid="attribute-desc">
										{prop.beschreibung}
									</TableCell>
									<TableCell>
										<span className="attributes-table__action-cell">
											<Tooltip title="Attribut bearbeiten">
												<IconButton
													onClick={createEditHandler(prop.nameTechnisch)}
													data-testid="edit-button"
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
											<DeletePropertyButton
												property={prop}
												onDelete={handleDelete}
											/>
										</span>
									</TableCell>
								</TableRow>
							),
						)}
						{editingRowName === null && (
							<PropertyEditRow
								properties={properties}
								mode={PropertyEditMode.Create}
								onConfirm={handleAdd}
							/>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}
