import { UnreachableError } from "../../../../../utils/error";
import { getNodeByPath, walkTreeEntries } from "../../../../Tree/treeHelpers";
import { isLiteModel } from "../../../../../lib/validation/lite/TypeGuards";
import { LiteNodeType } from "../../../../../lib/validation/lite/LiteEnums";
import type {
	LiteNode,
	RawLiteModel,
} from "../../../../../lib/validation/lite/LiteSchemas";
import type { LiteId } from "../../../../../lib/validation/lite/IDSchemas";
import { selectNodeFromModell } from "../../modellierungModel/selectors";
import type { StandardProjekt } from "../types";
import {
	assertRawLiteBaustein,
	assertRawLiteModel,
	assertRawLitePaket,
	getRawChildren,
	isRawLiteBaustein,
	isRawLiteEigenschaft,
	isRawLiteModel,
	isRawLitePaket,
	type RawLiteNode,
} from "./helpers";

function transformNode(node: LiteNode): RawLiteNode {
	switch (node.liteType) {
		case LiteNodeType.Model: {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { children, parent, ...restNode } = node;
			return { ...restNode, externeModelle: [], pakete: [] };
		}
		case LiteNodeType.Paket: {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { children, parent, liteType, ...restNode } = node;
			return { ...restNode, bausteine: [], pakete: [] };
		}
		case LiteNodeType.Baustein:
		case LiteNodeType.Eigenschaft: {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { children, parent, liteType, ...restNode } = node;
			return {
				...restNode,
				eigenschaften: [],
			};
		}
		default: {
			throw new UnreachableError("Unreachable LiteNode");
		}
	}
}

function getParentChildList(
	backendModel: RawLiteModel[],
	path: LiteId[],
	backendNode: RawLiteNode,
): RawLiteNode[] {
	if (path.length === 0) return backendModel;
	const parentNode = getNodeByPath(
		backendModel,
		path,
		getRawChildren,
		(node) => node.id,
	);
	if (!parentNode) {
		throw new Error("Expected parent");
	}
	// is modell
	if (isRawLiteModel(backendNode)) {
		assertRawLiteModel(parentNode);
		return parentNode.externeModelle;
	}
	// is paket
	if (isRawLitePaket(backendNode)) {
		if (isRawLitePaket(parentNode) || isRawLiteModel(parentNode)) {
			return parentNode.pakete;
		}
		assertRawLitePaket(parentNode);
		assertRawLiteModel(parentNode);
	}
	// is baustein
	if (isRawLiteBaustein(backendNode)) {
		assertRawLitePaket(parentNode);
		return parentNode.bausteine;
	}
	// is eigenschaft
	if (isRawLiteEigenschaft(backendNode)) {
		if (isRawLitePaket(parentNode)) {
			return parentNode.bausteine;
		}
		assertRawLiteBaustein(parentNode);

		if (!parentNode.eigenschaften) {
			throw new UnreachableError("Expected Eigenschaften");
		}

		return parentNode.eigenschaften;
	}

	throw new UnreachableError("Expected LiteNode");
}

function insertNode(
	backendModel: RawLiteModel[],
	path: LiteId[],
	backendNode: RawLiteNode,
) {
	const parentPath = path.slice(0, -1);
	const parentChildList = getParentChildList(
		backendModel,
		parentPath,
		backendNode,
	);
	parentChildList.push(backendNode);
}

export default function createBackendModel(
	frontendModel: StandardProjekt,
): RawLiteModel {
	const root = selectNodeFromModell(
		frontendModel.modell,
		frontendModel.modell.rootModelId,
	);
	if (!root || !isLiteModel(root)) {
		throw new Error("No root was found");
	}

	const entries = walkTreeEntries(
		[root as LiteNode],
		(node) =>
			node.children.map((childId) => {
				const child = selectNodeFromModell(frontendModel.modell, childId);
				if (!child) {
					throw new Error("No child was found");
				}
				return child;
			}),
		(node) => node.id,
	);

	const backendModel: RawLiteModel[] = [];

	for (const [path, node] of entries) {
		const backendNode = transformNode(node);
		insertNode(backendModel, path, backendNode);
	}

	return backendModel[0];
}
