import { useState } from "react";
import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useKeycloak } from "../../../../components/Keycloak/KeycloakProvider";
import UploadProject from "../UploadProject";
import CustomGridToolbarQuickFilter from "../CustomGridToolbarQuickFilter";
import FilterListIcon from "../../../../resources/icons/FilterListIcon";
import styles from "./CustomGridToolbarContainer.module.scss";

export default function CustomGridToolbarContainer(): JSX.Element {
	const { authenticated } = useKeycloak();
	const [isFilterPanelVisible, setIsFilterPanelVisible] =
		useState<boolean>(false);
	const apiContext = useGridApiContext();

	const handleClick = () => {
		if (!isFilterPanelVisible) {
			apiContext.current.showFilterPanel();
		} else {
			apiContext.current.hideFilterPanel();
		}

		setIsFilterPanelVisible((value) => !value);
	};

	return (
		<GridToolbarContainer className={styles["xoev-table-toolbar"]}>
			<CustomGridToolbarQuickFilter
				placeholder="Suche in Inhalt"
				data-testid="project-search"
			/>
			{/* TODO Implementation with Login Ticket */}
			{/* <div className={styles["xoev-table-toolbar--switch"]}>
				<label htmlFor="toolbar-switch-id">Für mich bearbeitbare Inhalte</label>
				<CustomSwitch
					size="small"
					itemID="toolbar-switch-id"
					data-testid="project-list-visibility-switch"
					checked={switchState}
					onChange={handleSwitch}
					disabled={!authenticated}
				/>
			</div> */}
			<Button
				endIcon={<FilterListIcon />}
				onClick={handleClick}
				className={styles["xoev-table-toolbar--filter"]}
			>
				Filter
			</Button>
			{authenticated && <UploadProject />}
		</GridToolbarContainer>
	);
}
