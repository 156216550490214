import { LiteNodeType, LitePaketType, LiteBausteinType } from "./LiteEnums";
import type {
	LiteNode,
	LiteModel,
	LitePaket,
	LiteBaustein,
	LiteEigenschaft,
	Geschaeftsregel,
} from "./LiteSchemas";

export function isLiteModel(node: LiteNode): node is LiteModel {
	return node.liteType === LiteNodeType.Model;
}
export function isLitePaket(node: LiteNode): node is LitePaket {
	return node.liteType === LiteNodeType.Paket;
}
export function isLiteBaustein(node: LiteNode): node is LiteBaustein {
	return node.liteType === LiteNodeType.Baustein;
}
export function isLiteEigenschaft(node: LiteNode): node is LiteEigenschaft {
	return node.liteType === LiteNodeType.Eigenschaft;
}
export function isLiteSchema(node: LiteNode): node is LitePaket {
	return isLitePaket(node) && node.typ === LitePaketType.Schema;
}

export function isGeschaeftsregel(regel: {
	[key: string]: string;
}): regel is Geschaeftsregel {
	return !!(regel.kennung && regel.beschreibung && regel.spezifikation);
}

export function isPartialGeschaeftsregel(regel: {
	[key: string]: string;
}): regel is Geschaeftsregel {
	return !!regel.kennung;
}

export function isLiteDatatype(node: LiteNode): node is LiteBaustein {
	return (
		isLiteBaustein(node) &&
		(node.typ === LiteBausteinType.Datentyp ||
			node.typ === LiteBausteinType.CodeDatentyp)
	);
}
export function isLiteBasisDatentyp(node: LiteNode): node is LiteBaustein {
	return isLiteDatatype(node) && !!node.basisDatentyp;
}
export function isLiteNachricht(node: LiteNode): node is LiteBaustein {
	return isLiteBaustein(node) && node.typ === LiteBausteinType.Nachricht;
}

export function isLiteGlobaleEigenschaft(node: LiteNode): node is LiteBaustein {
	return (
		isLiteBaustein(node) && node.typ === LiteBausteinType.GlobaleEigenschaft
	);
}
export function isLiteGlobaleEigenschaftGruppe(node: LiteNode) {
	return (
		isLiteBaustein(node) &&
		node.typ === LiteBausteinType.GlobaleEigenschaftGruppe
	);
}
