import type { ReactNode } from "react";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Keycloak from "keycloak-js";
import { useGetAppInfoQuery } from "../../redux/apiSlice";
import { setAppInfo } from "../../redux/appInfoSlice";
import { useAppDispatch } from "../../redux/hooks";

interface KeycloakContext {
	keycloak: Keycloak | undefined;
	authenticated: boolean;
	loading: boolean;
}

const initialKeycloakContext = {
	keycloak: undefined,
	authenticated: false,
	loading: false,
};

export const KeycloakContext = createContext<KeycloakContext>(
	initialKeycloakContext,
);

export const useKeycloak = () => useContext(KeycloakContext);

export const KeycloakProvider = ({
	children = null,
}: {
	children?: ReactNode;
}): JSX.Element => {
	const { data, isLoading } = useGetAppInfoQuery();
	const [keycloakContext, setKeycloakContext] = useState<KeycloakContext>(
		initialKeycloakContext,
	);
	const { keycloak } = keycloakContext;

	const dispatch = useAppDispatch();
	const refreshToken = useCallback(
		(token) => {
			if (token) {
				dispatch(
					setAppInfo({
						keycloak: {
							token,
						},
					}),
				);
			}
		},
		[dispatch],
	);

	useEffect(() => {
		if (data?.keycloak) {
			setKeycloakContext((prevState) => {
				return { ...prevState, loading: true };
			});
			const keycloakInstance = new Keycloak(data.keycloak);
			keycloakInstance
				.init({
					onLoad: "check-sso",
					silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
					checkLoginIframe: true,
					messageReceiveTimeout: 5000,
					silentCheckSsoFallback: false,
				})
				.then((isAuthenticated) => {
					setKeycloakContext((prevState) => {
						return {
							...prevState,
							keycloak: keycloakInstance,
							authenticated: isAuthenticated,
							loading: false,
						};
					});
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.error("Failed to initialize adapter:", error);
					setKeycloakContext((prevState) => {
						return {
							...prevState,
							loading: false,
						};
					});
				});
		}
	}, [data]);

	useEffect(() => {
		if (keycloak && keycloak.authenticated) {
			refreshToken(keycloak.token);

			let exp = 300;
			if (keycloak.tokenParsed?.exp) {
				exp = Math.floor(keycloak.tokenParsed.exp * 1000 - Date.now());
			}

			const intervall = () => {
				keycloak
					.updateToken(exp)
					.then(() => refreshToken(keycloak.token))
					.catch((error) => {
						// eslint-disable-next-line no-console
						console.error("Failed to initialize token:", error);
					});
			};
			setInterval(intervall, exp);
		}
	}, [keycloak, refreshToken]);

	if (isLoading || keycloakContext.loading) {
		return (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				sx={{ width: 1, height: "100vh" }}
			>
				Suite wird geladen, bitte warten...
				<CircularProgress style={{ marginLeft: "0.5rem" }} />
			</Stack>
		);
	}

	return (
		<KeycloakContext.Provider value={keycloakContext}>
			{children}
		</KeycloakContext.Provider>
	);
};
