import { useMemo, useRef } from "react";
import { useTreeContext } from "../TreeContext";
import type { LiteNode } from "../../../lib/validation/lite/LiteSchemas";
import {
	LiteBausteinFilter,
	type NodeRenderProps,
	type PathFragment,
	type TreeNodeProps,
} from "../types";
import { useEventHandler } from "../../../hooks";
import { getNodeContext } from "./TreeNodeContext";
import { isExpanded } from "../ExpansionState";
import "./TreeNode.scss";
import filterTreeNodes from "../filterTreeNodes";
import {
	getLiteNodeKind,
	LiteKind,
} from "../../AppActor/actors/modellierungModel/LiteKind";

function TreeNode<
	TNode extends LiteNode,
	TNodeId extends PathFragment = number,
>({
	node,
	path,
	activeFilters,
}: TreeNodeProps<TNode, TNodeId>): JSX.Element | null {
	const TreeNodeContext = getNodeContext<TNode, TNodeId>();
	const {
		onActivate,
		onOpen,
		onClose,
		getChildren,
		isNodeExpandable,
		renderNode,
		expansionState,
		getNodeId,
	} = useTreeContext<TNode, TNodeId>();
	const isOpen = isExpanded(expansionState, path);
	const isExpandable = isNodeExpandable(node, path);
	const nodeCtx = useMemo(() => ({ node, path, isOpen }), [node, path, isOpen]);

	const nodeRef = useRef<HTMLOrSVGElement>(null);

	const isEmptyFilterActive = () => {
		return activeFilters?.includes(LiteBausteinFilter.Leer);
	};
	const handleClick = useEventHandler(() => {
		const event = { node, path };
		if (isExpandable) {
			if (isOpen) {
				onClose(event);
			} else {
				onOpen(event);
			}
		}
		onActivate(event);
	});

	const handleOpen = () => {
		const event = { node, path };
		if (isExpandable) {
			onOpen(event);
		}
		onActivate(event);
	};

	const handleClose = () => {
		const event = { node, path };
		if (isExpandable) {
			onClose(event);
		}
		onActivate(event);
	};

	const handleKeyPress = useEventHandler((e: { key: string }) => {
		if (["Enter", " "].includes(e.key)) {
			handleClick();
		} else if (e.key === "ArrowRight") {
			handleOpen();
		} else if (e.key === "ArrowLeft") {
			handleClose();
		}
	});

	const nodeRenderProps: NodeRenderProps<TNode, TNodeId> = useMemo(() => {
		const props = {
			onClick: handleClick,
			onKeyDown: handleKeyPress,
			role: "menuitem",
			tabIndex: 0,
			ref: nodeRef,
		};
		return { getProps: () => props, node, path, isExpanded: isOpen };
	}, [handleClick, handleKeyPress, isOpen, node, path]);

	const nodeElem = renderNode(nodeRenderProps);
	const nodeChildren = getChildren(node, path) || [];
	const filteredChildren = filterTreeNodes(nodeChildren, activeFilters);

	const showTreeNode = () => {
		return !(
			isEmptyFilterActive() &&
			filteredChildren.length === 0 &&
			// makes sure it is a Packet or SchemaPaket
			[LiteKind.SchemaPaket, LiteKind.Paket].includes(getLiteNodeKind(node))
		);
	};
	return (
		<li className="treenode">
			{showTreeNode() && (
				<TreeNodeContext.Provider value={nodeCtx}>
					{nodeElem}
				</TreeNodeContext.Provider>
			)}

			{isOpen && (
				<ul className="treenode__list">
					{filteredChildren.map((child, i) => {
						const id = getNodeId(child, i);
						const childPath = [...path, id];
						return (
							<TreeNode
								key={id}
								node={child}
								path={childPath}
								activeFilters={activeFilters}
							/>
						);
					})}
				</ul>
			)}
		</li>
	);
}

export default TreeNode;
