import type { TreeNodeType } from "../../../../types/ProfilierungHome";
import CardinalityRenderer from "./renderers/CardinalityRenderer";
import RecursionRenderer from "./renderers/RecursionRenderer";
import SimpleRenderer from "./renderers/SimpleRenderer";
import InfoRenderer from "./renderers/InfoRenderer";
import ValuesRenderer from "./renderers/ValuesRenderer";
import type { FieldRendererKeys, FieldRendererInterface } from "./types";
import CodeListRestrictionRenderer from "./renderers/CodeListRestrictionRenderer";
import PropertiesRenderer from "./renderers/PropertiesRenderer";
import NutzungsArtRenderer from "./renderers/NutzungsArtRenderer";
import type { Mutable, ToUnion } from "../../../../utils/types";
import { toMutable } from "../../../../utils/types";
import KennungRenderer from "./renderers/KennungRenderer";
import VersionRenderer from "./renderers/VersionRenderer";
import MultiValueRenderer from "./renderers/MultiValueRenderer";
import FieldRenderer from "./renderers/FieldRenderer";
import { FieldRendererProvider } from "./context";
import ValueInfo from "./renderers/ValueInfo/ValueInfo";
import {
	isLiteDatatype,
	isLiteEigenschaft,
} from "../../../../lib/validation/lite/TypeGuards";
import { parseMultiplizitaet } from "./renderers/CardinalityRenderer/CardinalityRenderer";
import IsLeaf from "./guards/IsLeaf";

const isNodeDatatypeOrElement = (activeNode: TreeNodeType) =>
	isLiteDatatype(activeNode) || isLiteEigenschaft(activeNode);

function createFieldRenderer<KeyList extends ReadonlyArray<FieldRendererKeys>>(
	keys: KeyList,
	render: FieldRendererInterface<ToUnion<Mutable<KeyList>>>["render"],
): FieldRendererInterface<ToUnion<Mutable<KeyList>>> {
	return {
		keys: toMutable(keys),
		render(fields, props) {
			return (
				<FieldRendererProvider isStandardRenderer={props.isStandardRenderer}>
					{render(fields, props)}
				</FieldRendererProvider>
			);
		},
	};
}

const fieldRenderers: FieldRendererInterface[] = [
	createFieldRenderer(["beschreibung"], (fields, props) => (
		<FieldRenderer>
			{isNodeDatatypeOrElement(props.activeNode) && (
				<>
					{!props.isStandardRenderer && (
						<SimpleRenderer fields={fields} {...props} />
					)}
					<InfoRenderer
						label="Beschreibung"
						value={props.activeNode.beschreibung}
						fallback="Keine Beschreibung"
					/>
				</>
			)}
		</FieldRenderer>
	)),
	createFieldRenderer(["umsetzungshinweis"], (fields, props) => (
		<FieldRenderer>
			{isNodeDatatypeOrElement(props.activeNode) && (
				<>
					{!props.isStandardRenderer && (
						<SimpleRenderer fields={fields} {...props} />
					)}
					<InfoRenderer
						label="Umsetzungshinweis"
						value={props.activeNode.umsetzungshinweis}
						fallback="Kein Umsetzungshinweis"
					/>
				</>
			)}
		</FieldRenderer>
	)),
	createFieldRenderer(["lowerBound", "upperBound"], (fields, props) => {
		const node = props.activeNode;
		const { lowerBound, upperBound } =
			(isLiteEigenschaft(node) && parseMultiplizitaet(node.multiplizitaet)) ||
			{};
		return (
			<FieldRenderer>
				{!props.isStandardRenderer && (
					<CardinalityRenderer fields={fields} {...props} />
				)}

				{lowerBound && upperBound ? (
					<InfoRenderer
						label="Kardinalität"
						value={`${lowerBound} .. ${upperBound}`}
					/>
				) : (
					<InfoRenderer label="Kardinalität" fallback="Keine Kardinalität" />
				)}
			</FieldRenderer>
		);
	}),
	createFieldRenderer(["istRekursionsStart"], (fields, props) => (
		<>
			{!props.isStandardRenderer && (
				<FieldRenderer>
					<RecursionRenderer fields={fields} {...props} />
				</FieldRenderer>
			)}
		</>
	)),
	createFieldRenderer(["fixedWert"], (fields, props) => {
		const [definition] = fields;
		return (
			<IsLeaf path={props.activePath}>
				<FieldRenderer>
					{!props.isStandardRenderer && (
						<ValuesRenderer
							fields={fields}
							label={`${definition.label} der Einschränkung `}
							{...props}
						/>
					)}
					{/* TODO: add to translations */}

					<InfoRenderer
						label="Fix-Wert"
						value={
							"fixedWert" in props.activeNode
								? props.activeNode.fixedWert
								: null
						}
						fallback="Kein Fix-Wert"
					/>
				</FieldRenderer>
			</IsLeaf>
		);
	}),
	createFieldRenderer(["defaultWert"], (fields, props) => {
		const [definition] = fields;
		return (
			<IsLeaf path={props.activePath}>
				{isLiteEigenschaft(props.activeNode) && (
					<FieldRenderer>
						{!props.isStandardRenderer && (
							<ValuesRenderer
								fields={fields}
								{...props}
								label={
									<span>
										{definition.label} der Einschränkung{" "}
										<ValueInfo label={definition.name} />
									</span>
								}
							/>
						)}
						{/* TODO: add to translations  */}
						<InfoRenderer
							label="Standard-Wert"
							value={
								"defaultWert" in props.activeNode
									? props.activeNode.defaultWert
									: null
							}
							fallback="Kein Standard-Wert"
						/>
					</FieldRenderer>
				)}
			</IsLeaf>
		);
	}),
	createFieldRenderer(["beispielWert"], (fields, props) => {
		const [definition] = fields;
		return (
			<IsLeaf path={props.activePath}>
				{isLiteEigenschaft(props.activeNode) && (
					<FieldRenderer>
						<ValuesRenderer
							fields={fields}
							label={
								<span>
									{definition.label} der Einschränkung{" "}
									<ValueInfo label={definition.name} />
								</span>
							}
							{...props}
						/>
					</FieldRenderer>
				)}
			</IsLeaf>
		);
	}),
	createFieldRenderer(["beispielWertNachricht"], (fields, props) => (
		<IsLeaf path={props.activePath}>
			{isLiteEigenschaft(props.activeNode) && (
				<FieldRenderer>
					<MultiValueRenderer
						fields={fields}
						label={
							<span>
								Wert für Beispielnachrichten{" "}
								<ValueInfo label="Wert für Beispielnachrichten" />
							</span>
						}
						{...props}
					/>
				</FieldRenderer>
			)}
		</IsLeaf>
	)),
	createFieldRenderer(["nutzungsArt"], (fields, props) => {
		return <NutzungsArtRenderer fields={fields} {...props} />;
	}),
	createFieldRenderer(["kennung"], (fields, props) => {
		return (
			<FieldRenderer>
				<KennungRenderer fields={fields} {...props} />
			</FieldRenderer>
		);
	}),
	createFieldRenderer(["version"], (fields, props) => {
		return (
			<FieldRenderer>
				<VersionRenderer fields={fields} {...props} />
			</FieldRenderer>
		);
	}),
	createFieldRenderer(["codeliste"], (fields, props) => (
		<>
			{!props.isStandardRenderer && (
				<FieldRenderer>
					<CodeListRestrictionRenderer fields={fields} {...props} />
				</FieldRenderer>
			)}
		</>
	)),
	createFieldRenderer(["eigenschaften"], (fields, props) => (
		<>
			{isNodeDatatypeOrElement(props.activeNode) && (
				<PropertiesRenderer fields={fields} {...props} />
			)}
		</>
	)),
];

export default fieldRenderers;
