import { extractNodeProps } from "../../../helpers";
import type { RendererImplementation } from "../../../types";
import type { RendererImplementationComponent } from "../../types";

const HeaderRenderer: RendererImplementationComponent<
	RendererImplementation.Header
> = ({ markup }) => {
	const { "xoev-suite:value": header, "xoev-suite:level": level } =
		extractNodeProps(markup);
	const CustomHeading = `h${level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	return (
		<CustomHeading data-testid="header-renderer" data-value={header}>
			{header}
		</CustomHeading>
	);
};

export default HeaderRenderer;
